import React, { useEffect, useState } from "react";
import { ReactComponent as AddCoverPage } from "../../../../../assets/illustrations/add_cover_page.svg";
import { NoResultsFound } from "../../../../../components/NoResultsFound";
import Text from "../../../../../components/Text";
import { casaWebConstants } from "../../../../../constants/globalConstant";
import { useDispatch, useSelector } from "react-redux";
import {
  setCoverPageThumbnail,
  setSignedUrlApiLoader,
} from "../../../../../reducers/webHomepageSlice";
import { API_GET_SIGNED_URL } from "../../../../../api/configurationScreenServices";
import WebCoverPage from "../../../CasaWebWidgets/CoverPageWidget/WebCoverPage";
import "./CoverPagePreview.css";
const CoverPagePreview = ({ addCoverPage, webPreviewMode }) => {
  const dispatch = useDispatch();
  const [imageUrl, setImageUrl] = useState("");

  const { coverPageData, dialog, visiblePanel } = useSelector(
    (state) => state.webHomepage
  );
  const getBackgroundColor = () => {
    if (!coverPageData?.selectedColor) return "var(--N20)";
    if (webPreviewMode) {
      return `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), ${
        coverPageData?.selectedColor || "black"
      }`;
    }
    return `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), ${
      coverPageData?.selectedColor || "#F6F6F7"
    }`;
  };

  const editCoverPage = () => {
    !webPreviewMode && addCoverPage();
  };

  useEffect(() => {
    const fetchImageSignedURL = async () => {
      if (
        coverPageData?.image?.imageKey &&
        coverPageData?.image?.uploadStatus === "SUCCESS"
      ) {
        try {
          dispatch(setCoverPageThumbnail(null));
          dispatch(setSignedUrlApiLoader(true));
          const { data } = await API_GET_SIGNED_URL(
            coverPageData.image.imageKey
          );
          dispatch(setSignedUrlApiLoader(false));
          if (data?.url) {
            setImageUrl(data.url);
            dispatch(setCoverPageThumbnail(data.url));
          }
        } catch (error) {
          console.error("Error fetching signed URL:", error);
        }
      }
    };
    fetchImageSignedURL();
  }, [coverPageData]);
  return (
    <div
      className={`cover-page`}
      onClick={editCoverPage}
      style={{ background: getBackgroundColor(), zIndex: dialog ? 0 : 4 }}
    >
      {coverPageData?.customRadio === casaWebConstants.IMAGE &&
        coverPageData?.image?.imageURL &&
        imageUrl && (
          <img src={imageUrl} alt="Cover" className={"coverImageElement"} />
        )}
      <div
        className={
          webPreviewMode
            ? "webCoverPageContainerPreview"
            : "webCoverPageContainer"
        }
      >
        <WebCoverPage
          webPreviewMode={webPreviewMode}
          coverPageData={coverPageData}
        />
      </div>

      {!webPreviewMode &&
        visiblePanel === casaWebConstants.LEFT &&
        !coverPageData?.selectedColor &&
        !coverPageData?.image?.imageURL && (
          <div className="coverpage" onClick={addCoverPage}>
            <NoResultsFound
              Illustration={AddCoverPage}
              children={
                <div style={{ textAlign: "center" }}>
                  <Text type="T5" color="var(--N80)">
                    {casaWebConstants.ADD_COVER_IMAGE}
                  </Text>
                </div>
              }
            />
          </div>
        )}
    </div>
  );
};

export default CoverPagePreview;
