import React from "react";
import styles from "./styles.module.css";
import Text from "../Text";
import {
  casaConstants,
} from "../../constants/globalConstant";
import { teamsViewCardItems } from "../../utils/casaUtils";
import { getStatusColor } from "../../utils/globalUtils";
import { WebDashboardCardMenu } from "../WebDashboardCardMenu";

const WebTeamsViewDashboardCard = ({
  homepage,
  setDialog,
  teamId,
}) => { 
  const getHomepageStatusColor = (status) => {
    let _homepageStatus = "";
    if (
      status === casaConstants.PUBLISHED &&
      homepage.cloneDashboardId
    ) {
      _homepageStatus = casaConstants.UNPUBLISHED_CHANGES;
    } else {
      _homepageStatus = status;
    }
    return getStatusColor(_homepageStatus);
  };
  const homepageStatusColorClass =
    styles[getHomepageStatusColor(homepage?.status)];
  const statusStyleClass = styles["status-style"];
  const combinedClass = `${homepageStatusColorClass} ${statusStyleClass}`;

  return (
    <div
      className={`flex relative ${styles["dashboard-card-container"]}`}
      data-testid="teams-view-dashboard-card"
    >
      <div
        className={`flex align-items-center gap-1 p-3 w-full h-full ${styles["dashboard-card"]}`}
      >
        <div
          className={combinedClass}
        ></div>
        <Text type="T3B" color={"#314361"} className={`ellipsis w-full`}>
          {homepage?.name}
        </Text>
      </div>
      <div
        className={`flex w-full h-full align-items-center p-3 gap-1 justify-content-end absolute visible-on-hover ${styles["dashboard-card-hover"]}`}
      >
        <div className="bg-white p-1" style={{ borderRadius: "5px" }}>
          <WebDashboardCardMenu
            items={teamsViewCardItems(homepage?.status)}
            homepage={homepage}
            setDialog={setDialog}
            teamId={teamId}
          />
        </div>
      </div>
    </div>
  );
};

export default WebTeamsViewDashboardCard;
