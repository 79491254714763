import { stringConstants } from "../../constants/globalConstant";

const CheckInCheckOut = () => {
  const imgURL = `/images/widgetPreview/cico.svg`;

  return (
    <div>
      <img className="w-full" src={imgURL} alt={stringConstants.CICO} />
    </div>
  )
}

export default CheckInCheckOut;
