import React from 'react'
import ControlledMultiselect from '../../../../../components/LSQMultiSelect/ControlledMultiselect';
import { listOptionTemplate } from '../../../../../utils/dropdownUtils';

const renderMultiSelectDropdown = ({ field, form }) => (
    <ControlledMultiselect
      label={field.label}
      errors={form.formState.errors}
      className="w-full text-base lsq-input"
      isMandatory={field.isMandatory || false}
      fieldName={field.fieldName || "multiSelectDropdown"}
      control={form.control}
      options={field.options}
      optionLabel="name"
      placeholder="Select"
      itemTemplate={listOptionTemplate}
      panelClassName="lsq-multiselect-panel"
      selectionLimit={10}
      maxSelectedLabels={-1}
      id={field.fieldName}
      onChange={(e) => form.setValue(field.fieldName, e.target.value)}
    />
  );


export default renderMultiSelectDropdown