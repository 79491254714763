import React from "react";
import renderEntitySelection from "./FieldComponents/renderEntitySelection";
import renderDropdown from "./FieldComponents/renderDropdown";
import renderLSQNavigator from "./FieldComponents/renderLSQNavigator";
import renderCustomRadioGroup from "./FieldComponents/renderCustomRadioGroup";
import renderFileInput from "./FieldComponents/renderFileInput";
import renderSwitchController from "./FieldComponents/renderSwitchController";
import renderMultiSelectDropdown from "./FieldComponents/renderMultiSelectDropdown";
import renderIconPicker from "./FieldComponents/renderIconPicker";
import renderTextInput from "./FieldComponents/renderTextInput";
import renderRowComponent from "./FieldComponents/renderRowComponent";
import rearrangeIconLauncherItems from './FieldComponents/rearrangeIconLauncherItems';

const fieldRenderers = {
  ICON: renderIconPicker,
  TEXT: renderTextInput,
  DROPDOWN: renderDropdown,
  FILE: renderFileInput,
  SWITCH: renderSwitchController,
  MULTI_SELECT_DROPDOWN: renderMultiSelectDropdown,
  ROWCOMPONENT: renderRowComponent,
  ENTITYSELECTION: renderEntitySelection,
  RADIOGROUP: renderCustomRadioGroup,
  LSQ_NAVIGATOR: renderLSQNavigator,
  LSQ_ICON_LAUNCHER: rearrangeIconLauncherItems
};

const FieldComponentsParser = ({ field, form }) => {
  const renderField = fieldRenderers[field.type] || (() => null);
  return renderField({ field, form }) || <></>;
};

export default FieldComponentsParser;
