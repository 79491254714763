import { createAsyncThunk, createSlice, nanoid } from "@reduxjs/toolkit";
import {
  API_ADD_WIDGET,
  API_DELETE_SECTION,
  API_DELETE_WIDGET,
  API_GET_WIDGET,
  API_UPDATE_LAYOUT,
  API_UPDATE_WIDGET,
  API_GET_LAYOUT,
  API_REVERT_UNPUBLISHED_CHANGES,
} from "../api/configurationScreenServices";
import {
  addWidgetUtil,
  deleteWidgetUtil,
  doesSectionContainStandaloneWidget,
  findNumberOfWidgetsInASection,
  findWidgetPosition,
  isWidgetInsideARow,
  updateWidgetUtil,
  getPublishedViewSections,
} from "../utils/previewPaneUtils";
import cloneDeep from "lodash.clonedeep";
import { addHomepageLayout, changePublishState } from "./homePageSlice";
import {
  bannerSettingsConstants,
  casaConstants,
  imageGallerySettingsConstants,
  toastMessageConstants,
} from "../constants/globalConstant";
import { countLimit, formatWidgetConfig } from "../utils/globalUtils";
import { getWidgetTypeByWidgetId } from "../utils/widgetUtils";
import { widgetTypes } from "../constants/globalEnums";

const initialState = {
  isPreviewPaneDirty: false,
  isSaveLoading: false,
  loading: false,
  selectedSection: 0,
  widgetConfiguration: {},
  sections: [
    {
      sectionId: `section-${nanoid()}`,
      rows: [],
    },
  ],
  toastMessages: [],
  draftLayout: null,
  publishedLayout: null,
  hasDraftChanges: false,
  showCurrentChanges: true,
  isLayoutLoading:  false,
  smartViewCountRestrictionEnabled: false
};

export const getHomepageLayout = createAsyncThunk(
  "previewPane/getHomepageLayout",
  async (homepageId, thunkAPI) => {
    const response = await API_GET_LAYOUT(homepageId);
    const { status, data } = response;
    if( status !== 200) {
      const errorMessage = data?.message || toastMessageConstants.SOMETHING_WENT_WRONG
      return thunkAPI.rejectWithValue(errorMessage);
    }
    thunkAPI.dispatch(addHomepageLayout({homepageId, data}));
    thunkAPI.dispatch(toggleSmartViewCountRestriction(data?.smartViewCountRestrictionEnabled));
    return data;
  }
)

export const getWidgetConfiguration = createAsyncThunk(
  "previewPane/getLayoutSections",
  async (data) => {
    const { hasDraftChanges, draftLayout, mobileLayout } = data;
    const layout = hasDraftChanges ? draftLayout : mobileLayout;
    let { sections } = layout;
    let widgetIdList = [];
    sections?.forEach((section) => {
      section.rows.forEach((row) => {
        row.widgets.forEach((widget) => {
          widgetIdList.push(widget.widgetId);
        });
      });
    });
    let widgetTemplateConfigurationList = {};
    while (widgetIdList.length > 0) {
      let widgetPromiseList = [];
      const widgetBatch = widgetIdList.slice(0, 5);
      widgetIdList.splice(0, 5);
      widgetBatch.forEach((widgetId) => {
        widgetPromiseList.push(API_GET_WIDGET(widgetId).then(response => response?.data));
      });
      const widgetResponse = await Promise.allSettled(widgetPromiseList);
      let previewPaneData = {
        sections,
      };
      widgetResponse.forEach((widget, index) => {
        if (widget.value) {
          widgetTemplateConfigurationList[widget.value["widgetId"]] =
            formatWidgetConfig(widget?.value, hasDraftChanges);
        } else {
          previewPaneData = deleteWidgetUtil(
            previewPaneData,
            widgetBatch[index]
          );
          sections = previewPaneData.sections;
        }
      });
    }
    return {sections, widgetTemplateConfigurationList}
  })

export const getLayoutSections = createAsyncThunk(
  "previewPane/getLayoutSections",
  async (homepageId, thunkAPI) => {
    const {homepage} = thunkAPI.getState()
    const {selectedHomepageId, homepagesById} = homepage
    const {status} = homepagesById[selectedHomepageId]
    const data = (await thunkAPI.dispatch(getHomepageLayout(homepageId))).payload
    const {hasDraftChanges} = data
    const {widgetTemplateConfigurationList} = (await thunkAPI.dispatch(getWidgetConfiguration(data))).payload
    thunkAPI.dispatch(updateDraftAndPublishedLayout(data));
    const publishedStatus = !hasDraftChanges && status === casaConstants.PUBLISHED
    thunkAPI.dispatch(changePublishState(publishedStatus))
    return [widgetTemplateConfigurationList];
  }
);

export const getMemoizedLayout = createAsyncThunk(
  "previewPane/getMemoizedLayout",
  async (homepageId, thunkAPI) => {
    const {homepage} = thunkAPI.getState()
    const {homepagesById} = homepage
    const data = !homepagesById[homepageId].homepageLayout ? (
      (await thunkAPI.dispatch(getHomepageLayout(homepageId))).payload
    ) : homepagesById[homepageId].homepageLayout
    const {sections, widgetTemplateConfigurationList} = (await thunkAPI.dispatch(getWidgetConfiguration(data))).payload
    const publishedViewSections = getPublishedViewSections(
      sections,
      widgetTemplateConfigurationList
    );
    return publishedViewSections;
  }
);


export const apiAddWidget = createAsyncThunk(
  "previewPane/apiAddWidget",
  async (metaDataPostValue, thunkAPI) => {
    let previewPane = thunkAPI.getState().previewPane;
    if (
      findNumberOfWidgetsInASection(
        previewPane.selectedSection,
        previewPane
      ) === 5
    ) {
      return thunkAPI.rejectWithValue(100); // error code for out of limit widget in a section
    }
    if (
      doesSectionContainStandaloneWidget(
        previewPane.sections[previewPane.selectedSection]
      )
    ) {
      return thunkAPI.rejectWithValue(101); // error code for a section containing a webview
    }
    const widgetResponse  = await API_ADD_WIDGET(metaDataPostValue);
    const response = widgetResponse?.data;
    if(widgetResponse?.status !== 200) {
      const errorMessage = response?.message || toastMessageConstants.SOMETHING_WENT_WRONG
      return thunkAPI.rejectWithValue(errorMessage);
    }
    const { widgetTemplateId } = response.widget;
    let widgetType = getWidgetTypeByWidgetId(widgetTemplateId);
    const clonedPreviewPane = cloneDeep(previewPane);
    previewPane = {
      sections: [...clonedPreviewPane.sections],
    };
    const widgetInformation = {
      widgetId: response.widget.widgetId,
      type: isWidgetInsideARow(response.widget) ? "half" : "full",
      widgetType: widgetType,
    };
    if (widgetType === "quicklauncher") {
      previewPane = deleteWidgetUtil(clonedPreviewPane, "demoLauncherView");
    }
    if (widgetType === widgetTypes.BANNER || widgetType === widgetTypes.TILES_BANNER) {
      previewPane = deleteWidgetUtil(
        clonedPreviewPane,
        bannerSettingsConstants.DEMO_BANNER
      );
    }
    if (widgetType === widgetTypes.IMAGE_GALLERY) {
      previewPane = deleteWidgetUtil(
        clonedPreviewPane,
        imageGallerySettingsConstants.DEMO_GALLERY_IMAGE
      );
    }
    previewPane = addWidgetUtil(
      widgetInformation,
      clonedPreviewPane,
      clonedPreviewPane.selectedSection
    );
    const previewPaneRespone = await API_UPDATE_LAYOUT(
      previewPane.sections,
      thunkAPI.getState().homepage.selectedHomepageId
    );
    const {data, status} = previewPaneRespone
    if(status !== 200) {
      const errorMessage = data?.message || toastMessageConstants.SOMETHING_WENT_WRONG
      return thunkAPI.rejectWithValue(errorMessage);
    }
    thunkAPI.dispatch(updateDraftAndPublishedLayout(data));
    thunkAPI.dispatch(changePublishState(false));
    return {
      response,
      data,
    };
  }
);

export const apiUpdateWidget = createAsyncThunk(
  "previewPane/apiUpdateWidget",
  async (metaDataPostValue, thunkAPI) => {
    const updateWidgetResponse = await API_UPDATE_WIDGET(metaDataPostValue);
    const widgetResponse = updateWidgetResponse?.data
    if(updateWidgetResponse?.status !== 200) {
      const errorMessage = widgetResponse?.message || toastMessageConstants.SOMETHING_WENT_WRONG
      return thunkAPI.rejectWithValue(errorMessage);
    }
    const clonedPreviewPane = cloneDeep(thunkAPI.getState().previewPane);
    let previewPane = {
      sections: [...clonedPreviewPane.sections],
    };
    const type = isWidgetInsideARow(widgetResponse.widget) ? "half" : "full";
    const widgetId = widgetResponse.widget.widgetId;
    previewPane = updateWidgetUtil(previewPane, widgetId, type);
    const UpdatedPreviewPaneRespone = await API_UPDATE_LAYOUT(
      previewPane.sections,
      thunkAPI.getState().homepage.selectedHomepageId
    );
    const previewPaneRespone = UpdatedPreviewPaneRespone?.data
    if(UpdatedPreviewPaneRespone?.status !== 200) {
      const errorMessage = previewPaneRespone?.message || toastMessageConstants.SOMETHING_WENT_WRONG
      return thunkAPI.rejectWithValue(errorMessage);
    }
    thunkAPI.dispatch(updateDraftAndPublishedLayout(previewPaneRespone));
    thunkAPI.dispatch(changePublishState(false));
    return {
      widgetResponse,
      previewPaneRespone,
    };
  }
);

export const apiDeleteWidget = createAsyncThunk(
  "previewPane/apiDeleteWidget",
  async (widgetId, thunkAPI) => {
    const { previewPane, homepage } = thunkAPI.getState();
    const sections = previewPane.sections;
    const homepageId = homepage.selectedHomepageId;
    const { sectionIndex } = findWidgetPosition(widgetId, previewPane);
    const sectionId = sections[sectionIndex].sectionId;
    const isPreviewPaneDirty = previewPane.isPreviewPaneDirty;
    if(isPreviewPaneDirty){
      await thunkAPI.dispatch(apiUpdateLayout())
    }
    const previewPaneRespone = await API_DELETE_WIDGET(
      homepageId,
      sectionId,
      widgetId
    );
    if(previewPaneRespone?.status !== 200) {
      const errorMessage = previewPaneRespone?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG
      return thunkAPI.rejectWithValue(errorMessage);
    }
    thunkAPI.dispatch(getLayoutSections(homepageId));
    thunkAPI.dispatch(changePublishState(false));
    return {
      widgetId,
    };
  }
);

export const apiDeleteSection = createAsyncThunk(
  "previewPane/apiDeleteSection",
  async (sectionIndex, thunkAPI) => {
    const { homepage, previewPane } = thunkAPI.getState();
    const homepageId = homepage.selectedHomepageId
    const sectionId = previewPane.sections[sectionIndex].sectionId;
    const isPreviewPaneDirty = previewPane.isPreviewPaneDirty;
    if(isPreviewPaneDirty){
      await thunkAPI.dispatch(apiUpdateLayout())
    }
    const deleteSectionResponse = await API_DELETE_SECTION(homepageId, sectionId);
    if(deleteSectionResponse?.status !== 200) {
      const errorMessage = deleteSectionResponse?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG
      return thunkAPI.rejectWithValue(errorMessage);
    }
    thunkAPI.dispatch(changePublishState(false));
  }
);

export const apiUpdateLayout = createAsyncThunk(
  "previewPane/apiUpdateLayout",
  async (_, thunkAPI) => {
    const { previewPane, homepage } = thunkAPI.getState();
    if(previewPane.isPreviewPaneDirty === false) return; 
    const clonedPreviewPane = cloneDeep(previewPane);
    deleteWidgetUtil(
      clonedPreviewPane,
      "demoLauncherView"
    );
    deleteWidgetUtil(
      clonedPreviewPane,
      bannerSettingsConstants.DEMO_BANNER
    );
   let newPreviewPane = deleteWidgetUtil(
      clonedPreviewPane,
      imageGallerySettingsConstants.DEMO_GALLERY_IMAGE
    );
    const response = await API_UPDATE_LAYOUT(
      newPreviewPane.sections,
      homepage.selectedHomepageId
    );
    if(response?.status !== 200) {
      return thunkAPI.rejectWithValue(response.data);
    }
    thunkAPI.dispatch(updateDraftAndPublishedLayout({...response?.data , shouldUpdateSection: false}));
    return response?.data;
  }
);

export const apiDiscardChanges = createAsyncThunk(
  "previewPane/apiDiscardChanges",
  async (_, thunkAPI) => {
    const {selectedHomepageId, homepagesById} = thunkAPI.getState().homepage;
    const homepage = homepagesById[selectedHomepageId]
    const {homepageId, status} = homepage
    const response = await API_REVERT_UNPUBLISHED_CHANGES(homepageId);
    const { data } = response;
    if(response?.status !== 200) {
      const errorMessage = data?.message || toastMessageConstants.SOMETHING_WENT_WRONG
      return thunkAPI.rejectWithValue(errorMessage);
    }
    await thunkAPI.dispatch(getLayoutSections(homepageId));
    thunkAPI.dispatch(changePublishState(status === casaConstants.PUBLISHED))
    return data;
  }
);

const checkValue=(widget,_widgetConfig,showCurrentChanges)=>{
  if (widget.value) {
    _widgetConfig[widget.value["widgetId"]] = formatWidgetConfig(
      widget?.value,
      showCurrentChanges
    );
  }
}

export const togglePreviewPaneView = createAsyncThunk(
  "previewPane/togglePreviewPaneView",
  async (showCurrentChanges, thunkAPI) => {
    const _previewPane = thunkAPI.getState().previewPane;
    const { widgetConfiguration, draftLayout, publishedLayout , hasDraftChanges } = _previewPane;
    const _widgetConfiguration = { ...widgetConfiguration };
    const layout = (hasDraftChanges && showCurrentChanges) ? draftLayout : publishedLayout;
    const _sections = layout?.sections || [];
    let _widgetConfig = { ..._widgetConfiguration };
    let widgetIdList = [];
   
_sections.flatMap(section => section.rows || [])
.flatMap(row => row.widgets || [])
.forEach(widget => {
  const widgetId = widget?.widgetId;
  if ((_widgetConfiguration[widgetId] === undefined) || (!showCurrentChanges && !_widgetConfiguration[widgetId].publishedConfig)) {
    widgetIdList.push(widgetId);
  } else {
    _widgetConfig[widgetId] = {
      ..._widgetConfiguration[widgetId],
      config: showCurrentChanges
        ? _widgetConfiguration[widgetId].draftConfig
        : _widgetConfiguration[widgetId]?.publishedConfig,
    };
  }
});
    while (widgetIdList.length > 0) {
      let widgetPromiseList = [];
      const widgetBatch = widgetIdList.slice(0, 5);
      widgetIdList.splice(0, 5);
      widgetBatch.forEach((widgetId) => {
        widgetPromiseList.push(API_GET_WIDGET(widgetId).then(response => response?.data));
      });
      const widgetResponse = await Promise.allSettled(widgetPromiseList);
      widgetResponse.forEach((widget) => {
       checkValue(widget,_widgetConfig,showCurrentChanges)
      });
    }
    return { _sections, _widgetConfig, showCurrentChanges , hasDraftChanges };
  }
);

export const apiAddNewSection =  createAsyncThunk(
  "previewPane/apiAddNewSection", async (_, thunkAPI) => {
    const { sections } = thunkAPI.getState().previewPane
    const _sections = cloneDeep(sections)
    const newSection = {
      sectionId: `section-${nanoid()}`,
      rows: [],
    }
    _sections.push(newSection);
     thunkAPI.dispatch(updatePreviewPane({sections: _sections, selectedSection: sections.length}))
     thunkAPI.dispatch(setPreviewPaneDirty(true))
    thunkAPI.dispatch(apiUpdateLayout())
    return {sections: _sections}
  }
)

export const addNewTopFixedSection =  createAsyncThunk(
  "previewPane/addNewTopFixedSection", async (_, thunkAPI) => {
    const { sections } = thunkAPI.getState().previewPane
    const _sections = cloneDeep(sections)
    const newSection = {
      sectionId: `section-${nanoid()}`,
      rows: [],
      isFixed: true
    }
    _sections.unshift(newSection);
     thunkAPI.dispatch(updatePreviewPane({sections: _sections, selectedSection: 0}))
     thunkAPI.dispatch(setPreviewPaneDirty(true))
    return {sections: _sections}
  }
)

const previewPaneSlice = createSlice({
  name: "previewPane",
  initialState,
  reducers: {
    addSection: {
      reducer: (state, action) => {
        state.sections.push(action.payload);
        state.isPreviewPaneDirty = true
      },
      prepare: () => {
        return {
          payload: {
            sectionId: `section-${nanoid()}`,
            rows: [],
          },
        };
      },
    },
    deleteSection(state, action) {
      state.sections.splice(action.payload.sectionIndex, 1);
    },
    addWidget(state, action) {
      const { widgetLayout, widgetConfig } = action.payload;
      const { widgetId } = widgetLayout;
      let previewPane = {
        sections: [...state.sections],
      };
      state.widgetConfiguration[widgetId] = widgetConfig;
      const widgetInformation = { ...widgetLayout };
      const newPreviewPane = addWidgetUtil(
        widgetInformation,
        previewPane,
        state.selectedSection
      );
      state.sections = newPreviewPane.sections;
    },
    updateWidget(state, action) {
      const { widgetMetaValue } = action.payload;
      const previewPane = {
        sections: [...state.sections],
      };
      state = updateWidgetUtil(previewPane, widgetMetaValue);
    },
    deleteWidget(state, action) {
      const previewPane = {
        sections: [...state.sections],
      };
      const { widgetId } = action.payload;
      let newWidgetConfiguration = { ...state.widgetConfiguration };
      delete newWidgetConfiguration[widgetId];
      const newPreviewPane = deleteWidgetUtil(previewPane, widgetId);
      state.widgetConfiguration = newWidgetConfiguration;
      state.sections = newPreviewPane.sections;
    },
    updatePreviewPane(state, action) {
      state.sections = action.payload.sections;
      state.selectedSection = action.payload.selectedSection;
    },
    changeSelectedSection(state, action) {
      state.selectedSection = action.payload;
    },
    changeToastMessage(state, action) {
      state.toastMessages.push(action.payload);
    },
    resetToastMessage(state) {
      state.toastMessages.shift();
    },
    updateWidgetConfiguration(state, action) {
      const { widgetId, config } = action.payload.widget;
      let newWidgetConfiguration = { ...state.widgetConfiguration };
      newWidgetConfiguration[widgetId] = {
        ...newWidgetConfiguration[widgetId],
        config: config,
      };
      state.widgetConfiguration = newWidgetConfiguration;
    },
    setPreviewPaneDirty(state, action) {
      state.isPreviewPaneDirty = action.payload;
    },
    updateDraftAndPublishedLayout(state, action) {
      const { draftLayout, mobileLayout, hasDraftChanges, shouldUpdateSection = true } = action.payload;
      state.draftLayout = draftLayout;
      state.publishedLayout = mobileLayout;
      if(shouldUpdateSection){
        state.sections =
        (hasDraftChanges)
          ? draftLayout?.sections || draftLayout || []
          : mobileLayout?.sections || mobileLayout || [];
      }
      state.hasDraftChanges = hasDraftChanges;
      state.showCurrentChanges = hasDraftChanges? true : false
    },
    toggleSmartViewCountRestriction(state, action){
      state.smartViewCountRestrictionEnabled = action.payload;
    },
    resetPreviewPaneToInitialState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(apiAddWidget.fulfilled, (state, action) => {
      const { response } = action.payload;
      state.widgetConfiguration[response.widget.widgetId] = formatWidgetConfig(
        response?.widget,
        state.showCurrentChanges
      );
      state.loading = false;
      state.isPreviewPaneDirty = false;
    });
      builder.addCase(getLayoutSections.fulfilled, (state, action) => {
        const _widgetConfiguration = {
          ...state.widgetConfiguration,
          ...action.payload[0]
        }
        state.widgetConfiguration = _widgetConfiguration;
        state.loading = false;
      });
      builder.addCase(apiUpdateWidget.fulfilled, (state, action) => {
        const { widgetResponse } = action.payload;
        state.widgetConfiguration[widgetResponse.widget.widgetId] =
          formatWidgetConfig(widgetResponse.widget, state.showCurrentChanges);
        state.loading = false;
        state.isPreviewPaneDirty = false;
      });
      builder.addCase(apiDeleteWidget.fulfilled, (state) => {
        state.isPreviewPaneDirty = false;
      });
    builder.addCase(apiDeleteSection.fulfilled, (state) => {
      const { selectedSection, sections } = state;
      state.selectedSection =
        selectedSection > sections.length - 1
          ? sections.length - 1
          : selectedSection;
      state.loading = false;
      state.isPreviewPaneDirty = false;
    });
    builder.addCase(togglePreviewPaneView.fulfilled, (state, action) => {
      const { _sections, _widgetConfig, showCurrentChanges , hasDraftChanges } = action.payload;
      state.hasDraftChanges = hasDraftChanges
      state.widgetConfiguration = _widgetConfig;
      state.showCurrentChanges = showCurrentChanges;
      state.sections = _sections;
      state.loading = false;
    });
    builder.addCase(apiDiscardChanges.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(apiAddWidget.pending, (state) => {
      state.loading = true;
    });
      builder.addCase(getLayoutSections.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(apiUpdateWidget.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(apiDeleteWidget.pending, (state) => {
        state.loading = true;
      });
    builder.addCase(apiDeleteSection.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(apiDiscardChanges.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(togglePreviewPaneView.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(apiAddWidget.rejected, (state, action) => {
      state.loading = false;
      if (action.payload === 100) {
        state.toastMessages.push({
          type: "warning",
          title: "Warning",
          message: `Maximum ${countLimit.WIDGET_COUNT_ACROSS_SECTION} widgets can be added in a section`,
        });
      } else if (action.payload === 101) {
        state.toastMessages.push({
          type: "warning",
          title: "Warning",
          message: "To add widgets, please select another section. ",
        });
      } else {
        state.toastMessages.push({
          type: "error",
          title: "Error",
          message: "Error adding widget, please try again!",
        });
      }
    });
      builder.addCase(apiUpdateWidget.rejected, (state, action) => {
        state.loading = false;
        state.toastMessages.push({
          type: "error",
          title: "Error",
          message: action.payload,
        });
      });
      builder.addCase(apiDeleteWidget.rejected, (state, action) => {
        state.loading = false;
        state.toastMessages.push({
          type: "error",
          title: "Error",
          message: action.payload,
        });
      });
    builder.addCase(apiDeleteSection.rejected, (state, action) => {
      state.loading = false;
      state.toastMessages.push({
        type: "error",
        title: "Error",
        message: action.payload,
      });
    });
    builder.addCase(getLayoutSections.rejected, (state, action) => {
      state.loading = false;
      state.toastMessages.push({
        type: "error",
        title: "Error",
        message: action.payload,
      });
    });
    builder.addCase(apiUpdateLayout.fulfilled, (state) => {
      state.isPreviewPaneDirty = false;
      state.isSaveLoading = false;
    });
    builder.addCase(apiUpdateLayout.pending, (state) => {
      state.isSaveLoading = true;
    });
    builder.addCase(apiUpdateLayout.rejected, (state, action) => {
      state.isPreviewPaneDirty = true;
      state.isSaveLoading = false;
      const errorMessage = action.payload?.message || toastMessageConstants.SOMETHING_WENT_WRONG
      state.toastMessages.push({
        type: "error",
        title: "Error",
        message: errorMessage,
      });
    });
    builder.addCase(apiDiscardChanges.rejected, (state, action) => {
      state.loading = false;
      state.toastMessages.push({
        type: "error",
        title: "Error",
        message: action.payload,
      });
    });
    builder.addCase(togglePreviewPaneView.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getMemoizedLayout.rejected, (state, action) => {
      state.isLayoutLoading = false;
      state.toastMessages.push({
        type:  toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload,
      })
    });
    builder.addCase(getMemoizedLayout.pending, (state) => {
      state.isLayoutLoading = true;
    });
    builder.addCase(getMemoizedLayout.fulfilled, (state) =>{
      state.isLayoutLoading = false;
    });
  }
});

export const {
  addSection,
  deleteSection,
  addWidget,
  updateWidget,
  deleteWidget,
  updatePreviewPane,
  changeSelectedSection,
  resetToastMessage,
  changeToastMessage,
  setPreviewPaneDirty,
  updateWidgetConfiguration,
  updateDraftAndPublishedLayout,
  resetPreviewPaneToInitialState,
  toggleSmartViewCountRestriction
} = previewPaneSlice.actions;

export default previewPaneSlice.reducer;
