import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import styles from "./styles.module.css";
import { leadControlsConstants } from "../../../constants/globalConstant";
import { ReactComponent as Delete } from "../../../assets/svgIcons/lsq_delete.svg";
import {
  changeSelectedLeadCard,
  setLeadConfigurationDirty,
} from "../../../reducers/leadControlsSlice";
import { formatLeadCardActions } from "../../../utils/leadControlsUtils";

export default function LeadVisibleAction({
  visibleCardActions,
  availableCardActions,
}) {
  const dispatch = useDispatch();
  const { selectedLeadCard } = useSelector((state) => state.leadControls);
  const onDragEnd = (result) => {
    if (!result.destination) return;
    if (result.source.index === result.destination.index) return;
    visibleCardActions.move(result.source.index, result.destination.index);
    dispatch(setLeadConfigurationDirty(true));
  };

  useEffect(() => {
    dispatch(
      changeSelectedLeadCard({
        ...selectedLeadCard,
        visibleCardActions: formatLeadCardActions(visibleCardActions.fields),
      })
    );
  }, [visibleCardActions.fields]);

  return (
    <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
      <Droppable
        direction={"horizontal"}
        type="lead-card-actions"
        droppableId="lead-card-actions"
      >
        {(provided) => {
          return (
            <div
              className={`w-full ${styles["lead-actions-layout"]}`}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {visibleCardActions.fields?.map((element, index) => {
                return element.view === leadControlsConstants.FILLED ? (
                  <Draggable
                    index={index}
                    key={element.id}
                    draggableId={element.id}
                  >
                    {(provided) => {
                      return (
                        <div
                          className={`flex flex-row`}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          key={element.id}
                        >
                          <div
                            className={`flex align-items-center relative justify-content-center w-full ${styles["lead-actions-element"]}`}
                          >
                            <img
                              alt={element.name}
                              className={`${styles["lead-actions-icon"]}`}
                              src={`/images/launcherIcons/${element.imgName}.svg`}
                            />
                            <div
                              className={`flex border-round mr-1 absolute right-0 top-0 ${styles["delete-lead-action"]}`}
                              onClick={() => {
                                dispatch(setLeadConfigurationDirty(true));
                                availableCardActions.append(element);
                                visibleCardActions.update(index, {
                                  view: leadControlsConstants.EMPTY,
                                });
                              }}
                            >
                              <Delete
                                fill="var(--R700)"
                                style={{ width: "1rem" }}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    }}
                  </Draggable>
                ) : (
                  <div
                    key={index}
                    className={`w-full ${styles["visible-empty-lead-action"]}`}
                  ></div>
                );
              })}
              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
}
