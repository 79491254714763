import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Text from "../Text";
import { ReactComponent as NoUpcomingWidgets } from "../../assets/illustrations/no_widgets_available.svg";
import {
  globalConstants,
  mobileHomeBuilderConstants,
  toastMessageConstants,
  shimmerConstants
} from "../../constants/globalConstant";
import ExperimentalWidgetWrapper from "./ExperimentalWidgetWrapper";
import { NoResultsFound } from "../NoResultsFound";
import { ReactComponent as SomethingWentWrong } from "../../assets/illustrations/something_went_wrong.svg";
import { getUpComingWidgets } from "../../reducers/widgetTemplateListSlice";
import CustomButton from "../CustomButton";
import { ReactComponent as Retry } from "../../assets/svgIcons/lsq_retry.svg";
import { Shimmer } from "../Shimmer";
import { getImageNameFromImageUrl } from "../../utils/widgetUtils";
import { searchWidgets } from "../../utils/homepageUtils";
import { addLabWidget, resetNonSectionWidgets } from "../../reducers/labsSlice";
import CoachTooltip from "../Coach/CoachTooltip";
import Joyride from "react-joyride";
import { labsSteps } from "../Coach/coachSteps";
import { apiUpdateUserPreferences } from "../../reducers/homePageSlice";

export const ExperimentalWidgetList = ({
  addWidgets,
  setAddWidgets,
  suggestionBox,
  setSuggestionBox,
  setCustomFab,
  searchText = "",
}) => {
  const dispatch = useDispatch();
  const widgetTemplateList =
    useSelector((state) => state.widgetTemplateList) || {};
  const { status, list } =
    widgetTemplateList.widgetList.experimentalWidgetTemplates || {};
  let experimentalWidgetList = searchWidgets(
    Object.values(list || []),
    searchText
  );
  const { userPreferences } = useSelector((state) => state.homepage);
  const { labsCoachMarksVisited } = userPreferences;

  const retryHandler = () => {
    dispatch(getUpComingWidgets());
  };

  const noUpcomingWidgetsAvailable = () => {
    return (
      <div data-testid="no-upcoming-widgets-available">
        <Text type="T1" color={"var(--N100)"}>
          {mobileHomeBuilderConstants.NO_UPCOMING_WIDGETS_FOUND}
        </Text>
      </div>
    );
  };

  const onCoachSkippedOrFinished = () => {
    const payload = {
      labsCoachMarksVisited: true,
    };
    dispatch(apiUpdateUserPreferences(payload));
  };

  const somethingWentWrongContent = () => {
    return (
      <div
        data-testid="something-went-wrong"
        className="flex flex-column align-items-center justify-content-center gap-2"
      >
        <Text type="T1" color={"var(--N100)"}>
          {toastMessageConstants.SOMETHING_WENT_WRONG}
        </Text>
        <CustomButton
          data-testid="experimental-widgets-retry-button"
          varient="outline"
          label={mobileHomeBuilderConstants.RETRY}
          icon={<Retry />}
          onClick={retryHandler}
        />
      </div>
    );
  };

  const renderUpcomingWidgetList = () => {
    if (status === globalConstants.PENDING)
      return <Shimmer label={shimmerConstants.WIDGET_LIST} />;
    else if (
      status === globalConstants.FULFILLED &&
      experimentalWidgetList?.length !== 0
    )
    
      return experimentalWidgetList.map((widget, index) => (
        <ExperimentalWidgetWrapper
          key={widget?.id}
          widget={widget}
          addWidgets={addWidgets}
          setAddWidgets={setAddWidgets}
          suggestionBox={suggestionBox}
          setSuggestionBox={setSuggestionBox}
          index={index}
          isWidgetLiked={list[widget.id].userFeedback?.isWidgetLiked || false}
          setCustomFab={setCustomFab}
        >
          <img
            src={`/images/${getImageNameFromImageUrl(widget.imageUrl)}.png`}
            className="h-full w-full"
            alt={`${widget.title}`}
          />
        </ExperimentalWidgetWrapper>
      ));
    else
      return (
        <NoResultsFound
          Illustration={
            status === globalConstants.REJECTED
              ? SomethingWentWrong
              : NoUpcomingWidgets
          }
          children={
            status === globalConstants.REJECTED
              ? somethingWentWrongContent()
              : noUpcomingWidgetsAvailable()
          }
        />
      );
  };

  useEffect(() => {
    dispatch(getUpComingWidgets());
    return () => {
      dispatch(addLabWidget());
      dispatch(resetNonSectionWidgets());
    };
  }, []);
  const tooltipComponent = (props) => (
    <CoachTooltip
      {...props}
      onCoachSkippedOrFinished={onCoachSkippedOrFinished}
    />
  )
  return (
    <div
      className={`flex flex-column w-full h-full overflow-y-scroll pt-7`}
      id="widgetList-container"
    >
      {status === globalConstants.FULFILLED && (
        <Joyride
          tooltipComponent={tooltipComponent}
          continuous
          run={!labsCoachMarksVisited}
          showProgress
          showSkipButton
          steps={labsSteps}
          disableOverlayClose={true}
          disableCloseOnEsc={true}
          spotlightPadding={0}
          styles={{
            options: {
              overlayColor: "rgba(255, 255, 255, 0.6)",
            },
          }}
        />
      )}
      {renderUpcomingWidgetList()}
    </div>
  );
};
