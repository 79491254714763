import React from "react";
import { CustomDropdown } from "../../../../../components/CustomDropdown/CustomDropdown";
import { getEntityCountMap } from "../helper";
import { useSelector } from "react-redux";
import { casaWebConstants } from "../../../../../constants/globalConstant";
import {
  listOptionTemplate,
  listOptionTemplateWithCount,
  valueTemplate,
} from "../../../../../utils/dropdownUtils";

const renderDropdown = ({ field, form, hidden, ...props }) => {
  const { layout, casaWebLimits } = useSelector((state) => state.webHomepage);

  const isOptionDisabled = (option) => {
    const entityType = option?.id;
    const maxLimit = casaWebLimits?.entitiesLimit?.[entityType];
    const usedCount = entityCountMap?.[entityType];
    const isDisabled =
      entityType &&
      typeof maxLimit === casaWebConstants.NUMBER &&
      typeof usedCount === casaWebConstants.NUMBER &&
      usedCount >= maxLimit;

    return !!isDisabled;
  };
  const entityCountMap = getEntityCountMap(layout, casaWebLimits);
  const decideItemTemplate = () => {
    const isAnyRestriction = Object.keys(entityCountMap).length;
    const isEntityField = field?.fieldName === casaWebConstants.ENTITY;

    if (isAnyRestriction && isEntityField) {
      return (option) =>
        listOptionTemplateWithCount(option, entityCountMap, casaWebLimits);
    }

    return listOptionTemplate;
  };

  return (
    !hidden && (
      <CustomDropdown
        label={field.label}
        errors={form.formState.errors}
        className={`w-full text-base lsq-input`}
        fieldName={field.fieldName || "dropdown"}
        isMandatory={field.isMandatory || false}
        control={form.control}
        options={field.options}
        optionDisabled={isOptionDisabled}
        placeholder="Select"
        optionLabel="name"
        itemTemplate={decideItemTemplate()}
        valueTemplate={valueTemplate}
        {...props}
      />
    )
  );
};

export default renderDropdown;
