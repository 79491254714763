import { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  countLimit,
  dashboardsToTeamMapping,
  hierarchyToLinear,
} from "../utils/globalUtils";
import {
  changeSelectedHomepage,
  addToastMessage,
  cloneHomepage,
  apiAddHomepage,
} from "../reducers/homePageSlice";
import {
  unpublishWebHomePage,
  apiDeleteWebHomepage,
  apiGetAllWebDashboards,
  apiGetAllTeamsWebDashboards,
  publishWebHomePage,
  updateWebDashboardTeamAssignment,
  apiGetAllTeamsWebDashboardsV2
} from "../reducers/webHomepageSlice";
import useToast from "./useToast";
import analytics from "../utils/analytics";
import {
  globalConstants,
  dashboardCardConstants,
  ROUTES_PATH,
} from "../constants/globalConstant";
import { apiDeleteWebLaunchBar, unpublishWebLaunchBar,  apiGetAllTeamsWebLaunchBars,apiGetAllWebLaunchBars } from "../reducers/webLaunchbarSlice";

function useWebHomepageCard() {
  const { homepagesById } = useSelector((state) => state.homepage);
  const navigate = useNavigate();
  const toast = useToast(addToastMessage);
  const dispatch = useDispatch();
  const teamsHierarchy = useSelector((state) => state.homepage.teams);
  const teamDashboards = useSelector((state) => state.homepage.teamDashboards);
  const selectedTab = useSelector((state) => state.homepage.selectedTab);

  const teamsLinear = useMemo(() => {
    return hierarchyToLinear(teamsHierarchy);
  }, [teamsHierarchy]);
  useMemo(() => {
    return dashboardsToTeamMapping(
      teamDashboards?.teamsDashboards,
      teamsLinear
    );
  }, [teamDashboards, teamsLinear]);
  const cloneHomepageClicked = (homepage, sourceClicked = "") => {
    const homepageId = homepage.homepageId;
    if (Object.keys(homepagesById).length < countLimit.HOMEPAGES_COUNT) {
      dispatch(cloneHomepage(homepageId)).then(({ type, payload }) => {
        if (type.includes(globalConstants.FULFILLED)) {
          const clonedHomepage = payload;
          const clonedHomepageId = clonedHomepage.homepageId;
          dispatch(changeSelectedHomepage(clonedHomepageId));
          navigate(ROUTES_PATH.homebuilder);
        }
      });
      analytics.sendEvent(
        `clonenhomepage${sourceClicked && " clicked from " + sourceClicked}`,
        {
          homepageId: homepage.homepageId,
          status: homepage.status,
          homepageName: homepage.name,
        }
      );
    } else {
      toast.warn(
        `Maximum ${countLimit.HOMEPAGES_COUNT} homepages can be added`
      );
    }
  };

  const createHomepageClicked = (sourceClicked = "") => {
    if (Object.keys(homepagesById).length < countLimit.HOMEPAGES_COUNT) {
      dispatch(apiAddHomepage()).then(({ type }) => {
        if (type.includes(globalConstants.FULFILLED)) {
          navigate(ROUTES_PATH.homebuilder);
        }
      });
      const analytics_data = {
        category: "create_new_homepage",
        type: "click",
      };
      analytics.sendEvent(
        `create new homepage ${
          sourceClicked && " clicked from " + sourceClicked
        }`,
        analytics_data
      );
    } else {
      toast.warn(
        `Maximum ${countLimit.HOMEPAGES_COUNT} homepages can be added`
      );
    }
  };

  const deleteHomepageClicked = (homepage, sourceClicked = "") => {
    const homepageId = homepage.id;
    dispatch(apiDeleteWebHomepage(homepageId)).then(({ type }) => {
      if (type.includes(globalConstants.FULFILLED)) {
        dispatch(
          addToastMessage({
            type: globalConstants.SUCCESS,
            title: "Homepage Deleted",
            message: `${homepage.name} was deleted successfully!`,
          })
        );
      }
    }).then(()=>{
      dispatch(apiGetAllWebDashboards());
      dispatch(apiGetAllTeamsWebDashboards());
    })
    analytics.sendEvent(
      `delete homepage ${sourceClicked && " clicked from " + sourceClicked}`,
      {
        homepageId: homepage.homepageId,
        status: homepage.status,
        homepageName: homepage.name,
        tab: selectedTab === 0 ? "Teams View" : "Homepage View",
      }
    );
  };

  const unpublishHomepageClicked = (homepage, sourceClicked = "",) => {
    const homepageId = homepage.id;
    const postBody = {
      status: dashboardCardConstants.UNPUBLISHED,
    };
    dispatch(unpublishWebHomePage(homepageId)).then(({ type }) => {
      if (type.includes(globalConstants.FULFILLED)) {
        dispatch(
          addToastMessage({
            type: globalConstants.SUCCESS,
            title: `Homepage ${postBody.status}`,
            message: `${homepage.name} was successfully ${postBody.status}!`,
          })
        );
      }
    }).then(()=>{
      dispatch(apiGetAllWebDashboards());
      dispatch(apiGetAllTeamsWebDashboardsV2());
    })
    analytics.sendEvent(
      `unpublish homepage ${sourceClicked && " clicked from " + sourceClicked}`,
      {
        homepageId: homepage.homepageId,
        status: homepage.status,
        homepageName: homepage.name,
        tab: selectedTab === 0 ? "Teams View" : "Homepage View",
      }
    );
  };

  const publishHomepageClicked = (homepage, sourceClicked = "") => {
    const dashboardId = homepage.id;
    const postBody = {
      addTeams: [],
    }
    dispatch(publishWebHomePage({ dashboardId, postBody })).then(({ type }) => {
      if (type.includes(globalConstants.FULFILLED)) {
        dispatch(
          addToastMessage({
            type: globalConstants.SUCCESS,
            title: `Homepage Published`,
            message: `${homepage.name} was successfully published!`,
          })
        );
      }
    }).then(()=>{
      dispatch(apiGetAllWebDashboards());
      dispatch(apiGetAllTeamsWebDashboardsV2());
    })
    analytics.sendEvent(
      `publish homepage ${sourceClicked && " clicked from " + sourceClicked}`,
      {
        homepageId: homepage.id,
        status: homepage.status,
        homepageName: homepage.name,
        tab: selectedTab === 0 ? "Teams View" : "Homepage View",
      }
    );
  };

  const unassignHompeageClicked = (homepage, teamId, sourceClicked = "") => {
    const dashboardId = homepage.id;
 
    const postBody = {
      addTeams: [],
      deleteTeams: [teamId],
      isDefault: false
    };

    dispatch(
      updateWebDashboardTeamAssignment({ dashboardId, postBody })
    ).then(({ type }) => {
      if (type.includes(globalConstants.FULFILLED)) {
        dispatch(
          addToastMessage({
            type: globalConstants.SUCCESS,
            title: "Homepage Unassigned",
            message: `${homepage.name} was unassigned from the team!`,
          })
        );
      }
    }).then(()=>{
      dispatch(apiGetAllWebDashboards());
      dispatch(apiGetAllTeamsWebDashboardsV2());
    })
    analytics.sendEvent(
      `unassign homepage ${sourceClicked && " clicked from " + sourceClicked}`,
      {
        homepageId: homepage.id,
        status: homepage.status,
        homepageName: homepage.name,
        tab: selectedTab === 0 ? "Teams View" : "Homepage View",
      }
    );
  };
  const unpublishLaunchBarClicked = (homepage, sourceClicked = "") => {
    const homepageId = homepage.id;
    const postBody = {
      status: dashboardCardConstants.PUBLISHED,
    };
    dispatch(unpublishWebLaunchBar(homepageId)).then(({ type }) => {
      if (type.includes(globalConstants.FULFILLED)) {
        dispatch(
          addToastMessage({
            type: globalConstants.SUCCESS,
            title: `Homepage ${postBody.status}`,
            message: `${homepage.homepageName} was successfully ${postBody.status}!`,
          })
        );
      }
    }).then(()=>{
      dispatch(apiGetAllTeamsWebLaunchBars());
      dispatch(apiGetAllWebLaunchBars());
   });
    analytics.sendEvent(
      `publish homepage ${sourceClicked && " clicked from " + sourceClicked}`,
      {
        homepageId: homepage.homepageId,
        status: homepage.status,
        homepageName: homepage.homepageName,
        tab: selectedTab === 0 ? "Teams View" : "Homepage View",
      }
    );
  };
  const deleteLaunchBarClicked = (homepage, sourceClicked = "") => {
    const homepageId = homepage.id;
    dispatch(apiDeleteWebLaunchBar(homepageId)).then(({ type }) => {
      if (type.includes(globalConstants.FULFILLED)) {
        dispatch(
          addToastMessage({
            type: globalConstants.SUCCESS,
            title: "Homepage Deleted",
            message: `${homepage.name} was deleted successfully!`,
          })
        );
      }
    }).then(()=>{
       dispatch(apiGetAllTeamsWebLaunchBars());
       dispatch(apiGetAllWebLaunchBars());
    });
    analytics.sendEvent(
      `delete homepage ${sourceClicked && " clicked from " + sourceClicked}`,
      {
        homepageId: homepage.homepageId,
        status: homepage.status,
        homepageName: homepage.name,
        tab: selectedTab === 0 ? "Teams View" : "Homepage View",
      }
    );
  };
  return { cloneHomepageClicked, createHomepageClicked, deleteHomepageClicked, unpublishHomepageClicked, publishHomepageClicked, unassignHompeageClicked, unpublishLaunchBarClicked, deleteLaunchBarClicked};
}

export default useWebHomepageCard;
