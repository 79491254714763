import { useState, useCallback, useRef } from 'react';

export const useResizable = ({ initialWidth, initialHeight, minWidth, maxWidth, minHeight, maxHeight, onResize }) => {
  const [width, setWidth] = useState(initialWidth);
  const [height, setHeight] = useState(initialHeight);
  const startX = useRef(0);
  const startY = useRef(0);
  const startWidth = useRef(0);
  const startHeight = useRef(0);

  const onMouseDown = useCallback((e, direction) => {
    e.preventDefault();
    if (direction === 'horizontal') {
      startX.current = e.clientX;
      startWidth.current = width;
    } else {
      startY.current = e.clientY;
      startHeight.current = height;
    }
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  }, [width, height]);

  const onMouseMove = useCallback((e) => {
    const diffX = e.clientX - startX.current;
    const diffY = e.clientY - startY.current;

    if (startWidth.current !== 0) {
      const newWidth = startWidth.current + diffX;
      const steppedWidth = Math.round(newWidth / 4) * 4;
      const finalWidth = Math.max(minWidth, Math.min(maxWidth, steppedWidth));
      setWidth(finalWidth);
      onResize(finalWidth, height);
    }

    if (startHeight.current !== 0) {
      const newHeight = startHeight.current + diffY;
      const finalHeight = newHeight > parseInt((maxHeight + minHeight) / 2) ? maxHeight : minHeight;
      setHeight(finalHeight);
      onResize(width, finalHeight);
    }
  }, [minWidth, maxWidth, onResize, width, height]);

  const onMouseUp = useCallback(() => {
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
    startWidth.current = 0;
    startHeight.current = 0;
  }, [onMouseMove]);

  return { width, height, onMouseDown };
};

