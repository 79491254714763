import { useCallback, useState } from "react";
import styles from "./style.module.css";
import { useResizable } from "../../../../hooks/useResizable";
import { iconLauncherItemsConfigurations } from "../../../../constants/globalConstant";

const LauncherItem = (props) => {
  const { config, initialWidth, initialHeight, id, onResize } = props;
  const [activeResizer, setActiveResizer] = useState(null);

  const handleResize = useCallback((width, height) => {
    onResize(id, width, height);
  }, [id, onResize]);

  const minHeight = iconLauncherItemsConfigurations.minHeight;
  const maxHeight = iconLauncherItemsConfigurations.maxHeight;
  const minWidth = iconLauncherItemsConfigurations.minWidth;
  const maxWidth = iconLauncherItemsConfigurations.maxWidth;

  const { width, height, onMouseDown } = useResizable({
    initialWidth,
    initialHeight,
    minWidth,
    maxWidth,
    minHeight,
    maxHeight,
    onResize: handleResize
  });

  const handleMouseDown = (e, direction) => {
    setActiveResizer(direction);
    onMouseDown(e, direction);

    // Add mouseup listener to reset the active state
    const handleMouseUp = () => {
      setActiveResizer(null);
      document.removeEventListener('mouseup', handleMouseUp);
    };
    document.addEventListener('mouseup', handleMouseUp);
  };

  return (
    <div className={`${styles.itemWrapper} ${height === minHeight ? styles.minHeightWrapper : styles.maxHeightWrapper}`}
      style={{
        width: `${width}px`,
        height: `${height}px`,
        position: 'relative',
        minWidth,
        maxWidth,
        minHeight,
        maxHeight,
        transition: 'width 0.3s, height 0.3s'
      }}
    >
      <div className={styles.horizonatalResizer}>
        <div onMouseDown={(e) => handleMouseDown(e, 'horizontal')} data-testid="hz-resize"></div>
      </div>

      <div className={styles.verticalResizer}>
        <div onMouseDown={(e) => handleMouseDown(e, 'vertical')} data-testid="vt-resize"></div>
      </div>

      <div className={`${styles.itemSubWrapper} ${activeResizer ? styles.activeResizer : ''}`} title={config?.displayName}>
        <div className={styles.iconWrapper}>
          <img src={`/images/launcherIcons/${config?.icon?.imgName}.svg`} alt={config?.icon?.name} />
        </div>
        <div className={styles.itemHeading}>
          {config?.displayName}
        </div>
      </div>
    </div>
  );
};

export default LauncherItem;