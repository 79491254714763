import React from "react";
import { useSelector } from "react-redux";
import LauncherWidgetFactory from "./QuickLauncher/LauncherWidgetFactory";
import { TaskCounterWidgetFactory } from "./TaskCounter/TaskCounterWidgetFactory";
import TaskListWidgetFactory from "./TaskList/TaskListWidgetFactory";
import WebViewWidgetFactory from "./WebView/WebViewWidgetFactory";
import WidgetDraggableWrapper from "./WidgetDraggableWrapper";
import ErrorWidget from "./ErrorWidget";
import BannerWidget from "./Banner/BannerWidget";
import { LeadShortcutFactory } from "./LeadShortcut/LeadShortcutFactory";
import { LeadListFactory } from "./LeadList/LeadListFactory";
import { MyOpportunitiesFactory } from "./MyOpportunities/MyOpportunitiesFactory";
import SmartViewCounterFactory from "./SmartViewCounter/SmartViewCounterFactory";
import { ExperimentalWidgets } from "./ExperimentalWidgets";
import { MyScorecardFactory } from "./MyScorecard/MyScorecardFactory";
import { LeadMetrics } from "./LeadMetrics/index.js";
import { KeyLeadMetrics } from "./KeyLeadMetrics";
import { NearMe } from "./NearMe/NearMe";
import TaskCounterV2WidgetFactory from "./TaskCounterV2/TaskCounterV2WidgetFactory";
import { DistanceTravelled } from "./DistanceTravelled/DistanceTravelled";
import { TaskCounterFuelBar } from "./TaskCounterFuelBar/TaskCounterFuelBar";
import SieraAceWebViewWidgetFactory from "./SieraAceWebview/SieraAceWebViewWidgetFactory.js";
import TilesBannerWidget from "./TilesBanner/TilesBannerWidget.js";
import { widgetTypes } from "../constants/globalEnums.js";
import UserCheckinStatus from "./UserCheckinStatus/UserCheckinStatus.js";
import GalleryWidgetFactory from "./ImageGallery/GalleryWidgetFactory.js";
import CheckInCheckOut from "./CheckInCheckOut/index.js";

export const WidgetFactory = ({
  widgetInformation,
  index,
  isDummy = false,
}) => {
  const widgetConfiguartion = useSelector(
    (state) => state.previewPane.widgetConfiguration
  );

  const { widgetId, widgetType, type } = widgetInformation;
  const widgetTypeInformation = isDummy
    ? widgetInformation
    : widgetConfiguartion[widgetId];

  const draggableWrapper = (children) => {
    if (isDummy)
      return (
        <div
          style={{
            width: type === "half" ? "49%" : "95%",
            margin: "0.2rem 0px",
            position: "relative",
          }}
        >
          {children}
        </div>
      );
    return (
      <WidgetDraggableWrapper
        id={widgetId}
        index={index}
        widgetInformation={widgetConfiguartion[widgetId]}
        type={type}
      >
        {children}
      </WidgetDraggableWrapper>
    );
  };

  if (widgetConfiguartion[widgetId] === undefined && !isDummy) {
    return <></>;
  }

  const widgetComponentMap = {
    [widgetTypes.TASK_COUNTER]: (
      <TaskCounterWidgetFactory widgetInformation={widgetTypeInformation} />
    ),
    [widgetTypes.TASK_LIST]: (
      <TaskListWidgetFactory widgetInformation={widgetTypeInformation} />
    ),
    [widgetTypes.WEB_VIEW]: (
      <WebViewWidgetFactory widgetInformation={widgetTypeInformation} />
    ),
    [widgetTypes.QUICK_LAUNCHER]: (
      <LauncherWidgetFactory widgetInformation={widgetTypeInformation} />
    ),
    [widgetTypes.BANNER]: (
      <BannerWidget widgetInformation={widgetTypeInformation} />
    ),
    [widgetTypes.LEAD_SHORTCUT]: (
      <LeadShortcutFactory widgetInformation={widgetTypeInformation} />
    ),
    [widgetTypes.LEAD_LIST]: (
      <LeadListFactory widgetInformation={widgetTypeInformation} />
    ),
    [widgetTypes.MY_OPPORTUNITIES]: (
      <MyOpportunitiesFactory widgetInformation={widgetTypeInformation} />
    ),
    [widgetTypes.SMART_VIEWS_COUNTER]: (
      <SmartViewCounterFactory widgetInformation={widgetTypeInformation} />
    ),
    [widgetTypes.MY_SCORECARD]: (
      <MyScorecardFactory widgetInformation={widgetTypeInformation} />
    ),
    [widgetTypes.LEAD_METRICS]: (
      <LeadMetrics widgetInformation={widgetTypeInformation} />
    ),
    [widgetTypes.KEY_LEAD_MATRICS]: (
      <KeyLeadMetrics widgetInformation={widgetTypeInformation} />
    ),
    [widgetTypes.NEAR_ME]: <NearMe widgetInformation={widgetTypeInformation} />,
    [widgetTypes.TASK_COUNTER_V2]: (
      <TaskCounterV2WidgetFactory widgetInformation={widgetTypeInformation} />
    ),
    [widgetTypes.DISTANCE_TRAVELLED]: <DistanceTravelled />,
    [widgetTypes.TASK_COUNTER_FUEL_BAR]: (
      <TaskCounterFuelBar widgetInformation={widgetTypeInformation} />
    ),
    [widgetTypes.SIERA_ACE_WEBVIEW]: (
      <SieraAceWebViewWidgetFactory widgetInformation={widgetTypeInformation} />
    ),
    [widgetTypes.TILES_BANNER]: (
      <TilesBannerWidget widgetInformation={widgetTypeInformation} />
    ),
    [widgetTypes.USER_CHECKIN_STATUS]: (
      <UserCheckinStatus widgetInformation={widgetTypeInformation} />
    ),
    [widgetTypes.IMAGE_GALLERY]: (
      <GalleryWidgetFactory widgetInformation={widgetTypeInformation} />
    ),
    [widgetTypes.EXPERIMENTAL_WIDGETS]: (
      <ExperimentalWidgets widgetInformation={widgetTypeInformation} />
    ),
    [widgetTypes.CICO]: (
      <CheckInCheckOut widgetInformation={widgetTypeInformation} />
    ),
  };

  const WidgetComponent = widgetComponentMap[widgetType] || <ErrorWidget />;

  return draggableWrapper(WidgetComponent);
};
