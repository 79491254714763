import React ,{ useEffect } from "react";
import { useSelector } from "react-redux";
import { casaWebWidgetsConstants } from "../../../../../constants/globalConstant";
import SmartViewFilter from "../../../CasaWebWidgets/SmartViewFilterComponent/SmartViewFilter";
import TaskFilter from "../../../CasaWebWidgets/TaskFilterComponent/TaskFilter";
import SetThresholds from "../../../CasaWebWidgets/ThresholdsWidget/ThresholdsWidget";
import { GET_DATA_FROM_API_FOR_CASA_WEB } from "../../../../../api/webHompageConfigurationServices";
import { updateEntityMetaData } from "../../../../../reducers/webHomepageSlice";
import { useDispatch } from "react-redux";
import { webWidgetTypes } from "../../../../../constants/globalEnums";
import renderDropdown from "./renderDropdown";


const getSubMenu = async (navigationItem) => {
    const metaDataLink = navigationItem._links?.subMenu?.href;
    const { data } = await GET_DATA_FROM_API_FOR_CASA_WEB(metaDataLink);
    if (data === undefined) return;
    return data;
  };

const renderEntitySelection = ({ field, form }) => {
    const dispatch = useDispatch();
    const { selectedWidgetLanguage = {}} = useSelector(
        (state) => state.webHomepage
      );
    const { widgetType } = selectedWidgetLanguage
    const { entityMetaData } = useSelector((state) => state.webHomepage);
    const watchEntity = form.watch(field.fieldName);
    const watchSubField = form.watch("taskStatus"); // Watch the subfield value
    useEffect(() => {
      // Clear dependent fields when watchEntity changes
      form.setValue(
        "taskStatus",
        widgetType === webWidgetTypes.SPEEDO_METER ||
          widgetType === webWidgetTypes.PROGRESS_BAR
          ? {
              id: "completed",
              name: "Completed",
            }
          : null
      ); // Reset taskStatus before setting new values
      // form.setValue("thresholds", null);

      if (watchEntity && !entityMetaData[watchEntity.id]) {
        getSubMenu(watchEntity).then((payload) => {
          dispatch(
            updateEntityMetaData({
              ...entityMetaData,
              [watchEntity.id]: payload,
            })
          );
        });
      }

      // Check if `selectedWidgetLanguage` has `taskStatus` value and set it
      if (watchEntity && selectedWidgetLanguage?.config?.taskStatus) {
        form.setValue("taskStatus", selectedWidgetLanguage.config.taskStatus);
      }
    }, [watchEntity, selectedWidgetLanguage]);
    useEffect(() => {
      if (field && field.fieldName === "entity" && field.options.length === 1)
        form.setValue(field.fieldName, field.options[0]);
    }, [field]);
    return (
      <div className="flex flex-column gap-2">
        {renderDropdown({
          field,
          form,
          hidden:
            field && field.fieldName === "entity" && field.options.length === 1,
        })}
        {watchEntity && (
          <>
            <div style={{borderTop:' 1px solid var(--N30)'}}/>
            {watchEntity?.id === casaWebWidgetsConstants.TASKS && (
              <TaskFilter
                form={form}
                metaData={entityMetaData}
                widgetType={widgetType}
              />
            )}
            {watchEntity?.id === casaWebWidgetsConstants.SMART_VIEWS && (
              <>
                <SmartViewFilter form={form} metaData={entityMetaData} />
              </>
            )}
            {(watchEntity?.id === casaWebWidgetsConstants.SMART_VIEWS ||
              (watchSubField?.id === "completed" &&
                field?.includeSetThreshold)) && (
              <SetThresholds thresholdForm={form} />
            )}
          </>
        )}
      </div>
    );
  };
  export default renderEntitySelection