import { Controller } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import Text from "../Text";
import "./index.css";
import { FormErrorMessage } from "../FormErrorMessage";
import LsqProgressSpinner from "../LsqProgressSpinner";
import { globalConstants } from "../../constants/globalConstant";

export const CustomDropdown = ({
  fieldName,
  control,
  label,
  errors,
  id,
  isMandatory,
  errorMessage,
  optionLabel = "name",
  ...props
}) => {
  const emptyMessage = () => {
    if (props.options?.length === 0) {
      return <div>{globalConstants.NO_AVAILABLE_OPTIONS}</div>;
    } else return <LsqProgressSpinner strokeColor={"#314361"} />;
  };
  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{
        required: isMandatory,
        validate: (value) => {
          if (isMandatory) {
            if (value[optionLabel] === "" || value[optionLabel] === undefined) {
              if (errorMessage) return errorMessage;
              return "This field cannot be left blank";
            }
            return true;
          } else {
            return true;
          }
        },
      }}
      render={({ field, fieldState: { error } }) => (
        <span className={`p-float-label lsq-dropdown`}>
          <Dropdown
            emptyMessage={emptyMessage}
            id={id}
            {...field}
            optionLabel={optionLabel}
            {...props}
            style={error && { borderColor: "#E02020" }}
          />
          <label
            className="lsq-label flex align-items-center"
            htmlFor={field.name}
          >
            <Text color={error ? "#E02020" : "#768196"} type="T5">
              {label}
            </Text>
            {isMandatory && (
              <span style={{ color: "#E02020", fontSize: "0.8rem" }}> *</span>
            )}
          </label>
          {errors[fieldName] && (
            <FormErrorMessage message={errors[fieldName].message} />
          )}
        </span>
      )}
    />
  );
};
