import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { homepageConstants, ROUTES_PATH } from "../constants/globalConstant";
import Teams from "../views/Teams";
import WebTeams from "../views/WebTeams";
import HomepagesView from "../views/HomepagesView";
import HomeScreen from "../views/HomeScreen";
import MenuBuilder from "../views/MenuBuilder";
import Templates from "../views/Templates";
import MenuBuilderView from "../views/MenuBuilder/MenuBuilderView";
import { sideMenuOptions, sideMenuBottomOptions } from "../utils/homepageUtils";
import LeadControlsList from "../views/LeadControls/LeadControlsList";
import ThemeBuilderMain from "../views/ThemeBuilder/main.js";
import LeadControls from "../views/LeadControls/LeadControls";
import CasaWebHomepage from "../views/CasaWeb/CasaWebHomepage/WebDashBoard/CasaWebHomepage.js";
import LaunchBar from "../views/CasaWeb/LaunchBar/LaunchbarHome/index";
import LaunchbarHomepage from "../views/CasaWeb/LaunchBar/LaunchBar";
import PropTypes from "prop-types";
const SideNavigationMenu = React.lazy(() =>
  import("../components/SideNavigationMenu")
);
const WebHomePages = React.lazy(() =>
  import("../views/WebHomePages/index.js")
);

function AppRoutes({ featureConfig = { isDashboardEnabled: false, isCasaWebEnabled: false } }) {
  const featureNavigationConfig = {
    [homepageConstants.TEAMS] : featureConfig.isDashboardEnabled,
    [homepageConstants.MOBILE] : featureConfig.isDashboardEnabled,
    [homepageConstants.WEB] : featureConfig.isCasaWebEnabled
  }
  const sideNavigationOptions = sideMenuOptions.map((option) => {
    option.isEnabled = featureNavigationConfig[option.groupName]
    return option;
  })
  const landingPageOption = featureNavigationConfig[homepageConstants.MOBILE] ? "/" : ROUTES_PATH.webDashboards;
  return (
    <Router>
      <Suspense fallback={<div></div>}>
        <SideNavigationMenu
          featureConfig={featureConfig}
          sideMenuOptions={sideNavigationOptions}
          sideMenuBottomOptions={sideMenuBottomOptions}
        />
        <div className="main-content">
          <Routes>
            {featureNavigationConfig[homepageConstants.TEAMS] && <Route path={""} element={<Teams />} />}
            {featureNavigationConfig[homepageConstants.MOBILE] && 
            (<>
              <Route path={ROUTES_PATH.homepages} element={<HomepagesView />} />
              <Route path={ROUTES_PATH.menus} element={<MenuBuilderView />} />
              <Route path={ROUTES_PATH.homebuilder} element={<HomeScreen />} />
              <Route path={ROUTES_PATH.menubuilder} element={<MenuBuilder />} />
              <Route path={ROUTES_PATH.templates} element={<Templates />} />
              <Route path={ROUTES_PATH.themebuilder} element={<ThemeBuilderMain />} />
              <Route path={ROUTES_PATH.leadControls} element={<LeadControlsList />} />
              <Route path={ROUTES_PATH.leadControlBuilder} element={<LeadControls />} />
            </>)}
            {featureNavigationConfig[homepageConstants.WEB] && 
            (<>
              <Route path={ROUTES_PATH.webDashboards} element={<WebHomePages />} />
              <Route path={ROUTES_PATH.webTeams} element={<WebTeams />} />
              <Route path={ROUTES_PATH.webHomepage} element={<CasaWebHomepage />} />
              <Route path={ROUTES_PATH.launchBar} element={<LaunchBar />} /> 
              <Route path={ROUTES_PATH.launchBarHomePage} element={<LaunchbarHomepage />} /> 
            </>)}
            <Route path="*" element={<Navigate to={{ pathname: landingPageOption }} />} />
          </Routes>
        </div>
      </Suspense>
    </Router>
  );
}

AppRoutes.propTypes = {  
  featureConfig: PropTypes.shape({  
    isDashboardEnabled: PropTypes.bool,  
    isCasaWebEnabled: PropTypes.bool,  
  }),  
}; 


export default AppRoutes;
