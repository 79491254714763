import styles from './style.module.css';

export default function Loader() {
  return (
    <div>
      <div className={`${styles.skeleton} ${styles.small} ${styles.fullWidth}`} />
      <div className={`${styles.skeleton} ${styles.medium} ${styles.fullWidth}`} />

      <div className={styles.row}>
        <div className={`${styles.skeleton} ${styles.medium} ${styles.halfWidth}`} />
        <div className={`${styles.skeleton} ${styles.medium} ${styles.halfWidth}`} />
      </div>

      <div className={styles.row}>
        <div className={`${styles.skeleton} ${styles.medium} ${styles.halfWidth}`} />
        <div className={`${styles.skeleton} ${styles.medium} ${styles.halfWidth}`} />
      </div>

      {[...Array(4)].map((_, index) => (
        <div
          key={index}
          className={`${styles.skeleton} ${styles.small} ${styles.fullWidth}`}
        />
      ))}
    </div>
  );
}