import { useSelector, useDispatch } from 'react-redux';
import CustomButton from '../../../../components/CustomButton';
import { apiPostCloneWebDashboards, updateRenderLayoutConfig } from '../../../../reducers/webHomepageSlice';
import styles from "./style.module.css"
import analytics from '../../../../utils/analytics';

const ActionBar = ({ handleWebPreviewModeChange }) => {
  const dispatch = useDispatch();
  const { isWebHomepageLoading } = useSelector(state => state.webHomepage);
  const { cloneDashboardId, id: publishedDashboardId } = useSelector(state => state.webHomepage.selectedWebHomepage);
 
  const onEdit = async() => {
    if(!cloneDashboardId){
      const response = await dispatch(apiPostCloneWebDashboards(publishedDashboardId));
      const currentDashboardInfo = JSON.parse(localStorage.getItem("currentDashboardInfo"));
      currentDashboardInfo.cloneDashboardId = response?.payload?.id;
      localStorage.setItem("currentDashboardInfo", JSON.stringify(currentDashboardInfo));
    }

    const analytics_data = {
      category: "edit_published_template",
      type: "click",
    };
    analytics.sendEvent("edit button clicked for published template", analytics_data);

    dispatch(updateRenderLayoutConfig("clone"));
    handleWebPreviewModeChange(false);
  }

  return (
    <div className='flex mt-2 mb-2'>
      
      <div className={styles.buttonsWrapper}>
        <CustomButton
          varient="outline"
          label={"Edit"}
          onClick={onEdit}
          disabled={isWebHomepageLoading}
          data-testid="edit-published-web-template"
        />        
      </div>
    </div>
  )
}

export default ActionBar
