import React from 'react';
import iconLauncherPreview from "../../../../assets/illustrations/icon_launcher_preview.svg";
import styles from "./style.module.css"

const Preview = () => {
  return (
    <div className={styles.imageWrapper}>
      <img
        src={iconLauncherPreview}
        alt="icon launcher preview"
        className={styles.previewImage}
      />
    </div>
  )
}

export default Preview