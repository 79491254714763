export const getClassName = (...agrs) => {
  return agrs.filter(Boolean).join(" ");
};

export const capitalizeText = (text) => {
  if (!text) return '';
  return text
    .toLowerCase() // Convert all characters to lowercase
    .split(' ')    // Split the string into words
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter
    .join(' ');    // Join words back into a single string
}