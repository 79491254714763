import { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loader from "./Loader";
import Preview from "./Preview";
import LauncherItem from "./LauncherItem";
import { setIconLauncherItems } from "../../../../reducers/webHomepageSlice";
import { debounce } from "./utility";
import styles from "./style.module.css";


const IconLauncher = ({ widgetInformation, onWidgetEdit }) => {

  /* ======================================
  ************ DESTRUCTURING **************  
  ========================================*/
  const dispatch = useDispatch();
  const { selectedWidgetLanguage, iconLauncherItems, layout, isWebHomepageLoading } = useSelector(state => state.webHomepage);
  const iconLauncherItemsRef = useRef(iconLauncherItems);

  const widgetName = widgetInformation?.config?.name;
  const currentWidget = layout.find(widget => widget.id === widgetInformation?.id);
  const launcherItems = currentWidget?.config?.launcherItems ?? [];



  /* ======================================
  ************** USE EFFECT **************  
  ========================================*/
  useEffect(() => {
    iconLauncherItemsRef.current = iconLauncherItems;
  }, [iconLauncherItems]);



  /* ======================================
  *************** HANDLERS ****************  
  ========================================*/

  const handleChildResize = debounce((id, width, height) => {
    const latestIconLauncherItems = iconLauncherItemsRef.current;

    const items = JSON.parse(JSON.stringify(latestIconLauncherItems));
    const index = items.findIndex(item => item.uuid === id);
    items[index]["width"] = width;
    items[index]["height"] = height;

    dispatch(setIconLauncherItems(items));
  }, 500);

  const isEditDisabled = () => {
    if (selectedWidgetLanguage?.widgetType === "iconlauncher" && widgetInformation?.id === selectedWidgetLanguage?.id)
      return false;
    else
      return true;
  }


  /* ======================================
  ************** RETURNING UI ************** 
  ========================================*/


  // launcherItems: Rendered for preview purposes
  // iconLauncherItems: Rendered for widget editing purposes
  const renderItems = isEditDisabled() ? launcherItems : iconLauncherItems;

  // If no item added in widget edit mode, render <Preview />
  if (!renderItems?.length && !isEditDisabled()) {
    return <Preview />
  }

  if (isWebHomepageLoading && renderItems?.length === 0) {
    return <div className={styles.loaderWrapper} data-testid="loader-wrapper">
      <Loader />
    </div>
  }

  return <div className={styles.configParentWrapper} onClick={onWidgetEdit}>
    {isEditDisabled() && <div className={styles.disbaleEditTransparentLayer}></div>}

    <div className={styles.title}>
      {widgetName ? widgetName : "Quick Links"}
    </div>

    <div className={styles.configSubWrapper} id={widgetInformation?.id}>
      {renderItems?.map((config) => {
        const { uuid, width, height } = config;
        return (<LauncherItem
          key={uuid}
          config={config}
          initialWidth={width}
          initialHeight={height}
          id={uuid}
          onResize={handleChildResize}
        />)
      })}

    </div>
  </div>
}

export default IconLauncher