import React, { useCallback } from 'react'
import CustomInput from '../../../../../components/CustomInput';
import { useDispatch } from 'react-redux';
import { updateWebWidget } from '../../../../../reducers/webHomepageSlice';
import { useSelector } from 'react-redux';
import Text from '../../../../../components/Text';

const renderTextInput = ({ field, form }) => {
  const dispatch = useDispatch();
  const { selectedWidgetLanguage } = useSelector(
    (state) => state.webHomepage
  );
  const updateWidgetConfig = useCallback(
    (fieldName, value) => {
      dispatch(
        updateWebWidget({
          id: selectedWidgetLanguage?.id,
          config: {
            ...structuredClone(form.getValues()),
            [fieldName]: value,
          },
        })
      );
    },
    [dispatch, form, selectedWidgetLanguage]
  );

    return (
      <div className={`flex gap-2 flex-column`} key={field.id}>
        {field?.title && (
          <Text type="T4B" color="var(--N400)">
            {field.title}
          </Text>
        )}
        <CustomInput
          key={field.id}
          control={form.control}
          errors={form.formState.errors}
          fieldName={field.fieldName || "inputText"}
          maxLength={field.max}
          className="lsq-input-bold"
          label={field.label}
          isMandatory={field.isMandatory || false}
          data-testid={field.fieldName}
          onBlur={(e) => updateWidgetConfig(field.fieldName, e.target.value)}
          containerStyle={{ width: `100%` }}
          pattern={
            field.regex && {
              value: new RegExp(field.regex?.value),
              message: field.regex?.message,
            }
          }
        />
      </div>
    );
  };

export default renderTextInput