import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import CustomButton from "../components/CustomButton";
import CustomInput from "../components/CustomInput";
import CustomRadioGroup from "../components/CustomRadioGroup";
import TaskTypeMultiselectDropdown from "../components/TaskTypeMultiselectDropdown";
import { Skeleton } from "primereact/skeleton";
import { useDispatch } from "react-redux";
import { resetWidgetConfiguration } from "../reducers/widgetTemplateListSlice";
import { apiAddWidget, apiUpdateWidget } from "../reducers/previewPaneSlice";
import {
  formatTaskMetaValue,
  formatTaskMetaValueToTaskFieldValue,
} from "../components/TaskTypeMultiselectDropdown/TaskTypeMultiselectDropdownUtils";
import useWidgetEssentials from "../hooks/useWidgetEssentials";
import { formatTaskTypes, getWidgetPreviewOfExperimentalWidget, getWidgetSignature } from "../utils/widgetUtils";
import analytics from "../utils/analytics";
import { CustomDropdown } from "../components/CustomDropdown/CustomDropdown";
import { listOptionTemplate, valueTemplate } from "../utils/dropdownUtils";
import {
  globalConstants,
  stringConstants,
  taskCounterConfig,
} from "../constants/globalConstant";
import ObjectType from "./Helpers/ObjectType";

export default function TaskCounterSettings({ widgetMetaData }) {
  const { taskStatus, displayType, taskType, dateFilters, navigateTo } = widgetMetaData;
  const { selectedWidget, mode, selectedWidgetConfiguration, homepageId} =
    useWidgetEssentials();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const taskTypes = formatTaskTypes(taskType);
  const defaultValues = {
    taskTitle: "My Tasks",
    tasksToDisplay: {},
    taskStatus: taskStatus?.length > 0 &&  taskStatus[0].value,
    displayType: displayType?.length > 0 &&  displayType[0].value,
    dateFilters : { id : "Today", name  : "Today"},
    navigateTo : navigateTo?.length > 0 &&  navigateTo[0].value,
    objectTypes : [],
  };
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    watch,
    resetField,
    getValues
  } = useForm({ defaultValues });
   
  const dateFieldOptions = watch("taskStatus")  === globalConstants.PENDING
                          ? dateFilters?.pending    
                          : dateFilters?.overdue
  const onSubmit = (data) => {
    if(selectedWidget.isExperimental){
      getWidgetPreviewOfExperimentalWidget(selectedWidget.imageUrl, dispatch)
      return;
    }
    const displayType =  getValues("taskStatus") === globalConstants.PENDING
                            ? taskCounterConfig.SPEEDOMETER
                            : taskCounterConfig.NUMERIC
    const config = {
      title: data.taskTitle.trim(),
      taskType: formatTaskMetaValue(data.tasksToDisplay, taskTypes),
      taskStatus: data.taskStatus,
      displayType: displayType,
      dateFilters : data?.dateFilters,
      navigateTo : data?.navigateTo,
      objectTypes : data?.objectTypes,
    };
    const metaValueData = getWidgetSignature(
      homepageId,
      selectedWidget?.id,
      config
    );
    const analytics_data = {
      'category': 'Widget',
      'type': 'click',
      'widgetType': 'TaskCounter',
      'displayType': metaValueData.config.displayType,
      'taskStatus' : metaValueData.config.taskStatus,
      'navigateTo' : metaValueData.config.navigateTo,
    }
    if (mode === "UPDATE") {
      const updatedMetaData = {
        widgetId: selectedWidgetConfiguration?.metaValue.widgetId,
        config: {
          ...metaValueData.config,
        },
      };
      dispatch(apiUpdateWidget(updatedMetaData));
      analytics_data["widgetId"] = selectedWidgetConfiguration?.metaValue.widgetId;
      analytics.sendEvent('Updating_TaskCounter', analytics_data);
    } else if (mode === "CREATE") {
      dispatch(apiAddWidget(metaValueData));
      analytics.sendEvent('Adding_TaskCounter', analytics_data);
    }
    dispatch(resetWidgetConfiguration());
  };
  
  useEffect(() => {
    if (
      mode === "UPDATE" &&
      Object.keys(selectedWidgetConfiguration.metaValue).length !== 0
    ) {
        const taskMetaFieldValue = formatTaskMetaValueToTaskFieldValue(
        selectedWidgetConfiguration.metaValue.config.taskType,
        taskTypes
      );
      setValue("taskTitle", selectedWidgetConfiguration.metaValue.config.title);
      setValue("objectTypes", selectedWidgetConfiguration.metaValue.config?.objectTypes||[]);
      setValue(
        "taskStatus",
        selectedWidgetConfiguration.metaValue.config.taskStatus
      );
      setValue(
        "navigateTo",
        selectedWidgetConfiguration.metaValue.config.navigateTo
      );
      setValue("tasksToDisplay", taskMetaFieldValue);
      setValue("dateFilters", selectedWidgetConfiguration.metaValue.config.dateFilters)
    }
  }, []);

  const isTaskTypesLoaded =
    taskTypes.appointments.length > 0 || taskTypes.todos.length > 0;
  return (
    <form
      style={{ height: "90%" }}
      className="flex flex-column w-full"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex-1 overflow-y-auto">
        <div className={`flex flex-column mt-5 lg:mx-6 md:mx-5 sm: mx-4 gap-3`}>
          <div className={`flex flex-column gap-2`}>
            <CustomInput
              fieldName="taskTitle"
              errors={errors}
              control={control}
              isMandatory
              label="Display Name"
              maxLength={15}
              placeholder="Type Here"
            />
          </div>
          <ObjectType
            control={control}
            setValue={setValue}
            errors={errors}
          />
          {displayType === undefined ? (
            <Skeleton type="rectangle" width="60%" height="2rem"></Skeleton>
          ) : (
            <CustomRadioGroup
              control={control}
              label="Status"
              options={taskStatus}
              radioName="taskStatus"
              onChange={() => resetField("dateFilters")}
            />
          )}
          <div className={`flex flex-column gap-2`}>
            {isTaskTypesLoaded ? (
              <TaskTypeMultiselectDropdown
                isMandatory={true}
                taskTypes={taskTypes}
                errors={errors}
                fieldName="tasksToDisplay"
                control={control}
              />
            ) : (
              <Skeleton type="rectangle" width="70%" height="2rem"></Skeleton>
            )}
            <CustomDropdown
              fieldName="dateFilters"
              control={control}
              errors={errors}
              className="w-full text-base lsq-input"
              options={dateFieldOptions}
              optionLabel="name"
              placeholder="Select"
              itemTemplate={listOptionTemplate}
              valueTemplate={valueTemplate}
              label={t(stringConstants.SELECT_DEFAULT_DATE_RANGE)}
          />
          {navigateTo ? (
              <CustomRadioGroup
                control={control}
                label="Navigate to"
                options={navigateTo}
                radioName="navigateTo"
              />
            ) : (
              <Skeleton type="rectangle" width="60%" height="2rem"></Skeleton>
            )}
          </div>
        </div>
  
      </div>
      <div
        className={`flex w-11 py-2 mt-3 align-self-center align-items-center justify-content-end mt-auto gap-2`}
      >
        <CustomButton
          type="reset"
          onClick={() => reset()}
          varient="text"
          label="Reset"
        />

        <CustomButton type="submit" varient="filled" label="Save" />
      </div>
    </form>
  );
}

TaskCounterSettings.propTypes = {
  widgetMetaData: PropTypes.object,
};
