import React, { useEffect } from "react";
import CustomRadioGroup from "../../../../../components/CustomRadioGroup";
import ColorPicker from "../../../CasaWebWidgets/ColorPickerWidget/colorPicker";
import { defaultColor } from "../../../../../utils/casaWebUtils";
import renderFileInput from "./renderFileInput";
import { casaWebConstants } from "../../../../../constants/globalConstant";
import { useSelector } from "react-redux";

const renderCustomRadioGroup = ({ field, form }) => {
  const { coverPageData } = useSelector((state) => state.webHomepage);
  const defaultValue =
    coverPageData.customRadio === "color" ? "color" : "image";

  useEffect(() => {
    form.setValue("customRadio", defaultValue);
    if (defaultValue === "color" && coverPageData.selectedColor) {
      form.setValue("selectedColor", coverPageData.selectedColor);
    } else if (
      defaultValue === casaWebConstants.IMAGE &&
      coverPageData?.image
    ) {
      form.setValue(casaWebConstants.IMAGE, coverPageData.image);
    }
  }, [defaultValue, coverPageData, form]);

  return (
    <div>
      <CustomRadioGroup
        label={field.label}
        options={[
          { key: "Solid color", value: "color" },
          { key: "Image", value: "image" },
        ]}
        radioName={field.fieldName || "customRadio"}
        control={form.control}
        defaultValue={defaultValue}
      />
      {defaultValue === "color" ? (
        <ColorPicker
          colors={defaultColor}
          control={form.control}
          name="selectedColor"
        />
      ) : (
        <div className="p-3">
          {renderFileInput({ field, form, coverPageData })}
        </div>
      )}
    </div>
  );
};

export default renderCustomRadioGroup;
