import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CustomInput from "../components/CustomInput";
import { useForm } from "react-hook-form";
import { CustomDropdown } from "../components/CustomDropdown/CustomDropdown";
import ControlledMultiselect from "../components/LSQMultiSelect/ControlledMultiselect";
import CustomButton from "../components/CustomButton";
import { apiAddWidget, apiUpdateWidget } from "../reducers/previewPaneSlice";
import { resetWidgetConfiguration } from "../reducers/widgetTemplateListSlice";
import { useDispatch } from "react-redux";
import useWidgetEssentials from "../hooks/useWidgetEssentials";
import {
  getOptionsExistInTheList,
  getWidgetSignature,
  getUpdatedName,
  getWidgetPreviewOfExperimentalWidget,
} from "../utils/widgetUtils";
import { listOptionTemplate, valueTemplate } from "../utils/dropdownUtils";
import analytics from "../utils/analytics";
import {
  globalConstants,
  stringConstants,
  widgetConstants,
} from "../constants/globalConstant";
import ObjectType from "./Helpers/ObjectType";

export const LeadListSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [leadFieldOption, setLeadFieldOption] = useState([]);
  const { mode, selectedWidgetConfiguration, selectedWidget, homepageId } =
    useWidgetEssentials();
  const { leadFields, dateFilters } = selectedWidgetConfiguration.metaData;

  const defaultValues = {
    title: stringConstants.LEAD_LIST,
    leadFields: { id: "", name: "", leadFieldOptions: [] },
    leadFieldOption: [],
    dateFilters: { id: "Today", name: "Today" },
    objectTypes : [],
  };

  const {
    control,
    formState: { errors },
    reset,
    resetField,
    setValue,
    handleSubmit,
  } = useForm({ defaultValues: defaultValues });

  useEffect(() => {
    if (mode === globalConstants.UPDATE) {
      const outdatedMetaValue = selectedWidgetConfiguration.metaValue;
      const outdatedConfig = outdatedMetaValue.config;
      const { title, dateFilters, objectTypes } = outdatedConfig;
      const leadField = getUpdatedName(leadFields, outdatedConfig.leadFields);

      setValue("title", title);
      setValue("objectTypes", objectTypes);
      if (leadField) {
        setValue("leadFields", leadField);
        setLeadFieldOption(leadField.leadFieldOptions);
        setValue(
          "leadFieldOption",
          getOptionsExistInTheList(
            outdatedConfig.leadFieldOption,
            leadField.leadFieldOptions
          ) || []
        );
      }
      setValue("dateFilters", dateFilters);
    }
  }, []);

  const onLeadFieldChanged = (option) => {
    resetField("leadFieldOption");
    setLeadFieldOption(option.value.leadFieldOptions);
    setValue("leadFields", option.value);
  };

  const onResetClicked = () => {
    reset();
    setLeadFieldOption([]);
  };

  const onSubmit = (data) => {
    if (selectedWidget.isExperimental) {
      getWidgetPreviewOfExperimentalWidget(selectedWidget.imageUrl, dispatch);
      return;
    }
    if (!data) return;
    const config = {
      title: data.title.trim(),
      leadFields: data.leadFields,
      leadFieldOption: data.leadFieldOption,
      dateFilters: data?.dateFilters,
      objectTypes: data?.objectTypes,
    };

    const metaPostValue = getWidgetSignature(
      homepageId,
      selectedWidget.id,
      config
    );
    const analytics_data = {
      category: "Widget",
      type: "click",
      widgetType: "Leadlist",
      lead_field_schema: metaPostValue.config.leadFields?.id,
    };
    if (mode === globalConstants.CREATE) {
      dispatch(apiAddWidget(metaPostValue));
      analytics.sendEvent("Adding_leadlist", analytics_data);
    } else {
      const updatedMetaValue = {
        widgetId: selectedWidgetConfiguration?.metaValue.widgetId,
        config: {
          ...metaPostValue.config,
        },
      };
      dispatch(apiUpdateWidget(updatedMetaValue));
      analytics_data["widgetId"] =
        selectedWidgetConfiguration?.metaValue.widgetId;
      analytics.sendEvent("Updating_leadlist", analytics_data);
    }

    dispatch(resetWidgetConfiguration());
  };

  return (
    <form
      className="flex flex-column w-full relative h-full"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex-1 overflow-y-auto">
        <div className={`flex flex-column mt-5 lg:mx-6 md:mx-5 sm: mx-4 gap-3`}>
          <CustomInput
            control={control}
            errors={errors}
            fieldName="title"
            maxLength={30}
            className="lsq-input-bold"
            label="Display Name"
            isMandatory={true}
            data-testid="title"
          />
        </div>
        <div
          className={`flex flex-column mt-5 lg:mx-6 md:mx-5 sm: mx-4 gap-3 `}
        >
          <ObjectType
            control={control}
            setValue={setValue}
            errors={errors}
          />
          <CustomDropdown
            fieldName="leadFields"
            control={control}
            errors={errors}
            className="w-full text-base lsq-input"
            options={leadFields}
            optionLabel="name"
            placeholder="Select"
            itemTemplate={listOptionTemplate}
            valueTemplate={valueTemplate}
            label={t(widgetConstants.LEAD_CRITERIA)}
            isMandatory={true}
            onChange={onLeadFieldChanged}
          />
          <ControlledMultiselect
            label={t(widgetConstants.DEFAULT_FIELD)}
            isMandatory={true}
            errors={errors}
            className="w-full text-base lsq-input"
            fieldName="leadFieldOption"
            control={control}
            options={leadFieldOption}
            optionLabel="name"
            placeholder="Select"
            itemTemplate={listOptionTemplate}
            valueTemplate={valueTemplate}
            panelClassName="lsq-multiselect-panel"
            maxSelectedLabels={-1}
            id="lead-field-options"
            onChange={(e) => setValue("leadFieldOption", e.target.value)}
          />
          <CustomDropdown
            fieldName="dateFilters"
            control={control}
            errors={errors}
            className="w-full text-base lsq-input"
            options={dateFilters}
            optionLabel="name"
            placeholder="Select"
            itemTemplate={listOptionTemplate}
            valueTemplate={valueTemplate}
            label={t(stringConstants.SELECT_DEFAULT_DATE_RANGE)}
          />
        </div>
      </div>
      <div
        className={
          "flex flex-row align-items-center justify-content-end w-11 absolute bottom-0 pb-4 gap-2"
        }
      >
        <CustomButton
          type="reset"
          label="Reset"
          varient="text"
          onClick={onResetClicked}
        />
        <CustomButton type="submit" label="Save" varient="filled" />
      </div>
    </form>
  );
};
