import React, { useCallback, useState } from "react";
import {
  iconPickerTemplate,
  selectedIconTemplate,
} from "../../../../../utils/dropdownUtils";
import { CustomIconPicker } from "../../../../../components/CustomIconPicker/CustomIconPicker";
import { iconList } from "../../../../../utils/iconList";
import { quicklauncherConstants } from "../../../../../constants/globalConstant";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateWebWidget } from "../../../../../reducers/webHomepageSlice";
const renderIconPicker = ({ field, form }) => {
  const dispatch = useDispatch();
  const { selectedWidgetLanguage } = useSelector(
    (state) => state.webHomepage
  );
  const updateWidgetConfig = useCallback(
    (fieldName, value) => {
      dispatch(
        updateWebWidget({
          id: selectedWidgetLanguage?.id,
          config: {
            ...structuredClone(form.getValues()),
            [fieldName]: value,
          },
        })
      );
    },
    [dispatch, form, selectedWidgetLanguage]
  );
  const [iconPanelWidth, setIconPanelWidth] = useState(0);
  return (
    <CustomIconPicker
      key={field.id}
      id="icon-list"
      fieldName={field.fieldName || "icon"}
      control={form.control}
      className="w-full text-base lsq-input lsq-icon-picker"
      options={iconList}
      optionLabel="name"
      filter
      isMandatory={field.isMandatory || false}
      filterBy="name"
      resetFilterOnHide
      filterPlaceholder={quicklauncherConstants.SEARCH_ICONS}
      placeholder="lsq_change_image"
      valueTemplate={selectedIconTemplate}
      itemTemplate={iconPickerTemplate}
      label={quicklauncherConstants.ICON}
      errors={form.formState.errors}
      panelClassName="lsqIconPickerPanel"
      onShow={() => {
        setIconPanelWidth(250);
      }}
      panelStyle={{ width: iconPanelWidth }}
      containerStyle={{ width: `${field?.width + 8}%` }}
      onChange={(e) => {
        form.setValue(field.fieldName, e.target.value);
        updateWidgetConfig(field.fieldName, e.target.value);
      }}
    />
  );
};

export default renderIconPicker;
