import React, { useState, useRef, useEffect } from "react";
import "./calander.css";
import { ChevronLeft, ChevronRight } from "lucide-react";
import ToggleTab from "../../../../components/ToggleTab";
import DynamicSVG from "../DynamicSVGComponent/dynamicSVG";
import Lead from "./../../../../assets/svgIcons/lead.svg";
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const tabs = [
  { value: "Timeline", key: "timeline", id: "timeline" },
  { value: "Agenda", key: "agenda", id: "agenda" },
];

const events = {
  "2024-06-06": [
    {
      time: "01:10",
      title: "Meeting",
      color: "#6666ff",
      person: "Mr. Manikandan",
    },
    {
      time: "03:00",
      title: "Collect KYC Documents",
      person: "Mr. Manikandan",
      color: "#66ccff",
    },
    {
      time: "11:00",
      title: "Collect KYC Documents",
      person: "Suchitra",
      color: "#cc66ff",
    },
    { time: "13:00", title: "Karthik Follow-up", color: "#ff9933" },
  ],
  "2024-06-07": [
    { time: "00:02", title: "Team Meeting", color: "#6666ff" },
    {
      time: "14:00",
      title: "Client Call",
      person: "John Doe",
      color: "#66ccff",
    },
  ],
};

const TaskCard = ({ event }) => {
  return (
    <div
      className="flex-1 bg-white p-2 pl-1 shadow-1 flex align-items-center"
      style={{
        borderRadius: 4,
        borderLeft: "3px solid var(--S800)",
      }}
    >
      <div className="flex align-items-start">
        <div className="flex-1">
          <div
            className="text-xs font-medium text-800"
            style={{ textWrap: "nowrap" }}
          >
            {event.title}
          </div>
          {event.person && (
            <div
              className="text-xs flex align-items-center flex-nowrap"
              style={{
                background: "#EBECFA",
                borderRadius: 4,
                padding: 2,
                color: "#303DB1",
                textWrap: "nowrap",
              }}
            >
              <img src={Lead} className="mr-1" /> {event.person}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const TimelineTodos = ({events=[]}) => {
  return (
    <div className="timeline-header">
      <div className="flex p-1">
        <label className="ml-2 text-xs">3 To-dos</label>
        <div className="flex ml-auto">
          <button className="scroll-button" role="left-button">
            <ChevronLeft size={20} />
          </button>
          <button className="scroll-button ml-1" role="right-button">
            <ChevronRight size={20} />
          </button>
        </div>
      </div>
      <div className="todo-container"> {events.map(event=><TaskCard event={event}/>)}</div>
    </div>
  );
};

const Calendar = ({ onWidgetEdit, widgetInformation = {} }) => {
  const { config } = widgetInformation;
  const [currentDate, setCurrentDate] = useState(new Date(2024, 5, 7));
  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const timelineRef = useRef(null);

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = monthNames[date.getMonth()].substring(0, 3);
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  const changeDate = (days) => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + days);
    setCurrentDate(newDate);
  };

  const updateEvents = () => {
    const dateKey = currentDate.toISOString().split("T")[0];
    return events[dateKey] || [];
  };

  const switchTab = (tab) => {
    setCurrentTab(tab);
  };

  useEffect(() => {
    if (timelineRef.current && currentTab.key === "timeline") {
      const currentHour = new Date().getHours();
      timelineRef.current.scrollTop = currentHour * 48 - 96; // Adjusted for new hour height
    }
  }, [currentTab]);

  const Timeline = ({ events }) => {
    const hours = Array.from({ length: 24 }, (_, i) => i);
    const hourHeight = 48; // Compact height for each hour

    return (
      <div className="timeline-header">
        <div className="flex p-1">
          <label className="ml-2 text-xs">3 To-dos</label>
          <div className="flex ml-auto">
            <button className="scroll-button" role="left-button">
              <ChevronLeft size={20} />
            </button>
            <button className="scroll-button ml-1" role="right-button">
              <ChevronRight size={20} />
            </button>
          </div>
        </div>
        <div
          ref={timelineRef}
          className="timeline active relative overflow-y-auto z-0"
          id="timeline"
          style={{ height: "calc(100% - 20px)" }}
        >
          <div className="relative" style={{ height: `${hourHeight * 24}px` }}>
            {hours.map((hour) => (
              <div
                key={hour}
                className="border-top-1 border-300 absolute w-full"
                style={{
                  top: `${hour * hourHeight}px`,
                  height: `${hourHeight}px`,
                }}
              >
                <span
                  className="absolute text-500 text-xs"
                  style={{ left: "0.5rem", width: "2.5rem" }}
                >
                  {`${hour.toString().padStart(2, "0")}:00`}
                </span>
              </div>
            ))}
            {events.length > 0 ? (
              events.map((event, index) => {
                const [hours, minutes] = event.time.split(":").map(Number);
                const top =
                  hours * hourHeight +
                  (minutes / 60) * hourHeight -
                  hourHeight / 2; // Center the event on its time
                return (
                  <div
                    key={index}
                    className="flex align-items-center absolute w-full pr-3"
                    style={{ top: `${top}px`, paddingLeft: "3rem" }}
                  >
                    <TaskCard event={event} />
                  </div>
                );
              })
            ) : (
              <div className="flex justify-content-center align-items-center h-full">
                <p className="text-500 text-sm">No data found for this date.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const Agenda = ({ events }) => (
    <div className="agenda" id="agenda">
      {events.map((event, index) => (
        <div key={index} className="agenda-item">
          <div className="agenda-time">{event.time}</div>
          <div className="agenda-event">
            <div className="agenda-title">{event.title}</div>
            {event.person && (
              <div className="agenda-person">{event.person}</div>
            )}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div
      className="calendar-container h-full w-full"
      onClick={onWidgetEdit}
      data-testid="calendar-container"
    >
      <div className="calendar-title">
        <DynamicSVG
          className="calendar-widget-svg"
          color={"#292929"}
          iconName={config?.icon?.imgName || "lsq_calendar"}
          width="16px"
          height="16px"
          opacity={0.9}
        />
        <label
          className="overflow-ellipsis"
          title={config?.name}
          style={{ color: "var(--N600)", fontSize: "14px" }}
        >
          {" "}
          {config?.name}
        </label>
      </div>
      <ToggleTab
        data-testid="tab-option"
        tabs={tabs}
        activeTab={currentTab}
        setActiveTab={switchTab}
      />
      <div className="calendar-nav">
        <button className="nav-button" onClick={() => changeDate(-1)}>
          <ChevronLeft  role='prev-date'/>
        </button>
        <span className="calendar-date">{formatDate(currentDate)}</span>
        <button className="nav-button" onClick={() => changeDate(1)}>
          <ChevronRight role='next-date'/>
        </button>
      </div>
      {currentTab?.key === "timeline" && <TimelineTodos events={updateEvents()} />}
      <div className="calendar-body">
        {currentTab?.key === "timeline" ? (
          <Timeline events={updateEvents()} />
        ) : (
          <Agenda events={updateEvents()} />
        )}
      </div>
    </div>
  );
};

export default Calendar;
