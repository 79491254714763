import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import WebTeamsViewDashboardCard from "../../components/WebTeamsViewDashboardCard";
import { apiUpdateWebTeamsDashboardOrder } from "../../reducers/homePageSlice";
import { useDispatch } from "react-redux";
import analytics from "../../utils/analytics";

const TeamsContainer = ({
  isScrollable = false,
  dragAndDrop = false,
  homepages = [],
  homepagesById = {},
  teamId = "",
  setPreviewPanel,
  setDialog,
}) => {
  const dispatch = useDispatch();
  const clonedhomepages = homepages?.filter(
    (homepageId) => homepagesById[homepageId] !== undefined
  );
  const onDragEnd = (result) => {
    const destination = result?.destination;
    const source = result?.source;

    if (source === null || destination === null) return;

    const sourceIndex = source.index;
    const destinationIndex = destination.index;

    const [removed] = clonedhomepages.splice(sourceIndex, 1);
    clonedhomepages.splice(destinationIndex, 0, removed);
    const postBody = {
      dashboardIds: [...clonedhomepages],
    };
    dispatch(
      apiUpdateWebTeamsDashboardOrder({ teamId: source.droppableId, postBody })
    );
    
    analytics.sendEvent("web_teams_order_changed", {
      teamId: source.droppableId,
    });
  };

  if (clonedhomepages.length === 0) return <></>;
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        type="dashboard-cards"
        droppableId={teamId || "container-1"}
        direction={"horizontal"}
      >
        {(provided) => {
          return (
            <div
              key="container-1"
              ref={provided.innerRef}
              className={`flex gap-1 ${
                isScrollable ? `flex-row flex-nowrap` : "flex-row flex-wrap"
              }`}
              {...provided.droppableProps}
            >
              {clonedhomepages?.map((homepageId, index) => {
                const homepage = homepagesById[homepageId];
                return (
                  <Draggable
                    isDragDisabled={!(dragAndDrop && isScrollable)}
                    key={"id" + homepageId}
                    index={index}
                    draggableId={"id" + homepageId}
                  >
                    {(provided) => (
                      <div
                        key={"id" + homepageId}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <WebTeamsViewDashboardCard
                          key={index}
                          homepage={homepage}
                          setPreviewPanel={setPreviewPanel}
                          setDialog={setDialog}
                          teamId={teamId}
                        ></WebTeamsViewDashboardCard>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};

export default TeamsContainer;
