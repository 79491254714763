import React, { useEffect, useState } from "react";
import LsqMobileNavigator from "../../../../../components/LsqMobileNavigator";
import { useDispatch } from "react-redux";
import { globalConstants } from "../../../../../constants/globalConstant";
import { useSelector } from "react-redux";
import { apiGetWebLauncehBarMetaData } from "../../../../../reducers/webLaunchbarSlice";

const renderLSQNavigator = ({ field, form }) => {
  const dispatch = useDispatch();
  const { selectedWidgetLanguage } = useSelector((state) => state.webHomepage);
  const mode = selectedWidgetLanguage.mode;
  const { launchBarFormMetaData } =
    useSelector((state) => state.webLaunchBar) || {};
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(true);
  const [edit, setEdit] = useState(mode === globalConstants.UPDATE);
  const [navigationList, setNavigationList] = useState(
    field?.showExternalLink
      ? [
          {
            name: "External Link",
            items: [{ id: "ExternalLink", name: "-----" }],
          },
          {
            name: "Internal Navigation",
            items: [],
          },
        ]
      : [
          {
            name: "Internal Navigation",
            items: [],
          },
        ]
  );
  useEffect(() => {
    const fetchMetaData = async () => {
      const formFilterList = field?.options || [];
      let filteredMenu;
      if (launchBarFormMetaData?.menu?.length > 0)
        filteredMenu = launchBarFormMetaData?.menu.filter((item) =>
          formFilterList.includes(item.id)
        );
      else {
        let data = await dispatch(apiGetWebLauncehBarMetaData());
        filteredMenu = data.payload.menu.filter((item) =>
          formFilterList.includes(item.id)
        );
      }

      setNavigationList((prevList) => {
        const updatedList = [...prevList];
        updatedList[1].items = filteredMenu;
        return updatedList;
      });
      setIsLoading1(false);
    };

    fetchMetaData();
  }, [dispatch]);
  if (isLoading1) return;
  return (
    <div className={`flex gap-2 flex-column`} key={field.id}>
      <LsqMobileNavigator
        formValues={form}
        mobileNavigationMenu={navigationList}
        unregister={form.unregister}
        edit={edit}
        setEdit={setEdit}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        mode={mode}
        application="WEB"
        displayExternalLinkOpeningOptions={false}
      />
    </div>
  );
};

export default renderLSQNavigator;
