import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.css";
import { InputSwitch } from "primereact/inputswitch";
import { Controller } from "react-hook-form";
import "./index.css";
import Text from "../Text";

export default function LsqSwitch({
  control,
  switchName,
  label,
  disabled,
  ...props
}) {
  return (
    <div className={styles.wrapper} data-testid="lsq-switch-v2">
      {label && <Text type="T4">{label}</Text>}
      {control ? (
        <Controller
          name={switchName}
          control={control}
          render={({ field }) => {
            return (
              <InputSwitch
                className="lsq-switch-v2"
                checked={field.value === true}
                disabled={disabled}
                onChange={(e) => field.onChange(e.value)}
                {...props}
              />
            );
          }}
        />
      ) : (
        <InputSwitch className="lsq-switch-v2" disabled={disabled} {...props} />
      )}
    </div>
  );
}

LsqSwitch.propTypes = {
  control: PropTypes.object,
  label: PropTypes.string,
  switchName: PropTypes.string,
};
