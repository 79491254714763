import { getRecentlyModifiedDate } from "../../utils/globalUtils";

export const getTeamIdBasedMapping = (data = []) => {
  return Object.fromEntries(data?.map((team) => [team.teamId, team])
  );
};

export const getAllTeamsDataForTable = (
  linearTeams,
  dashboardsbyTeamId,
  launchersbyTeamId,
  defaultDashboards,
  defaultLauncher
) => {
  const defaultTeam = {
    teamLabel: "Default",
    teamId: defaultDashboards?.teamId || defaultLauncher?.teamId,
    dashboards: defaultDashboards?.dashboards || [],
    launcher: defaultLauncher?.launcherId,
  };
  const teamsData = linearTeams.map((team) => ({
    teamLabel: team.label,
    teamId: team.teamId,
    dashboards: dashboardsbyTeamId[team.teamId]?.dashboards || [],
    launcher: launchersbyTeamId[team.teamId]?.launcherId,
    updatedAt: getRecentlyModifiedDate(
      dashboardsbyTeamId[team.teamId]?.updatedAt,
      launchersbyTeamId[team.teamId]?.updatedAt
    ),
  }));

  return { defaultTeam, teamsData };
};