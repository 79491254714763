export const getLeadIdentifier = (selectedLeadControl) => {
  if (selectedLeadControl?.leadCard?.config?.title?.firstA)
    return {
      name:
        selectedLeadControl?.leadCard?.config?.title?.firstA +
        " " +
        selectedLeadControl?.leadCard?.config?.title?.firstB,
      label:
        selectedLeadControl?.leadCard?.config?.title?.firstA.charAt(0) +
          selectedLeadControl?.leadCard?.config?.title?.firstB?.charAt(0) || "",
    };
  return {
    name: "",
    label: "NA",
  };
};
