import { findNumberOfWidgetsInASection } from "./previewPaneUtils";
import { casaConstants, globalConstants } from "../constants/globalConstant";
import { changeToastMessage } from "../reducers/previewPaneSlice";
import moment from "moment";

export const delay = (delay) => {
  return new Promise((res) => setTimeout(res, delay));
};

export const checkIfTeamExist = (teamId, teamList) => {
  return teamList.some((team) => team?.teamId === teamId);
};

export const dashboardsToTeamMapping = (teamDashboards = [], teamList = []) => {
  let dashboardsToTeamCountMapping = {};
  teamDashboards.forEach((team) => {
    if (checkIfTeamExist(team?.teamId, teamList)) {
      team.homepages?.forEach((homepageId) => {
        if (dashboardsToTeamCountMapping[homepageId]) {
          dashboardsToTeamCountMapping[homepageId].push(team.teamId);
        } else {
          dashboardsToTeamCountMapping[homepageId] = [team.teamId];
        }
      });
    }
  });
  return dashboardsToTeamCountMapping;
};

export const webDashboardsToTeamMapping = (
  teamDashboards = [],
  teamList = []
) => {
  let dashboardsToTeamCountMapping = {};
  for (let team in teamDashboards) {
    if (checkIfTeamExist(team, teamList)) {
      teamDashboards[team]?.forEach((homepageId) => {
        if (dashboardsToTeamCountMapping[homepageId]) {
          dashboardsToTeamCountMapping[homepageId].push(team);
        } else {
          dashboardsToTeamCountMapping[homepageId] = [team];
        }
      });
    }
  }
  return dashboardsToTeamCountMapping;
};
export const groupByTeamId = (data=[]) => {
  if(!data.length)return {}
  return data.reduce((acc, item) => {
    const { launcherId, teamId } = item;
    if (!acc[launcherId]) {
      acc[launcherId] = [];
    }
    acc[launcherId].push(teamId);
    return acc;
  }, {});
};

export const teamsToMenuMapping = (menuTeams = [], teamList = []) => {
  let teamsToMenuCountMapping = {};
  menuTeams.forEach((team) => {
    if (checkIfTeamExist(team?.teamId, teamList)) {
      if (teamsToMenuCountMapping[team.menuId]) {
        teamsToMenuCountMapping[team.menuId].push(team.teamId);
      } else {
        teamsToMenuCountMapping[team.menuId] = [team.teamId];
      }
    }
  });
  return teamsToMenuCountMapping;
};


export const teamsToLeadControlMapping = (leadControlTeams = [], teamList = []) => {
  let teamsToLeadControlCountMapping = {};
  leadControlTeams.forEach((team) => {
    if (checkIfTeamExist(team?.teamId, teamList)) {
      if (teamsToLeadControlCountMapping[team.leadControlId]) {
        teamsToLeadControlCountMapping[team.leadControlId].push(team.teamId);
      } else {
        teamsToLeadControlCountMapping[team.leadControlId] = [team.teamId];
      }
    }
  });
  return teamsToLeadControlCountMapping;
};

export const menubyTeamIdMapping = (teamMenu) => {
  return Object.fromEntries(
    teamMenu?.map((menu) => {
      return [menu.teamId, menu];
    })
  );
};

export const deletedTeamsFromPlatform = (
  teamsList = [],
  teamsDashboards = [],
  teamMenu = []
) => {
  let deletedTeamIds = [];
  teamsDashboards.forEach((team) => {
    if (!checkIfTeamExist(team?.teamId, teamsList)) {
      deletedTeamIds.push(team?.teamId);
    }
  });
  teamMenu.forEach((team) => {
    if (!checkIfTeamExist(team?.teamId, teamsList)) {
      deletedTeamIds.push(team?.teamId);
    }
  });
  return [...new Set(deletedTeamIds)];
};

export const hierarchyToLinear = (hierarchyList = []) => {
  if (hierarchyList.length === 0) return [];
  let stack = [];
  let output = [];
  stack.push(hierarchyList[0]);
  while (stack.length > 0) {
    const node = stack[stack.length - 1];
    stack.pop();
    output.push(node);
    if (node.children === undefined) continue;
    for (let i = node.children.length - 1; i >= 0; i--) {
      stack.push(node.children[i]);
    }
  }
  return output;
};

export const formatWidgetConfig = (widgetConfiguration) => {
  const widgetConfig = {
    ...widgetConfiguration,
  };
  widgetConfig["publishedConfig"] = widgetConfig["config"];
  if (widgetConfig.isDraft && widgetConfig.draftConfig !== null) {
    widgetConfig["config"] = widgetConfig["draftConfig"];
  } else {
    widgetConfig["draftConfig"] = widgetConfig["config"];
  }
  return widgetConfig;
};

const {
  REACT_APP_SECTION_COUNT,
  REACT_APP_WIDGET_COUNT_ACROSS_SECTION,
  REACT_APP_WIDGET_COUNT_ACROSS_PREVIEW_PANE,
  REACT_APP_BANNER_MAX_FILE_SIZE,
  REACT_APP_HOMEPAGES_COUNT,
  REACT_APP_IMAGE_GALLERY_MAX_FILE_SIZE,
  REACT_APP_SMART_VIEW_ALLOWED_WIDGET_COUNT,
  REACT_APP_WEB_BANNER_MAX_FILE_SIZE,
  REACT_APP_COVER_PAGE_HEADING_MAX_CHAR_ALLOWED,
  REACT_APP_WEB_HOMEPAGES_COUNT,
  REACT_APP_WEB_WIDGETS_ALLOWED,
  REACT_APP_CICO_ALLOWED_WIDGET_COUNT
} = process.env;

export const countLimit = {
  SECTION_COUNT: parseInt(REACT_APP_SECTION_COUNT) || 5,
  WIDGET_COUNT_ACROSS_SECTION:
    parseInt(REACT_APP_WIDGET_COUNT_ACROSS_SECTION) || 5,
  WIDGET_COUNT_ACROSS_PREVIEW_PANE:
    parseInt(REACT_APP_WIDGET_COUNT_ACROSS_PREVIEW_PANE) || 10,
  BANNER_MAX_FILE_SIZE: parseInt(REACT_APP_BANNER_MAX_FILE_SIZE) || 5000000,
  HOMEPAGES_COUNT: parseInt(REACT_APP_HOMEPAGES_COUNT) || 150,
  WEB_HOMEPAGES_COUNT: parseInt(REACT_APP_WEB_HOMEPAGES_COUNT) || 25,
  IMAGE_GALLERY_MAX_FILE_SIZE:
    parseInt(REACT_APP_IMAGE_GALLERY_MAX_FILE_SIZE) || 2000000,
  SMART_VIEW_ALLOWED_WIDGET_COUNT: parseInt(REACT_APP_SMART_VIEW_ALLOWED_WIDGET_COUNT) || 1,
  CICO_ALLOWED_WIDGET_COUNT: parseInt(REACT_APP_CICO_ALLOWED_WIDGET_COUNT) || 1,
  WEB_BANNER_MAX_FILE_SIZE: parseInt(REACT_APP_WEB_BANNER_MAX_FILE_SIZE) || 1000000,
  COVER_PAGE_HEADING_MAX_CHAR_ALLOWED: parseInt(REACT_APP_COVER_PAGE_HEADING_MAX_CHAR_ALLOWED) || 50,
  WEB_WIDGETS_ALLOWED: parseInt(REACT_APP_WEB_WIDGETS_ALLOWED) || 10,
};
export const validateWidgetSubmission = (mode, previewPane, dispatch) => {
  if (
    mode === globalConstants.CREATE &&
    findNumberOfWidgetsInASection(previewPane.selectedSection, previewPane) > 0
  ) {
    dispatch(
      changeToastMessage({
        type: "warning",
        title: "Warning",
        message: "To add widgets, please select another section. ",
      })
    );
    return false;
  }

  return true;
};

const isAppLoadedWithinAnIframe = () => {
  return window.location !== window.parent.location;
};
export const removeLoadingIconFromParent = () => {
  if (isAppLoadedWithinAnIframe()) {
    window.parent.postMessage(
      {
        Type: "SPA",
        Action: "hideLoaderImg",
      },
      "*"
    );
  }
};

export const redirectToLogin = () => {
  if (isAppLoadedWithinAnIframe()) {
    window.parent.postMessage(
      {
        Type: "SPA",
        Action: "redirect",
        RedirectURL: "/Home/SignOut",
      },
      "*"
    );
  }
};

export const getSmartViewPrimaryColorFilter = (hexCode) => {
  const hexCodeToFilterMap = {
    "#B7D52A":
      "invert(82%) sepia(82%) saturate(450%) hue-rotate(11deg) brightness(86%) contrast(92%)",
    "#1F5AE9":
      "invert(32%) sepia(96%) saturate(4983%) hue-rotate(221deg) brightness(94%) contrast(93%)",
    "#19C8DF":
      "invert(68%) sepia(63%) saturate(892%) hue-rotate(140deg) brightness(92%) contrast(89%)",
    "#1DD491":
      "invert(60%) sepia(74%) saturate(447%) hue-rotate(106deg) brightness(96%) contrast(93%)",
    "#39C02E":
      "invert(66%) sepia(53%) saturate(2654%) hue-rotate(69deg) brightness(95%) contrast(80%)",
    "#80CA20":
      "invert(67%) sepia(58%) saturate(592%) hue-rotate(41deg) brightness(94%) contrast(92%)",
    "#D5C92A":
      "invert(87%) sepia(58%) saturate(664%) hue-rotate(349deg) brightness(89%) contrast(86%)",
    "#FF8000":
      "invert(50%) sepia(74%) saturate(2252%) hue-rotate(1deg) brightness(104%) contrast(105%)",
    "#F44C06":
      "invert(34%) sepia(64%) saturate(3929%) hue-rotate(2deg) brightness(99%) contrast(96%)",
    "#EE11A3":
      "invert(16%) sepia(74%) saturate(5228%) hue-rotate(309deg) brightness(98%) contrast(97%)",
    "#BC51EE":
      "invert(68%) sepia(86%) saturate(4982%) hue-rotate(244deg) brightness(88%) contrast(114%)",
    "#9D87DE":
      "invert(54%) sepia(19%) saturate(948%) hue-rotate(214deg) brightness(102%) contrast(95%)",
    "#482BC8":
      "invert(14%) sepia(97%) saturate(3914%) hue-rotate(252deg) brightness(84%) contrast(95%)",
    "#319AFC":
      "invert(48%) sepia(67%) saturate(616%) hue-rotate(169deg) brightness(101%) contrast(98%)",
    "#FABE06":
      "invert(86%) sepia(23%) saturate(3191%) hue-rotate(347deg) brightness(94%) contrast(108%)",
  };
  return hexCodeToFilterMap[hexCode];
};
export const capitalizeFirstLetter = (string) => {
  if (!string) return;
  else return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getImageAspectRatio = (imageLink) => {
  const image = new Image();
  image.src = imageLink;
  return image.width / image.height;
};

export const formatDateTime = (UTCdate) => {
  return moment.utc(UTCdate).local();
};

export const getUpdatedTeam = (teams, outdatedTeams) => {
  return teams.filter((team) => {
    return outdatedTeams.some((obj) => obj.teamId === team.teamId);
  });
};

export const homepagesbyTeamIdMapping = (teamsDashboards) => {
  return Object.fromEntries(
    teamsDashboards?.map((team) => {
      return [team.teamId, team];
    })
  );
};

export const getImageCropperAspect = (aspectRatio) => {
  switch (aspectRatio) {
    case globalConstants.SQUARE:
      return 1;
    case globalConstants.WIDE:
      return 3;
    case globalConstants.VERTICAL:
      return 0.6;
    default:
      return 1;
  }
};

export const getRecentlyModifiedDate = (date1, date2) => {
  if (!date1 && !date2) return;
  if (!date1) return date2;
  if (!date2) return date1;
  return new Date(date1) > new Date(date2) ? date1 : date2;
};

export const getAllTeamsViewData = (
  linearTeams,
  homepagesbyTeamId,
  menuByTeamId,
  defaultTeamDashboards,
  defaultMenu
) => {
  const defaultTeam = {
    teamLabel: "Default",
    teamId: defaultTeamDashboards?.teamId || defaultMenu?.teamId,
    homepages: defaultTeamDashboards?.homepages || [],
    menu: defaultMenu?.menuId,
  };
  const teamsData = linearTeams.map((team) => ({
    teamLabel: team.label,
    teamId: team.teamId,
    homepages: homepagesbyTeamId[team.teamId]?.homepages || [],
    menu: menuByTeamId[team.teamId]?.menuId,
    updatedAt: getRecentlyModifiedDate(
      homepagesbyTeamId[team.teamId]?.updatedAt,
      menuByTeamId[team.teamId]?.updatedAt
    ),
  }));

  return { defaultTeam, teamsData };
};

export const getStatusColor = (status) => {
  switch (status) {
    case casaConstants.PUBLISHED:
      return "green-status";
    case casaConstants.UNPUBLISHED:
      return "red-status";
    case casaConstants.UNPUBLISHED_CHANGES:
      return "orange-status";
    default:
      return "transaparent-status";
  }
};

export const getCurrentTime = () => {
  return new Date().toLocaleTimeString("en-US", {
    hour12: false,
    hour: "numeric",
    minute: "numeric",
  });
};

export const getExternalLinkOpeningOptions = (option) => {
  const allOptions = [
    {
      id: "native_app",
      name: "Native App",
    },
    {
      id: "embedded_browser",
      name: "Embedded Browser",
    },
    {
      id: "external_browser",
      name: "External Browser",
    }
  ];

  if (option === "CASA_WEB") {
    return allOptions.filter(option => option.id === "external_browser");
  }

  return allOptions;
};
