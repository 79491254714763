import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as LinkIcon } from "../../../assets/svgIcons/link.svg";
import { useForm } from "react-hook-form";
import CustomInput from "../../../components/CustomInput";
import {
  globalConstants,
  leadControlsWidgetConstants,
} from "../../../constants/globalConstant";
import {
  fetchImageURL,
  formatImageFile,
  leadWidgetTileStyles,
  uploadImageInToBucket,
} from "../../../utils/leadControlsUtils";
import {
  addLeadWidget,
  resetLeadWidgetConfiguration,
  updateLeadWidget,
} from "../../../reducers/leadControlsSlice";
import { API_DELETE_IMAGES } from "../../../api/configurationScreenServices";
import WidgetConfigurationLayout from "./WidgetConfigurationLayout";

const CustomWidgetSettings = () => {
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const { selectedLeadControl, selectedLeadWidget } = useSelector(
    (state) => state.leadControls
  );
  const { metaData } = useSelector(
    (state) => state.leadControls.selectedWidgetConfiguration
  );
  const { widgetStyle: widgetStyles } = metaData;
  const tileStyles = leadWidgetTileStyles;

  const defaultValues = {
    identifier: "Custom Widget",
    widgetStyle: "Tile",
    tileStyle: "Icon",
    url: "",
    ...selectedLeadWidget.defaultValues
  };

  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
    reset,
  } = useForm({ defaultValues: defaultValues });
  const widgetStyle = watch("widgetStyle");
  const tileStyle = watch("tileStyle");

  useEffect(() => {
    !tileStyle && setValue("tileStyle", defaultValues.tileStyle);
    if (
      tileStyle === leadControlsWidgetConstants.IMAGE &&
      !(image && image?.uploadStatus === leadControlsWidgetConstants.SUCCESS)
    )
      setIsSaveDisabled(true);
    else {
      setIsSaveDisabled(false);
    }
    tileStyle === leadControlsWidgetConstants.ICON && setImage(null);
  }, [tileStyle, image]);

  useEffect(() => {
    if (selectedLeadWidget.mode === globalConstants.UPDATE) {
      const widgetDetails = selectedLeadWidget.widgetDetails.config;
      setValue("identifier", widgetDetails.identifier);
      setValue("widgetStyle", widgetDetails.widgetStyle);
      setValue("tileStyle", widgetDetails.tileStyle);
      setValue("url", widgetDetails.url);
      setValue("icon", widgetDetails.icon);
      if (widgetDetails.tileStyle === leadControlsWidgetConstants.IMAGE) {
        fetchImageURL(widgetDetails.image).then((payload) => {
          setImage(payload);
        });
      }
    }
    return () => {
      reset();
    };
  }, [selectedLeadWidget]);

  const onSubmit = (data) => {
    const widgetType =
    selectedLeadWidget.mode === globalConstants.CREATE
      ? selectedLeadWidget.widgetDetails.id
      : selectedLeadWidget.widgetDetails?.config.widgetType;
    const config = {
      identifier: data.identifier.trim(),
      widgetStyle: data.widgetStyle,
      url: data.url.trim(),
      widgetType : widgetType,
    };
    data.widgetStyle === leadControlsWidgetConstants.TILE &&
      (config["tileStyle"] = data.tileStyle);
    data.tileStyle === leadControlsWidgetConstants.IMAGE &&
      (config["image"] = {
        imageKey: image.imageKey,
      });
    data.tileStyle === leadControlsWidgetConstants.ICON &&
      (config["icon"] = data.icon);
    const pageId = selectedLeadControl?.leadPage.id;
    if (selectedLeadWidget.mode === globalConstants.CREATE)
      dispatch(
        addLeadWidget({
          pageId: pageId,
          postbody: { config: config },
        })
      );
    else
      dispatch(
        updateLeadWidget({
          pageId: pageId,
          widgetId: selectedLeadWidget.widgetDetails.id,
          postbody: { config: config },
        })
      );
    dispatch(resetLeadWidgetConfiguration());
  };

  const imageUploadHandler = (event) => {
    const imgFile = formatImageFile(event);
    setImage({
      ...imgFile,
      uploadStatus: leadControlsWidgetConstants.PENDING,
    });
    uploadImageInToBucket(imgFile).then((response) => {
      setImage(response);
    });
  };

  const onElementDeleted = () => {
    if (selectedLeadWidget.mode === globalConstants.CREATE) {
      let key = "";
      key += String(image.imageKey);
      API_DELETE_IMAGES(key);
    }
    setImage(null);
  };

  return (
    <WidgetConfigurationLayout
      control={control}
      errors={errors}
      widgetStyles={widgetStyles}
      widgetStyle={widgetStyle}
      image={image}
      tileStyle={tileStyle}
      tileStyles={tileStyles}
      imageUploadHandler={imageUploadHandler}
      onSaveClicked={handleSubmit(onSubmit)}
      isSaveDisabled={isSaveDisabled}
      onImageDeletion={onElementDeleted}
      hasAdditionalSection={true}
      additionalSection={
        <div className={`flex flex-column gap-2`}>
          <CustomInput
            RightIcon={LinkIcon}
            fieldName="url"
            errors={errors}
            control={control}
            isMandatory
            label="Add URL"
            data-testid="custom-widget-url"
            pattern={{
              value: /^((https):\/\/).+$/,
              message: "Please enter a valid URL with https protocol",
            }}
          />
        </div>
      }
    />
  );
};

export default CustomWidgetSettings;
