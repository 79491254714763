import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Text from "../../components/Text";
import {
  errorMessageConstants,
  globalConstants,
  leadControlsConstants,
  shimmerConstants,
} from "../../constants/globalConstant";
import styles from "./styles.module.css";
import CustomButton from "../../components/CustomButton";
import { RadioButton } from "primereact/radiobutton";
import { Shimmer } from "../../components/Shimmer";
import {
  addLeadControls,
  changeSelectedLeadType,
  getLeadTypes,
  updateLeadControls,
} from "../../reducers/leadControlsSlice";
import { ReactComponent as Error } from "../../assets/svgIcons/lsq_error.svg";
import { FormErrorMessage } from "../../components/FormErrorMessage";

export default function LeadTypeSelector({
  state,
  setShowLeadType,
  leadControlName,
  setShowLeadTypeError,
  showLeadTypeError,
}) {
  const dispatch = useDispatch();
  const {
    isLeadTypeLoading,
    leadTypes,
    selectedLeadControl,
    selectedLeadType,
  } = useSelector((state) => state.leadControls);
  const defaultValues = {
    selectedLeadType: leadTypes[0],
  };
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: defaultValues,
  });
  const handlePostResponse = ({ type }, selectedLeadType) => {
    if (type.includes(globalConstants.FULFILLED)) {
      dispatch(changeSelectedLeadType(selectedLeadType));
      setShowLeadType(false);
    }
  };

  const onLeadTypeSubmit = (data) => {
    const { selectedLeadType } = data;
    const postBody = {
      leadTypeId: selectedLeadType.LeadTypeId,
      name: leadControlName,
    };

    if (selectedLeadControl) {
      dispatch(
        updateLeadControls({
          leadControlId: selectedLeadControl.id,
          postBody,
        })
      ).then((response) => handlePostResponse(response, selectedLeadType));
      return;
    }

    dispatch(
      addLeadControls({
        postBody,
      })
    ).then((response) => handlePostResponse(response, selectedLeadType));
  };

  const onClick = () => {
    dispatch(getLeadTypes()).then(({ type }) => {
      if (!type.includes(globalConstants.REJECTED)) setShowLeadTypeError(false);
    });
  };
  useEffect(() => {
    state && setValue("selectedLeadType", selectedLeadType);
  }, [state]);

  return (
    state && (
      <div
        className={`flex h-full w-full justify-content-center align-items-center absolute z-5 ${styles["lead-type"]}`}
      >
        {showLeadTypeError ? (
          <div
            className={`flex flex-column p-3 gap-3 justify-content-center align-items-center ${styles["lead-type-error"]}`}
          >
            <Error />
            <div className="flex flex-column gap-1 justify-content-center align-items-center">
              <Text type="H2B">
                {errorMessageConstants.SOMETHING_WENT_WRONG}
              </Text>
              <Text type="T3">
                {errorMessageConstants.ERROR_WHILE_LOADING_LEAD_TYPES}
              </Text>
            </div>
            <CustomButton
              varient="filled"
              label={leadControlsConstants.RETRY}
              onClick={onClick}
              style={{ width: "100%" }}
            />
          </div>
        ) : (
          <div
            className={`flex flex-column gap-2 ${styles["lead-type-container"]}`}
          >
            <div className="flex flex-column gap-2 overflow-hidden">
              <div className={`flex justify-content-center w-full `}>
                <Text type="H2B" color="var(--text-primary)">
                  {leadControlsConstants.PLEASE_SELECT_LEAD_TYPE}
                </Text>
              </div>
              {isLeadTypeLoading ? (
                <Shimmer label={shimmerConstants.LEAD_TYPES} />
              ) : (
                <div
                  className={`flex flex-column gap-2 p-2 h-full overflow-scroll`}
                >
                  {leadTypes.map((type) => {
                    return (
                      <Controller
                        key={type.LeadTypeId}
                        name={"selectedLeadType"}
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field }) => (
                          <div
                            key={type.LeadTypeId}
                            className="flex align-items-center cursor-pointer"
                            onClick={() => field.onChange(type)}
                          >
                            <RadioButton
                              className="lsq-radio"
                              name="selectedLeadType"
                              value={field.value}
                              {...field}
                              checked={
                                field.value?.LeadTypeId === type.LeadTypeId
                              }
                              onChange={() => {
                                field.onChange(field.value);
                              }}
                            />
                            <Text
                              type="T2B"
                              className="ml-2 ellipsis"
                              color="var(--text-secondary)"
                            >
                              {type.Name}
                            </Text>
                          </div>
                        )}
                      />
                    );
                  })}
                </div>
              )}
            </div>
            {errors["selectedLeadType"] && (
              <FormErrorMessage
                message={errorMessageConstants.PLEASE_SELECT_OPTION}
              />
            )}
            <CustomButton
              varient="filled"
              label={leadControlsConstants.START}
              onClick={handleSubmit(onLeadTypeSubmit)}
            />
          </div>
        )}
      </div>
    )
  );
}
