import React, { useRef } from "react";
import FieldComponentsParser from "../FieldComponentsParser";

const renderRowComponent = ({ field, form }) => {
  const iconPickerRef = useRef(null);
  return (
    <div className="flex w-full gap-1" ref={iconPickerRef}>
      {field.components?.map((component) => {
        return (
          <FieldComponentsParser
            field={component}
            form={form}
            iconPickerRef={iconPickerRef}
          />
        );
      })}
    </div>
  );
};
export default renderRowComponent;
