import React, { useState } from "react";
import Text from "../../../../components/Text";
import styles from "../styles.module.css";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as NoWidgetsAdded } from "../../../../assets/illustrations/add_widgets.svg";
import {
  deleteWebWidget,
  apiDeleteWebWidget,
  resetSelectedWidget,
  setVisiblePanel,
  setDeleteDialog,
  apiUpdateWebHomepage,
  apiGetWebDashboards,
  apiGetCloneWebDashboards,
  changeSelectedWidgetLanguage,
} from "../../../../reducers/webHomepageSlice";
import { NoResultsFound } from "../../../../components/NoResultsFound";
import { casaWebConstants } from "../../../../constants/globalConstant";
import WidgetPreviewLayout from "../WidgetCentralPanel/WidgetPreviewLayout";
import ConfirmDialog from "../../../../components/Modal/ConfirmDialog";
import { ReactComponent as DeleteIllustration } from "../../../../assets/illustrations/error.svg";
import CoverPagePreview from "./CoverPagePreview/CoverPagePreview";
export default function WebHomepagePreview({
  webPreviewMode,
  addCoverPage,
  unSavedChanges,
}) {
  const dispatch = useDispatch();
  const { layout, coverPageData, dialog, renderLayoutConfig } =
    useSelector((state) => state.webHomepage);
  const { cloneDashboardId, id: publishedDashboardId } = useSelector(
    (state) => state.webHomepage.selectedWebHomepage
  );
  const [showGridContainer, setShowGridContainer] = useState(false);

  const dashboardId =
    renderLayoutConfig === "clone" ? cloneDashboardId : publishedDashboardId;

  const deleteCoverPage = async () => {
    const coverPage = {
      heading: "",
      description: "",
      customRadio: "",
      selectedColor: "",
      image: null,
    };
    const postBody = {
      layout: {
        widgets: layout,
        coverPage,
      },
    };
    await dispatch(apiUpdateWebHomepage({ dashboardId, postBody }));

    if (cloneDashboardId) {
      await dispatch(apiGetCloneWebDashboards(cloneDashboardId));
    } else {
      await dispatch(apiGetWebDashboards());
    }
  };
  const confirmDialogHandler = async () => {
    dispatch(setDeleteDialog({ state: false, widgetId: null }));
    dispatch(setVisiblePanel(casaWebConstants.LEFT));
    dispatch(resetSelectedWidget());
    if (dialog.widgetId === "demoWidget") {
      deleteCoverPage();
    } else {
      dispatch(deleteWebWidget({ id: dialog.widgetId }));

      await dispatch(
        apiDeleteWebWidget({
          dashboardId,
          widgetId: dialog.widgetId,
        })
      );

      dispatch(changeSelectedWidgetLanguage(null));

      const updatedLayout = layout.filter(
        (widget) => widget.id !== dialog.widgetId
      );

      const postBody = {
        layout: {
          widgets: updatedLayout,
          coverPage: coverPageData,
        },
      };

      await dispatch(apiUpdateWebHomepage({ dashboardId, postBody }));

      if (cloneDashboardId) {
        await dispatch(apiGetCloneWebDashboards(cloneDashboardId));
      } else {
        await dispatch(apiGetWebDashboards());
      }
    }
  };
  const dialogClickListener = () => {
    confirmDialogHandler();
  };
  return (
    <div
      className={`flex h-full overflow-y-scroll overflow-x-visible`}
      style={{ background: "var(--N10)" }}
    >
      <div
        className={`flex w-full h-full relative ${
          webPreviewMode ? "webPreviewMode" : ""
        }`}
      >
        {showGridContainer && !webPreviewMode && (
          <div className={styles["grid-container"]}></div>
        )}
        <div className="w-full" id="web-homepage-preview">
         <CoverPagePreview addCoverPage={addCoverPage} webPreviewMode={webPreviewMode} />
          <div
            className="relative"
            style={{
              width: webPreviewMode ? "80%" : "100%",
              margin: webPreviewMode ? "-30px auto" : "0",
              zIndex: webPreviewMode ? "4" : "0",
              display: "flex",
              flexDirection: "column",

              minHeight: "100%",
            }}
          >
            {layout?.length === 0 ? (
              <div className="mt-6">
                <NoResultsFound
                  Illustration={NoWidgetsAdded}
                  children={
                    <div>
                      <Text type="T2B" color="var(--N80)">
                        {casaWebConstants.NO_WIDGETS_ADDED}
                      </Text>
                    </div>
                  }
                />
              </div>
            ) : (
              <WidgetPreviewLayout
                webPreviewMode={webPreviewMode}
                setShowGridContainer={setShowGridContainer}
                unSavedChanges={unSavedChanges}
                confirmDialogHandler={dialogClickListener}
              />
            )}
          </div>
        </div>
        {dialog.state && (
          <div className="modal-wrapper " onClick={(e) => e.stopPropagation()}>
            <div className="modal-content">
              <ConfirmDialog
                setState={(newState) => dispatch(setDeleteDialog(newState))}
                illustration={<DeleteIllustration />}
                buttonLabel="Delete"
                displayText={
                  <>
                    <Text type="heading" className="capitalize" color="#0A1F43">
                      Delete Widget
                    </Text>
                    <Text type="sub-heading" color="#0A1F43">
                      Are you sure you want to delete this widget? It will be
                      removed from all the teams that are currently assigned to
                      this homepage!
                    </Text>
                  </>
                }
                clickListener={dialogClickListener}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
