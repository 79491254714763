import React from "react";
import Recommendations from "../../../../../components/Recommendations";
import FileInput from "../../../CasaWebWidgets/FileUploadWidget/FileInput";
import { imageRecommendations } from "../../../../../constants/globalConstant";

const renderFileInput = ({ field, form, coverPageData }) => {
  form.register(field.fieldName, { required: field.isMandatory });
  return (
    <div className="flex flex-column gap-1">
      <FileInput field={field} form={form} coverPageData={coverPageData} />
      <Recommendations
        heading={
          coverPageData
            ? imageRecommendations.coverPage.header
            : imageRecommendations.banner.header
        }
        points={
          coverPageData
            ? imageRecommendations.coverPage.points
            : imageRecommendations.banner.points
        }
      />
    </div>
  );
};
export default renderFileInput;
