import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Text from "../../../../components/Text";
import TaskTypeMultiselectDropdown from "../../../../components/TaskTypeMultiselectDropdown";
import { CustomDropdown } from "../../../../components/CustomDropdown/CustomDropdown";
import { casaWebWidgetsConstants } from "../../../../constants/globalConstant";
import {
  formatTaskMetaValueToTaskFieldValue,
} from "../../../../components/TaskTypeMultiselectDropdown/TaskTypeMultiselectDropdownUtils";
import {
  listOptionTemplate,
  valueTemplate,
} from "../../../../utils/dropdownUtils";
import { formatTaskTypes } from "../../../../utils/widgetUtils";
import { Skeleton } from "primereact/skeleton";
import { webWidgetTypes } from "../../../../constants/globalEnums";
import ObjectType from "../../../../DashboardSettingsScreen/Helpers/ObjectType";
import useWidgetEssentials from "../../../../hooks/useWidgetEssentials";

const getTaskStatusOptions = (widgetType, metaData) => {
  if (
    widgetType === webWidgetTypes.SPEEDO_METER ||
    widgetType === webWidgetTypes.PROGRESS_BAR
  )
    return metaData?.tasks?.taskStatus.filter((e) => e.id === "completed");
  else if (widgetType === webWidgetTypes.SINGLE_COUNTER)
    return metaData?.tasks?.taskStatus.filter(
      (e) => e.id !== "pending_and_overdue"
    );
  return metaData?.tasks?.taskStatus;
};
const showTaskStatus=(widgetType) => {
  return (
    widgetType !== webWidgetTypes.SPEEDO_METER &&
    widgetType !== webWidgetTypes.PROGRESS_BAR
  )
}
export default function TaskFilter({ form, metaData, widgetType }) {
  const { selectedWidgetLanguage } = useSelector((state) => state.webHomepage);
  const taskTypes = formatTaskTypes(metaData?.tasks?.taskTypes);
  const { leadTypes, leadTypesEnabled } = useWidgetEssentials();
  const [dateRange, setDateRange] = useState([]);
  const isTaskTypesLoaded =
    taskTypes.appointments.length > 0 || taskTypes.todos.length > 0;
  const onTaskStatuChange = (option) => {
    form.resetField("dateFilters");
    setDateRange(metaData?.tasks?.dateFilters[option.value.id]);
    form.setValue("taskStatus", option.value);
  };
  useEffect(() => {
    if (
      selectedWidgetLanguage?.mode === "UPDATE" &&
      taskTypes.appointments.length > 0 &&
      selectedWidgetLanguage?.config?.taskTypes
    ) {
      const taskMetaFieldValue = formatTaskMetaValueToTaskFieldValue(
        selectedWidgetLanguage.config.taskTypes,
        taskTypes
      );
      if (leadTypesEnabled) {
        form.setValue("objectType", leadTypes.find((leadType) => leadType.IsDefault))
      }
      form.setValue("tasksToDisplay", taskMetaFieldValue);
    }
    // Set the initial value for tasksToDisplay
  }, [form, metaData]);

  useEffect(() => {
    const taskStatus = form.getValues("taskStatus");
    if (taskStatus) {
      setDateRange(metaData?.tasks?.dateFilters[taskStatus.id]);
    }
  }, [form, metaData]);
  return (
    <>
      <Text type={"T4B"} color={"var(--N400)"}>
        {casaWebWidgetsConstants.TASK_FILTERS}
      </Text>
      {/* <TaskTypeMultiselectDropdown
          isMandatory={true}
          taskTypes={taskTypes}
          errors={form.formState.errors}
          fieldName="tasksToDisplay"
          control={form.control}
        /> */}
        <ObjectType
          control={form.control}
          fieldName="objectType"
          id="objectType"
          isMandatory={true}
          isSingleSelect={true}
          showClear={true}
          errors={form.formState.errors}
          setValue={form.setValue}
          onChange={() => {}}
        />
      {isTaskTypesLoaded ? (
        <TaskTypeMultiselectDropdown
          isMandatory={true}
          taskTypes={taskTypes}
          errors={form.formState.errors}
          fieldName="tasksToDisplay"
          control={form.control}
        />
      ) : (
        <Skeleton type="rectangle" width="70%" height="2rem"></Skeleton>
      )}
      {showTaskStatus(widgetType)&&<CustomDropdown
        label={casaWebWidgetsConstants.SELECT_STATUS}
        isMandatory={true}
        errors={form.formState.errors}
        className="w-full text-base lsq-input"
        fieldName={"taskStatus"}
        control={form.control}
        options={getTaskStatusOptions(widgetType, metaData)}
        placeholder="Select"
        optionLabel="name"
        itemTemplate={listOptionTemplate}
        valueTemplate={valueTemplate}
        onChange={onTaskStatuChange}
      />}
      {isTaskTypesLoaded && widgetType !== webWidgetTypes.CALANDER && (
        <CustomDropdown
          label={casaWebWidgetsConstants.DATE_RANGE}
          isMandatory={true}
          errors={form.formState.errors}
          className="w-full text-base lsq-input"
          fieldName={"dateFilters"}
          control={form.control}
          options={dateRange}
          placeholder="Select"
          optionLabel="name"
          itemTemplate={listOptionTemplate}
          valueTemplate={valueTemplate}
        />
      )}
    </>
  );
}
