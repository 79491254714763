import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import {
  GET_DATA_FROM_API,
  POST_DATA_FROM_API,
} from "../../api/configurationScreenServices";
import {
  dynamicFormEntityMap,
  globalConstants,
  menuBuilderConstants,
  placeholderConstants,
  staticDynamicFormEntities,
  toastMessageConstants,
} from "../../constants/globalConstant";
import { LSQApplications, NavigationMenu } from "../../constants/globalEnums";
import {
  groupedHeaderTemplate,
  groupedItemTemplate,
  groupedValueTemplate,
  listOptionTemplate,
  valueTemplate,
} from "../../utils/dropdownUtils";
import { getUpdatedName } from "../../utils/widgetUtils";
import { CustomDropdown } from "../CustomDropdown/CustomDropdown";
import CustomInput from "../CustomInput";
import { TreeSelect } from "primereact/treeselect";
import "./index.css";
import Text from "../Text";
import { getExternalLinkOpeningOptions } from "../../utils/globalUtils";
import { CustomCheckbox } from "../CustomCheckbox/CustomCheckbox";
import useWidgetEssentials from "../../hooks/useWidgetEssentials";
import ObjectType from "../../DashboardSettingsScreen/Helpers/ObjectType.js"
import useToast from "../../hooks/useToast.js";
import { addToastMessage } from "../../reducers/homePageSlice.js";

const LsqMobileNavigator = ({
  formValues,
  mobileNavigationMenu,
  edit,
  setEdit,
  isMandatory,
  setIsLoading,
  mode,
  displayExternalLinkOpeningOptions = true,
  application = "MOBILE",
  loadPage,
}) => {
  const [subMenu, setSubMenu] = useState({});
  const [dynamicFormWorkAreaOption, setDynamicFormWorkAreaOption] = useState({
    root: undefined,
  });
  const toast = useToast(addToastMessage);
  const [dynamicFormOptions, setDynamicFormOptions] = useState();
  const { leadTypesEnabled } = useWidgetEssentials() || {};
  const watchNavigateTo = formValues.watch("navigateTo");
  const isSieraLink = formValues.watch("isSieraLink");

  const getSubMenu = async (navigationItem) => {
    const metaDataLink = navigationItem._links?.subMenu?.href;
    setIsLoading(true);
    const { data } = await GET_DATA_FROM_API(metaDataLink, application);
    setIsLoading(false);
    if (data === undefined) return;
    const isDynamicForm =
      formValues.getValues("navigateTo").name === NavigationMenu.DYNAMIC_FORMS;
    if (edit && !isDynamicForm) {
      const updatedInternalLink = getUpdatedName(
        Object.values(data)[0],
        formValues.getValues("internalLink")
      );
      if (updatedInternalLink === undefined) {
        formValues.unregister("internalLink");
        formValues.setValue("internalLink", {});
      } else {
        formValues.setValue("internalLink", updatedInternalLink);
      }
    }

    setSubMenu((prev) => {
      return {
        ...prev,
        [navigationItem.name]: Object.values(data)[0],
      };
    });
    return data;
  };

  const containsDependentDropdown = (navigationItem) => {
    if (navigationItem) {
      return (
        navigationItem._links?.subMenu?.href !== undefined &&
        navigationItem.name !== NavigationMenu.DYNAMIC_FORMS
      );
    }
  };

  const getDependentDropdownItems = (navigationItem) => {
    return subMenu[navigationItem];
  };

  const fetchWorkAreas = async (val) => {
    const getWorkArea = await getSubMenu(val);
    setDynamicFormWorkAreaOption({
      root: getWorkArea.workArea,
    });
  };

  const getDynamicFormsRequestBody = (objectType, entity, workAreaKey, application) => {
    const [workArea, parentKey] = String(workAreaKey).split("/");
    let code = parentKey;
    if (staticDynamicFormEntities.includes(entity.id)) {
      code = "";
    }
    const requestBody = {
      application: LSQApplications[application] || LSQApplications.MOBILE,
      workAreas: [workArea],
      code,
    }
    if (objectType && !objectType.IsDefault) {
      switch(entity.id) {
        case dynamicFormEntityMap.LEAD:
          requestBody.code = objectType.LeadTypeId;
          break;
        case dynamicFormEntityMap.TASKS:
          requestBody.code = objectType.LeadTypeId;
          break;
        case dynamicFormEntityMap.OPPORTUNITY:
          requestBody.code += "MXLTDATASEPARATOR" + objectType.LeadTypeId;
          break;
        case dynamicFormEntityMap.ACTIVITY:
          requestBody.code += "MXLTDATASEPARATOR" + objectType.LeadTypeId;
          break;
        default:
          break;
      }
    }
    return requestBody;
  }

  const fetchForms = async (key, application, objectType, entity) => {
    try {
      const { data } = await POST_DATA_FROM_API(
        "/widget/mobilemenu/dynamicform/forms",
        getDynamicFormsRequestBody(objectType, entity, key, application)
      );
      let _processes = [...data.processes];
      _processes =
        _processes[0]?.RelatedForms.map((form) => {
          let _process = {
            id: form.FormId,
            name: form.FormDisplayName,
            process: {
              ..._processes[0],
              AdditionalData: [_processes[0].AdditionalDataId],
              RelatedForms: [form],
            },
          };
          return _process;
        }) || [];
      setDynamicFormOptions(_processes);
      return _processes;
    } catch (error) {
      toast.error(toastMessageConstants.SOMETHING_WENT_WRONG);
      formValues.resetField("dynamicForm.form");
    }
  };

  const onWorkAreaNodeSelected = async (e, formValues) => {
    const values = formValues.getValues();
    const objectType = values.dynamicForm.objectType;
    const entity = values.dynamicForm.entity;
    const key = String(e.node.key);
    if (!e.node.children) {
      fetchForms(key, application, objectType, entity);
    }
  };

  const onFormChange = (e) => {
    const val = e.value;
    formValues.setValue("dynamicForm.form", val);
    const _process = dynamicFormOptions?.find((form) => form.id === val.id);
    const _internalLink = {
      ...formValues.getValues("dynamicForm"),
      form: _process,
    };
    formValues.setValue("internalLink", _internalLink);
  };

  const _valueTemplate = (option, props) => {
    if (option?.length > 0) {
      return (
        <div className="flex">
          <Text type="T4">{option[0]?.label}</Text>
        </div>
      );
    }
    return <Text type="T4">{props?.placeholder}</Text>;
  };

  const setFormInField = (formList, form) => {
    const _form = getUpdatedName(formList, form);
    if (!_form) return;
    delete _form["process"];
    formValues.setValue("dynamicForm.form", getUpdatedName(formList, _form));
  };

  const handleEditMode = () => {
    if (mode === globalConstants.UPDATE) {
      const navigateTo = formValues.getValues("navigateTo");
      const navigetToName = navigateTo?.name;
      const internalLink = formValues.getValues("internalLink");
      if (
        subMenu[navigetToName] &&
        navigetToName !== NavigationMenu.DYNAMIC_FORMS
      ) {
        const updatedValue = getUpdatedName(
          subMenu[navigetToName],
          internalLink
        );
        if (updatedValue === undefined) {
          formValues.unregister("internalLink");
          formValues.setValue("internalLink", {});
        } else {
          formValues.setValue("internalLink", updatedValue);
        }
      }
    }
  };
  const resetInternalLink = () => {
    formValues.unregister("internalLink");
    formValues.setValue("internalLink", {});
  };

  useEffect(() => {
    if (edit) {
      handleEditMode();
      setEdit(false);
    } else resetInternalLink();
    if (watchNavigateTo?._links?.subMenu?.href !== undefined) {
      if (!subMenu[watchNavigateTo?.name]) getSubMenu(watchNavigateTo);
    }

    if (watchNavigateTo?.name === NavigationMenu.DYNAMIC_FORMS) {
      const internalLink = formValues.getValues("internalLink");
      if (Object.keys(internalLink).length === 0) return;
      const { objectType, entity, workArea, form } = internalLink;
      (async () => {
        await fetchWorkAreas(entity);
        const formList = await fetchForms(Object.keys(workArea)[0], application, objectType, entity);
        setFormInField(formList, form);
        formValues.setValue("dynamicForm.objectType", objectType);
        formValues.setValue("dynamicForm.entity", entity);
        formValues.setValue("dynamicForm.workArea", workArea);
      })();
    } else {
      formValues.resetField("dynamicForm");
      setDynamicFormOptions();
      setDynamicFormWorkAreaOption({ root: undefined });
    }
  }, [watchNavigateTo]);

  const getDynamicFormsForm = () => {
    return (
      <div className="flex flex-column gap-2">
        <ObjectType
          control={formValues.control}
          fieldName="dynamicForm.objectType"
          id="objectType"
          isMandatory={true}
          isSingleSelect={true}
          showClear={true}
          errors={formValues.formState.errors}
          setValue={formValues.setValue}
          onChange={() => {
            formValues.resetField("dynamicForm.entity");
            formValues.resetField("dynamicForm.workArea");
            formValues.resetField("dynamicForm.form");
          }}
        />
        <CustomDropdown
          control={formValues.control}
          fieldName="dynamicForm.entity"
          options={getDependentDropdownItems(watchNavigateTo.name)}
          id="entity"
          optionLabel="name"
          className="w-full text-base lsq-input"
          filter={true}
          filterBy="name"
          resetFilterOnHide={true}
          placeholder={placeholderConstants.SELECT_ENTITY}
          valueTemplate={listOptionTemplate}
          itemTemplate={listOptionTemplate}
          label="Entity"
          isMandatory={true}
          errors={formValues.formState.errors}
          onChange={(e) => {
            const val = e.value;
            formValues.setValue("dynamicForm.entity", val);
            formValues.resetField("dynamicForm.workArea");
            formValues.resetField("dynamicForm.form");
            fetchWorkAreas(val);
          }}
        />
        <Controller
          name="dynamicForm.workArea"
          control={formValues.control}
          rules={{
            required: true,
            validate: (value) => {
              if (Object.keys(value).length === 0) {
                return "Please select a workArea";
              }
            },
          }}
          render={({ field, fieldState: { error } }) => {
            return (
              <div className="flex w-full flex-column gap-2">
                <span className={`p-float-label`}>
                  <TreeSelect
                    valueTemplate={_valueTemplate}
                    value={field.value}
                    className="w-full work-area"
                    style={error && { borderColor: "#E02020" }}
                    placeholder={placeholderConstants.SELECT_WORKAREA}
                    selectionMode="checkbox"
                    options={dynamicFormWorkAreaOption.root}
                    panelClassName="entityTreePanel"
                    filter={true}
                    filterPlaceholder={placeholderConstants.SELECT_WORKAREA}
                    onNodeSelect={(e) => {
                      field.onChange({
                        [e.node.key]: { checked: true, partialChecked: false },
                      });
                      formValues.resetField("dynamicForm.form");
                      onWorkAreaNodeSelected(e, formValues);
                      return e;
                    }}
                    onChange={(e) => e.preventDefault()}
                  ></TreeSelect>
                  <label
                    className="lsq-label flex align-items-center"
                    htmlFor={field.name}
                  >
                    <Text color={error ? "#E02020" : "#768196"} type="T5">
                      Work Areas
                    </Text>
                    <span style={{ color: "#E02020", fontSize: "0.8rem" }}>
                      {" "}
                      *
                    </span>
                  </label>
                </span>
              </div>
            );
          }}
        />
        <CustomDropdown
          control={formValues.control}
          fieldName="dynamicForm.form"
          options={dynamicFormOptions?.map((dynamicFormOption) => {
            return {
              id: dynamicFormOption.id,
              name: dynamicFormOption.name,
            };
          })}
          id="form"
          className="w-full text-base lsq-input"
          resetFilterOnHide={true}
          placeholder={placeholderConstants.SELECT_FORM}
          optionLabel="name"
          valueTemplate={listOptionTemplate}
          itemTemplate={listOptionTemplate}
          label="Forms"
          isMandatory={true}
          errors={formValues.formState.errors}
          onChange={onFormChange}
        />
      </div>
    );
  };
  const options =
    leadTypesEnabled
      ? mobileNavigationMenu
      : mobileNavigationMenu?.map((item) => {
          if (item.name !== "Internal Navigation") return item;
          else
            return {
              ...item,
              items: item?.items?.map((e) => {
                if (e?.id !== "Lead/Form") return e;
                else return { id: e?.id, name: e?.name };
              }),
            };
        });
  return (
    <div className="flex flex-column gap-2" data-testid="mobile-navigator">
      <CustomDropdown
        id="navigate-to"
        fieldName="navigateTo"
        control={formValues.control}
        className="w-full text-base lsq-input"
        ariaLabelledBy="navigate-to"
        options={options}
        optionLabel="name"
        optionGroupLabel="name"
        optionGroupChildren="items"
        placeholder="Select Option"
        optionGroupTemplate={groupedHeaderTemplate}
        itemTemplate={groupedItemTemplate}
        valueTemplate={groupedValueTemplate}
        label="Navigate To"
        isMandatory={isMandatory}
        errors={formValues.formState.errors}
        filter={true}
        resetFilterOnHide={true}
      />
      {watchNavigateTo && watchNavigateTo.name === NavigationMenu.EXTERNAL ? (
        <>
          <CustomInput
            errors={formValues.formState.errors}
            fieldName="externalLink"
            control={formValues.control}
            isMandatory
            label="Launch"
            pattern={{
              value: /^(?!http:)\w+:.+$/,
              message: "Please enter a secured URL",
            }}
          />
          {displayExternalLinkOpeningOptions && (
            <CustomDropdown
              control={formValues.control}
              isMandatory={false}
              options={getExternalLinkOpeningOptions(application)}
              id="external-link-options"
              fieldName={"externalLinkOptions"}
              optionLabel="name"
              className="w-full text-base lsq-input"
              placeholder="Select"
              onChange={(e) => {
                if (e.value === undefined) {
                  formValues.setValue("externalLinkOptions", null);
                } else {
                  formValues.setValue("externalLinkOptions", e.value);
                }
              }}
              valueTemplate={listOptionTemplate}
              itemTemplate={valueTemplate}
              label="Open with"
              errors={formValues.formState.errors}
              showClear
            />
          )}
          {loadPage === menuBuilderConstants.BOTTOM_NAVIGATION && (
            <CustomCheckbox
              control={formValues.control}
              fieldName="isSieraLink"
              onChange={(e) => formValues.setValue("isSieraLink", e.checked)}
              checked={!!isSieraLink}
              className="cursor-pointer"
              label={"SIERA Report / Dashboard?"}
              style={{
                height: "10px !important",
                width: "10px !important",
              }}
            />
          )}
        </>
      ) : (
        containsDependentDropdown(watchNavigateTo) && (
          <CustomDropdown
            control={formValues.control}
            fieldName="internalLink"
            options={getDependentDropdownItems(watchNavigateTo.name)}
            id="submenu-list"
            optionLabel="name"
            className="w-full text-base lsq-input"
            filter={true}
            filterBy="name"
            resetFilterOnHide={true}
            placeholder="Select"
            valueTemplate={listOptionTemplate}
            itemTemplate={listOptionTemplate}
            label="Launch"
            isMandatory={true}
            errors={formValues.formState.errors}
          />
        )
      )}
      {watchNavigateTo?.name === NavigationMenu.DYNAMIC_FORMS &&
        getDynamicFormsForm()}
    </div>
  );
};

export default LsqMobileNavigator;
