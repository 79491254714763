import React from "react";
import styles from "../CasaWebHomepage/styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setSidePanelExpanded,
} from "../../../reducers/homePageSlice.js";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Back } from "../../../assets/svgIcons/arrow.svg";
import CustomButton from "../../../components/CustomButton/index.js";
import { ReactComponent as TeamsActive } from "../../../assets/svgIcons/lsq_teams_active.svg";
import { ReactComponent as PublishIcon } from "../../../assets/svgIcons/publish.svg";
import { ReactComponent as SavedIcon } from "../../../assets/svgIcons/lsq_ok.svg";
import { ReactComponent as Hamburger } from "../../../assets/svgIcons/Menu.svg";
import { ReactComponent as DropdownIcon } from "../../../assets/svgIcons/lsq_chevron_down_filled.svg";
import { LsqTextEditor } from "../../../components/LsqTextEditor/index.js";
import CustomSplitButton from "../../../components/CustomSplitButton/index.js";
import { setVisiblePanel} from "../../../reducers/webHomepageSlice.js";
import { casaWebConstants,ROUTES_PATH } from "../../../constants/globalConstant.js";
import cloneDeep from "lodash.clonedeep";
import LsqProgressSpinner from "../../../components/LsqProgressSpinner/index.js";
import {
  apiGetAllTeamsWebLaunchBars,
  apiGetAllWebLaunchBars,
  apiUpdateWebLaunchBar,
  unpublishWebLaunchBar,
} from "../../../reducers/webLaunchbarSlice.js";

export default function WebHomepageTopBar({ setPublishState }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    currentLaunchBar,
    teamLaunchBars,
    publish
  } = useSelector((state) => state.webLaunchBar);
  const { orgCode } = useSelector((state) => state.homepage.user)||{};


  const defaultDashboards = teamLaunchBars[orgCode] || [];
  const cloneTeamWebDashboard = cloneDeep(teamLaunchBars);
  delete cloneTeamWebDashboard[orgCode];
  const selectedHomepageId = currentLaunchBar?.id;

  let dashboardsToTeamCountMapping = {};
  Object.keys(teamLaunchBars).forEach((e) => {
    if (orgCode !== e) {
      dashboardsToTeamCountMapping[teamLaunchBars[e]] =
        dashboardsToTeamCountMapping[teamLaunchBars[e]] || [];
      dashboardsToTeamCountMapping[teamLaunchBars[e]].push(e);
    }
  });
  const getPublishAdditionalOptions = () => {
    const options = publish.isPublished === "published"
      ? [
          {
            label: casaWebConstants.UNPUBLISH,
            command: async() => {
              dispatch(
                unpublishWebLaunchBar()
              ).then(async()=>{
                await dispatch(apiGetAllTeamsWebLaunchBars());
                await  dispatch(apiGetAllWebLaunchBars())
              })
              
            }
            ,
          },
        ]
      : [];
    return options;
  };

  const assignTeamsData = () => {
    dispatch(setVisiblePanel(casaWebConstants.RIGHT));
    setPublishState({
      state: true,
      data: {
        isDefault: defaultDashboards?.includes(selectedHomepageId),
        teamIds: dashboardsToTeamCountMapping[selectedHomepageId] || [],
        selectedHomepageId,
      },
    });
  };

  const onPublishClick = () => {
    if (publish.isPublished === "published") {
      return;
    }
    assignTeamsData();
  };

  const getPublishButtonIcon = () => {
    if (publish.isPublishLoading)
      return <LsqProgressSpinner strokeWidth={3} strokeColor="white" />;
    if (publish.isPublished === "unpublished") return <PublishIcon />;
    return <SavedIcon fill="white" />;
  };

  const getPublishButtonLabel = () => {
    if (publish.isPublishLoading || publish.isPublished !== "published")
      return casaWebConstants.PUBLISH;
    return "Published";
  };

  const homepageNameSaveHandler = (text) => {
    const postBody = {
      name: text?.trim(),
    };
    dispatch(apiUpdateWebLaunchBar(postBody));
  };
  const openExtendedSidePanel = () => {
    dispatch(setSidePanelExpanded(true));
  };

  const getTeamsButtonLabel = () => {
    return defaultDashboards?.includes(selectedHomepageId)
      ? "Default"
      : `${dashboardsToTeamCountMapping[selectedHomepageId]?.length} Teams`;
  };
  const onBackClicked = () => {
    navigate(ROUTES_PATH.launchBar);
  };
  return (
    <div
      className={`flex w-full align-items-center ${styles["casa-web-top-bar"]}`}
    >
      <div
        className={`flex align-items-center justify-content-center h-full`}
        style={{ width: "4rem" }}
      >
        <div
          className={`flex cursor-pointer p-2 ${styles["hamburger-icon"]}`}
          onClick={openExtendedSidePanel}
        >
          <Hamburger />
        </div>
      </div>
      <div className={`flex relative align-items-center w-full`}>
        <div className={`flex align-items-center gap-1`}>
          <div className={`${styles["hamburger-icon"]}`}>
            <CustomButton
              varient="text"
              icon={<Back />}
              label=""
              onClick={onBackClicked}
              data-testid="scaffold-back-btn"
            />
          </div>
          <LsqTextEditor
            displayText={currentLaunchBar?.name || casaWebConstants.HOMEPAGE}
            onSaveHandler={homepageNameSaveHandler}
            editIconStyle={{ width: "1.3rem" }}
          />
        </div>
      </div>
      <div
        className={`flex justify-content-end gap-2 w-full align-items-center ${
          styles["box-animation"]
        } ${styles["fade-in"]}`}
      >
        
        {currentLaunchBar?.config?.length > 0 && publish.isPublished === "published" && (
          <CustomButton
            varient="outline"
            label={getTeamsButtonLabel()}
            onClick={assignTeamsData}
            data-testid="lead-controls-teams"
            icon={<TeamsActive width={16} fill="var(--N400)"/>}
            badge={<i className="pi pi pi-angle-down"/>}
            badgeClassName='team-assignment-badge ml-0'
          />
        )}
        {currentLaunchBar?.config?.length > 0 && (
          <CustomSplitButton
            varient="filled"
            onClick={onPublishClick}
            icon={getPublishButtonIcon()}
            label={getPublishButtonLabel()}
            id="publish-btn"
            additionalOptions={getPublishAdditionalOptions()}
            dropdownIcon={<DropdownIcon />}
          />
        )}
      </div>
    </div>
  );
}
