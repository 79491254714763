import React, { useState, useEffect } from "react";
import Joyride from "react-joyride";
import { useSelector, useDispatch } from "react-redux";
import Text from "../components/Text";
import { useTranslation } from "react-i18next";
import CustomInput from "../components/CustomInput";
import { useForm, useFieldArray } from "react-hook-form";
import { CustomDropdown } from "../components/CustomDropdown/CustomDropdown";
import CustomButton from "../components/CustomButton";
import { apiAddWidget, apiUpdateWidget } from "../reducers/previewPaneSlice";
import { resetWidgetConfiguration } from "../reducers/widgetTemplateListSlice";
import useWidgetEssentials from "../hooks/useWidgetEssentials";
import {
  getWidgetSignature,
  getOptionsExistInTheList,
  opportunityStatus,
} from "../utils/widgetUtils";
import { valueTemplate, listOptionTemplate } from "../utils/dropdownUtils";
import analytics from "../utils/analytics";
import {
  stringConstants,
  placeholderConstants,
  widgetConstants,
  globalConstants,
} from "../constants/globalConstant";
import ControlledMultiselect from "../components/LSQMultiSelect/ControlledMultiselect";
import ItemsDragHandler from "../components/ItemsDragHandler";
import { Checkbox } from "primereact/checkbox";
import CoachTooltip from "../components/Coach/CoachTooltip";
import { myOpportunitiesWidgetSteps } from "../components/Coach/coachSteps";
import { apiUpdateUserPreferences } from "../reducers/homePageSlice";
import ObjectType from "./Helpers/ObjectType";

const getFilteredOptions = (options, leadTypesEnabled, selectedObjectType) => {
  let filteredOptions = [...options];
  if (leadTypesEnabled)
    filteredOptions = options.filter(
      (opportunityType) =>
        opportunityType.relatedLeadTypeAssociations &&
        opportunityType.relatedLeadTypeAssociations !== "" &&
        opportunityType.relatedLeadTypeAssociations
          .split(",")
          .map((InternalName) => InternalName.trim())
          .includes(selectedObjectType?.InternalName)
    );
  return filteredOptions.length > 0 ? filteredOptions : options;
};
export const MyOpportunitiesSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userPreferences } = useSelector((state) => state.homepage);
  const { myOpportunitiesWidgetVisited } = userPreferences;
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const {
    mode,
    selectedWidgetConfiguration,
    selectedWidget,
    homepageId,
    leadTypesEnabled,
  } = useWidgetEssentials();
  const { opportunityTypes = [], dateFilters } =
    selectedWidgetConfiguration.metaData;
  const defaultValues = {
    title: "My Opportunities",
    opportunityTypes: [],
    opportunityStatusOrder: opportunityStatus,
    dateFilters: { id: "Today", name: "Today" },
  };
  const {
    control,
    formState: { errors },
    reset,
    setValue,
    watch,
    handleSubmit,
  } = useForm({ defaultValues: defaultValues });
  const { fields, move, replace } = useFieldArray({
    control,
    name: "opportunityStatusOrder",
  });
  const selectedObjectType = watch("objectType");
  const filteredOptions = getFilteredOptions(
    opportunityTypes,
    leadTypesEnabled,
    selectedObjectType
  );

  useEffect(() => {
    if (mode === globalConstants.UPDATE) {
      const outdatedMetaValue = selectedWidgetConfiguration.metaValue;
      const outdatedConfig = outdatedMetaValue.config;

      const {
        title,
        dateFilters,
        selectedOpportunityTypes,
        opportunityStatusOrder,
        selectAll,
        objectType,
      } = outdatedConfig;
      const filteredOptions = getFilteredOptions(
        opportunityTypes,
        leadTypesEnabled,
        objectType
      );

      const opportunities = selectAll
        ? filteredOptions
        : getOptionsExistInTheList(selectedOpportunityTypes, filteredOptions) ||
          [];
      setSelectAllChecked(selectAll);
      setValue("title", title);
      setValue("opportunityTypes", opportunities);
      setValue("dateFilters", dateFilters);
      setValue("objectType", objectType);
      setValue(
        "opportunityStatusOrder",
        opportunityStatusOrder || opportunityStatus
      );
      opportunityStatusOrder &&
        replace(
          selectedWidgetConfiguration.metaValue.config.opportunityStatusOrder
        );
    }
  }, []);
  const onSubmit = (data) => {
    const allSelected = data.opportunityTypes.length == filteredOptions.length;
    const config = {
      title: data.title.trim(),
      selectedOpportunityTypes: allSelected
        ? [data.opportunityTypes[0]]
        : data.opportunityTypes,
      selectAll: allSelected,
      dateFilters: data.dateFilters,
      opportunityStatusOrder: data.opportunityStatusOrder,
      objectType: data.objectType,
    };

    const metaPostValue = getWidgetSignature(
      homepageId,
      selectedWidget.id,
      config
    );
    const analytics_data = {
      category: "Widget",
      type: "click",
      widgetId: selectedWidgetConfiguration?.metaValue.widgetId,
      widgetType: "MyOpportunities",
    };
    if (mode === globalConstants.CREATE) {
      dispatch(apiAddWidget(metaPostValue));
      analytics.sendEvent("Adding_MyOpportunities", analytics_data);
    } else {
      const updatedMetaValue = {
        widgetId: selectedWidgetConfiguration?.metaValue.widgetId,
        config: {
          ...metaPostValue.config,
        },
      };
      dispatch(apiUpdateWidget(updatedMetaValue));
      analytics_data["widgetId"] =
        selectedWidgetConfiguration?.metaValue.widgetId;
      analytics.sendEvent("Updating_MyOpportunities", analytics_data);
    }
    dispatch(resetWidgetConfiguration());
  };

  const selectAllHandler = (e) => {
    e.stopPropagation();
    if (e.target.checked) {
      setValue("opportunityTypes", filteredOptions);
    } else setValue("opportunityTypes", []);
    setSelectAllChecked(e.target.checked);
  };

  const selectAllPanel = () => {
    return (
      <div
        className="w-full flex align-items-center gap-1"
        style={{ padding: "0.5rem 1rem", height: "2.5rem" }}
      >
        <Checkbox
          onChange={selectAllHandler}
          checked={selectAllChecked}
          className="cursor-pointer"
          style={{
            height: "12px",
            width: "12px",
          }}
        ></Checkbox>
        <Text color="var(--N400)" type="T4B">
          {"Select All"}
        </Text>
      </div>
    );
  };
  const onCoachSkippedOrFinished = () => {
    const payload = {
      myOpportunitiesWidgetVisited: true,
    };
    dispatch(apiUpdateUserPreferences(payload));
  };
  const tooltipComponent = (props) => (
    <CoachTooltip
      {...props}
      onCoachSkippedOrFinished={onCoachSkippedOrFinished}
    />
  );

  return (
    <form
      className="flex flex-column w-full relative h-full"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Joyride
        tooltipComponent={tooltipComponent}
        continuous
        run={!myOpportunitiesWidgetVisited}
        showProgress
        showSkipButton
        steps={myOpportunitiesWidgetSteps}
        disableOverlayClose={true}
        disableCloseOnEsc={true}
        spotlightPadding={0}
      />
      <div className="flex-1 overflow-y-auto">
        <div className={`flex flex-column mt-5 lg:mx-6 md:mx-5 sm: mx-4 gap-3`}>
          <Text type="sub-heading"> {t(widgetConstants.WIDGET_TITLE)}</Text>
          <CustomInput
            control={control}
            errors={errors}
            fieldName="title"
            maxLength={30}
            className="lsq-input-bold"
            label="Display Name"
            isMandatory={true}
            data-testid="title"
          />
          <ObjectType
            control={control}
            setValue={setValue}
            errors={errors}
            isSingleSelect
            fieldName="objectType"
            onChange={() => {
              setValue("opportunityTypes", []);
              setSelectAllChecked(false);
            }}
          />
          {(!leadTypesEnabled || selectedObjectType) && (
            <ControlledMultiselect
              label={t(widgetConstants.OPPORTUNITY_TYPE)}
              isMandatory={true}
              errors={errors}
              className="w-full text-base lsq-input coach-opportunity-types"
              fieldName="opportunityTypes"
              control={control}
              options={filteredOptions}
              optionLabel="name"
              placeholder={placeholderConstants.SELECT}
              itemTemplate={listOptionTemplate}
              valueTemplate={valueTemplate}
              panelClassName="lsq-multiselect-panel lsq-select-all-panel"
              maxSelectedLabels={-1}
              id="opportunity-types"
              panelHeaderTemplate={selectAllPanel}
              onChange={(e) => {
                setValue("opportunityTypes", e.target.value);
                setSelectAllChecked(
                  e.target.value.length === filteredOptions.length
                );
              }}
            />
          )}
          <CustomDropdown
            fieldName="dateFilters"
            control={control}
            errors={errors}
            className="w-full text-base lsq-input"
            options={dateFilters}
            optionLabel="name"
            placeholder={placeholderConstants.SELECT}
            itemTemplate={listOptionTemplate}
            valueTemplate={valueTemplate}
            label={stringConstants.SELECT_DEFAULT_DATE_RANGE}
          />
          <div className={`coach-opportunity-status flex flex-column gap-3`}>
            <Text type="sub-heading">
              {t(widgetConstants.SET_STATUS_ORDER)}
            </Text>
            <ItemsDragHandler
              id={"opportunity-status"}
              elements={fields}
              move={move}
              hasCloseButton={false}
            />
          </div>
        </div>
      </div>
      <div
        className={
          "flex flex-row align-items-center justify-content-end w-11 absolute bottom-0 pb-4 gap-2"
        }
      >
        <CustomButton
          type="reset"
          label="Reset"
          varient="text"
          onClick={() => {
            reset({ defaultValues, opportunityStatusOrder: opportunityStatus });
            setSelectAllChecked(false);
          }}
        />
        <CustomButton type="submit" label="Save" varient="filled" />
      </div>
    </form>
  );
};
