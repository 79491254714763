import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import styles from "./styles.module.css";
import Text from "../../components/Text";
import CustomInput from "../../components/CustomInput";
import { CustomIconPicker } from "../../components/CustomIconPicker/CustomIconPicker";
import LsqMobileNavigator from "../../components/LsqMobileNavigator";
import { iconList } from "../../utils/iconList";
import CustomButton from "../../components/CustomButton";
import { getWidgetMetaData } from "../../reducers/widgetTemplateListSlice";
import { ReactComponent as CrossIcon } from "../../assets/svgIcons/lsq_close.svg";
import { NavigationMenu } from "../../constants/globalEnums";
import useToast from "../../hooks/useToast";
import { getWidgetIdByWidgetType } from "../../utils/widgetUtils";
import {
  iconPickerTemplate,
  selectedIconTemplate,
} from "../../utils/dropdownUtils";
import {
  globalConstantValues,
  globalConstants,
  menuBuilderConstants,
  quicklauncherConstants,
  toastMessageConstants,
} from "../../constants/globalConstant";
import {
  apiAddCustomMenuTab,
  apiUpdateCustomMenuTab,
  addToastMessage,
} from "../../reducers/menuBuilderSlice";

export default function CustomMenu({
  createNewTab,
  setCreateNewTab,
  activeTab = { key: menuBuilderConstants.BOTTOM_NAVIGATION },
}) {
  const dispatch = useDispatch();
  const toast = useToast(addToastMessage);
  const iconPickerRef = useRef(null);
  const [iconPanelWidth, setIconPanelWidth] = useState(0);
  const [edit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const { widgetList } = useSelector((state) => state.widgetTemplateList);
  const { menuTabs } = useSelector((state) => state.menuBuilder);
  const [navigationList, setNavigationList] = useState([
    {
      name: "External Link",
      items: [{ id: "ExternalLink", name: "-----" }],
    },
    {
      name: "Internal Navigation",
      items: [],
    },
  ]);

  const defaultItem = {
    displayName: "",
    icon: "",
    navigateTo: "",
    internalLink: {},
    externalLink: "",
    externalLinkOptions: "",
    dynamicForm: {
      entity: "",
      workArea: null,
      form: {},
    },
  };
  const curItem = useForm({
    defaultValues: defaultItem,
  });

  const watchAll = curItem.watch();

  const getNavigationListItems = (navigationListItems) => {
    return activeTab.key === menuBuilderConstants.BOTTOM_NAVIGATION
      ? navigationListItems.filter((item) => !item.id.includes("Form"))
      : navigationListItems;
  };

  const getDisplayExternalLinkOpeningOptions = () => {
    return activeTab.key === menuBuilderConstants.SIDE_NAVIGATION;
  }

  useEffect(() => {
    const widgetId = getWidgetIdByWidgetType("quicklauncher");
    let tempNavigationList = [...navigationList];
    if (!widgetList.widgetMetaData?.quicklauncher) {
      dispatch(getWidgetMetaData(parseInt(widgetId))).then(
        ({ type, payload }) => {
          if (type.includes(globalConstants.FULFILLED)) {
            const { _widgetMetaData } = payload;
            const navigationListItems = _widgetMetaData?.quicklauncher?.menu;
            tempNavigationList[1].items =
              getNavigationListItems(navigationListItems);
              setNavigationList([...tempNavigationList]);
          }
        }
      );
    } else {
      const navigationListItems = widgetList.widgetMetaData.quicklauncher?.menu;
      tempNavigationList[1].items = getNavigationListItems(navigationListItems);
      setNavigationList([...tempNavigationList]);
    }
  }, []);

  useEffect(() => {
    if (createNewTab.data?.edit) {
      setEdit(true);
      const updateTab = menuTabs.customTabs.find(
        (item) => item.tabId === createNewTab.data?.tabId
      );
      curItem.setValue("displayName", updateTab.config.displayName, {
        shouldDirty: true,
      });
      curItem.setValue("icon", updateTab.config.icon, { shouldDirty: true });
      curItem.setValue("isSieraLink", updateTab.config.isSieraLink, { shouldDirty: true });
      curItem.setValue("navigateTo", updateTab.config.navigateTo, {
        shouldDirty: true,
      });
      curItem.setValue("internalLink", updateTab.config.internalLink, {
        shouldDirty: true,
      });
      curItem.setValue("externalLink", updateTab.config.externalLink, {
        shouldDirty: true,
      });
      curItem.setValue("externalLinkOptions", updateTab.config.externalLinkOptions, {
        shouldDirty: true,
      });
    }
  }, []);
  const onAddCustomTab = (data) => {
    const customTabPostData = {
      config: { ...data, displayName: data.displayName.trim() },
    };
    if (createNewTab.data?.edit) {
      dispatch(
        apiUpdateCustomMenuTab({
          tabId: createNewTab.data?.tabId,
          postBody: customTabPostData,
        })
      ).then(({ type }) => {
        if (type.includes(globalConstants.FULFILLED)) {
          toast.success(
            toastMessageConstants.CUSTOM_MENU_TAB_UPDATED_SUCCESSFULLY
          );
        } else toast.error(toastMessageConstants.SOMETHING_WENT_WRONG);
      });
    } else {
      dispatch(apiAddCustomMenuTab({ postBody: customTabPostData })).then(
        ({ type }) => {
          if (type.includes(globalConstants.FULFILLED)) {
            toast.success(
              toastMessageConstants.CUSTOM_MENU_TAB_ADDED_SUCCESSFULLY
            );
          } else toast.error(toastMessageConstants.SOMETHING_WENT_WRONG);
        }
      );
    }
    setCreateNewTab({ state: false, data: null });
  };

  useEffect(() => {
    const dirtyFieldLength = Object.keys(curItem.formState.dirtyFields).length;
    const navigateTo = watchAll["navigateTo"];
    if (isLoading) setIsDisabled(true);
    else if (dirtyFieldLength < 3) {
      if (!isDisabled) setIsDisabled(true);
    } else if (
      navigateTo?._links?.subMenu?.href !== undefined &&
      navigateTo.name !== NavigationMenu.DYNAMIC_FORMS &&
      dirtyFieldLength <= 3
    ) {
      if (!isDisabled) setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [watchAll]);

  return (
    <div
      className="flex justify-content-center align-items-center fixed z-1 h-full"
      style={{
        width: "inherit",
      }}
    >
      <div
        className={`flex justify-content-end align-items-center fixed h-full ${styles["custom-menu-backdrop"]}`}
      >
        <div
          className={`flex flex-column h-full w-full align-items-center justify-content-center`}
        >
          <div
            className={`flex flex-column justify-content-center ${styles["custom-menu"]}`}
            data-testid="custom-menu"
          >
            <div
              className={`flex justify-content-between align-items-center p-2`}
            >
              <Text color="var(--text-primary)" type="heading" className="ml-2">
                {"Configure Custom Tab"}
              </Text>
              <div
                data-testid="custom-menu-cross"
                className={`flex px-1 py-1 cursor-pointer`}
                onClick={() => {
                  setCreateNewTab({ state: false, data: null });
                }}
              >
                <CrossIcon />
              </div>
            </div>
            <div className={`flex flex-column gap-2 p-3`}>
              <div className={`flex w-full gap-1`} ref={iconPickerRef}>
                <CustomIconPicker
                  id="icon-list"
                  fieldName="icon"
                  control={curItem.control}
                  className="w-full text-base lsq-input lsq-icon-picker"
                  options={iconList}
                  optionLabel="name"
                  filter
                  filterBy="name"
                  resetFilterOnHide={true}
                  filterPlaceholder={quicklauncherConstants.SEARCH_ICONS}
                  placeholder={"lsq_change_image"}
                  valueTemplate={selectedIconTemplate}
                  itemTemplate={iconPickerTemplate}
                  label={quicklauncherConstants.ICON}
                  isMandatory={true}
                  errors={curItem.formState.errors}
                  panelClassName="lsqIconPickerPanel"
                  onShow={() => {
                    setIconPanelWidth(iconPickerRef.current?.offsetWidth);
                  }}
                  panelStyle={{ width: iconPanelWidth }}
                  containerStyle={{ width: "25%" }}
                />
                <CustomInput
                  errors={curItem.formState.errors}
                  fieldName="displayName"
                  control={curItem.control}
                  isMandatory
                  label="Label"
                  maxLength={
                    globalConstantValues.CUSTOM_MENU_TAB_LABEL_CHAR_LIMIT
                  }
                  placeholder="Type Here"
                  containerStyle={{ width: "75%" }}
                  data-testid="custom-tab-title"
                />
              </div>
              <LsqMobileNavigator
                formValues={curItem}
                mobileNavigationMenu={navigationList}
                isMandatory={true}
                edit={edit}
                setEdit={setEdit}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
                mode={edit ? globalConstants.UPDATE : globalConstants.CREATE}
                displayExternalLinkOpeningOptions={getDisplayExternalLinkOpeningOptions()}
                loadPage={activeTab.key}
              />
            </div>
            <div className="flex align-items-center justify-content-end gap-2 p-3">
              <CustomButton
                type="reset"
                label="Reset"
                varient="text"
                onClick={() => {
                  curItem.reset();
                }}
              />
              <CustomButton
                type="submit"
                label="Save"
                varient="filled"
                onClick={curItem.handleSubmit(onAddCustomTab)}
                data-testid="save-btn"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
