import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../styles.module.css";
import {
  casaWebConstants,
  casaWebWidgetsConstants,
  globalConstants,
  defaultWidgetConfigurations,
} from "../../../../constants/globalConstant";
import { getTeams, setShowSidePanel } from "../../../../reducers/homePageSlice";
import WidgetLeftPanelComponent from "../WidgetLeftPanel/WidgetLeftPanelComponent";
import WidgetConfigurationRightPanel from "../WidgetRightPanel/WidgetConfigurationRightPanel";
import WebHomepagePreview from "../WidgetPreviewPanel/WebHomepagePreview";
import PublishWebHomepage from "../../TeamAssignment/PublishWebHomepage";
import {
  apiGetAllWebDashboards,
  apiGetWebDashboards,
  changeSelectedWidgetLanguage,
  apiGetAllTeamsWebDashboards,
  getWebWidgetTemplate,
  resetWebHomepage,
  setVisiblePanel,
  addWebWidget,
  addToastMessageFromWebHomepage,
  apiGetCloneWebDashboards,
  apiUpdateWebHomepage,
  updateRenderLayoutConfig,
} from "../../../../reducers/webHomepageSlice";
import { IndeterminateLoader } from "../../../../components/IndeterminateLoader";
import WebHomepageTopBar from "./WebHomepageTopBar";
import ToastMessage from "../../../../components/ToastMessage";
import ActionBar from "./ActionBar";
import "./CasaWebHomepage.css";
import { getClassName } from "../../../../utils/stringUtils";
import { countLimit } from "../../../../utils/globalUtils";

export default function CasaWebHomepage() {
  const dispatch = useDispatch();
  const teamsHierarchy = useSelector((state) => state?.homepage?.teams);
  const { renderLayoutConfig, coverPageData, cloneDashboardData } = useSelector(
    (state) => state.webHomepage
  );
  const { cloneDashboardId, id: publishedDashboardId } = useSelector(
    (state) => state.webHomepage.selectedWebHomepage
  );
  const {
    visiblePanel,
    widgets,
    layout,
    isWebHomepageLoading,
    signedUrlApi,
    toastMessages,
    publish,
  } = useSelector((state) => state?.webHomepage);

  const [publishState, setPublishState] = useState({
    state: false,
    data: null,
  });

  const [webPreviewMode, setWebPreviewMode] = useState(
    publish.isPublished === casaWebConstants.PUBLISHED &&
      cloneDashboardData?.createdAt === cloneDashboardData?.updatedAt
      ? true
      : false
  );

  const [layoutUnsavedChnages, setLayoutUnsavedChnages] = useState(false);

  const handleWebPreviewModeChange = async (value, udpateLayout = false) => {
    setWebPreviewMode(value);
    setLayoutUnsavedChnages(false);

    if (value === false && publish.isPublished === casaWebConstants.PUBLISHED) {
      dispatch(updateRenderLayoutConfig("clone"));
    }

    if (udpateLayout && value) {
      const updatedLayout = layout;
      const postBody = {
        layout: {
          widgets: updatedLayout,
          coverPage: {
            heading: coverPageData?.heading,
            description: coverPageData?.description,
            customRadio: coverPageData?.customRadio,
            image:
              coverPageData?.customRadio === casaWebConstants.IMAGE
                ? coverPageData?.image
                : null,
            selectedColor: coverPageData?.selectedColor,
          },
        },
      };

      const dashboardId =
        renderLayoutConfig === "clone" && cloneDashboardId
          ? cloneDashboardId
          : publishedDashboardId;

      await dispatch(apiUpdateWebHomepage({ dashboardId, postBody }));
      setLayoutUnsavedChnages(false);
      dispatch(changeSelectedWidgetLanguage());
    }
  };

  useEffect(() => {
    if (teamsHierarchy.length === 0) {
      dispatch(getTeams());
    }
    dispatch(setShowSidePanel(false));
    if (widgets.length === 0) dispatch(getWebWidgetTemplate());
    //these two apis we can remove from here when listing page will be implemented
    //dispatch(apiGetAllWebDashboards());
    //dispatch(apiAddWebHomepage({ postBody: defaultColorPaylod }));
    dispatch(apiGetAllTeamsWebDashboards());
    dispatch(apiGetAllWebDashboards());

    const cloneDashboardId = JSON.parse(
      localStorage.getItem("currentDashboardInfo")
    )?.cloneDashboardId;

    if (cloneDashboardId) {
      dispatch(apiGetCloneWebDashboards(cloneDashboardId)).then((response) => {
        const { createdAt, updatedAt } = response.payload;
        if (createdAt === updatedAt) {
          handleWebPreviewModeChange(true);
        }
      });
    }

    dispatch(apiGetWebDashboards()).then((response) => {
      if (
        !cloneDashboardId &&
        response?.payload?.status === casaWebConstants.PUBLISHED
      ) {
        handleWebPreviewModeChange(true);
      }

      if (cloneDashboardId) {
        dispatch(updateRenderLayoutConfig("clone"));
      }

      setLayoutUnsavedChnages(false);
    });
    return () => {
      dispatch(resetWebHomepage());
    };
  }, []);
  const addWidgetToRightPanel = (widget, isCoverPage) => {
    let mode = globalConstants.CREATE;

  if(isCoverPage!==true && layout.length >= countLimit.WEB_WIDGETS_ALLOWED) {
    dispatch(
      addToastMessageFromWebHomepage({
        type: globalConstants.SUCCESS,
        title: "",
        message: `Maximum ${countLimit.WEB_WIDGETS_ALLOWED} Widgets can be added. `,
      }));
      return;
    } else if (isCoverPage === true) {
      const isDataPresent =
        (coverPageData && Object.keys(coverPageData)?.length) ?? false;
      if (isDataPresent) mode = globalConstants.UPDATE;
    }
    dispatch(
      changeSelectedWidgetLanguage({
        id: casaWebWidgetsConstants.DEMO_WIDGET,
        widgetType: widget?.id,
        mode,
        config: {
          name: widget.title,
          icon: { name: "Activity", imgName: "lsq_copy" },
        },
      })
    );
    if (widget.id !== "Cover") {
      dispatch(
        addWebWidget({
          id: casaWebWidgetsConstants.DEMO_WIDGET,
          widgetType: widget?.id,
          config: {
            name: widget?.title,
            icon: { name: "Activity", imgName: "lsq_copy" },
          },
          height: defaultWidgetConfigurations[widget.id]?.height,
          width: defaultWidgetConfigurations[widget.id]?.width,
          minW: defaultWidgetConfigurations[widget.id]?.minW,
          maxW: defaultWidgetConfigurations[widget.id]?.maxW,
          minH: defaultWidgetConfigurations[widget.id]?.minH,
          maxH: defaultWidgetConfigurations[widget.id]?.maxH,
        })
      );
    }
    dispatch(setVisiblePanel(casaWebConstants.RIGHT));
  };
  const addCoverPage = () => {
    visiblePanel !== casaWebConstants.RIGHT &&
      addWidgetToRightPanel(widgets[widgets.length - 1], true);
  };

  return (
    <div className="casa-web-view-app">
      <div className={`toast_container`} style={{ top: "3.5rem" }}>
        <ToastMessage toastMessages={toastMessages} />
      </div>
      <div className="top-content">
        <WebHomepageTopBar
          layoutUnsavedChnages={layoutUnsavedChnages}
          setPublishState={setPublishState}
          setLayoutUnsavedChnages={setLayoutUnsavedChnages}
          webPreviewMode={webPreviewMode}
          onWebPreviewModeChange={handleWebPreviewModeChange}
        />
        {(isWebHomepageLoading || signedUrlApi?.isLoading) && (
          <IndeterminateLoader
            color="#0A1F43"
            height="0.25rem"
            backgroundColor="#B0B7C2"
          />
        )}
      </div>
      <div
        className={getClassName(
          "bottom-content",
          webPreviewMode && "web-preview-mode"
        )}
      >
        {visiblePanel === casaWebConstants.LEFT && !webPreviewMode && (
          <div className={`left-panel ${styles["visible-panel"]}`}>
            <WidgetLeftPanelComponent
              addWidgetToRightPanel={addWidgetToRightPanel}
            />
          </div>
        )}
        <div className="web-preview">
          {webPreviewMode &&
            publish.isPublished === casaWebConstants.PUBLISHED &&
            cloneDashboardData?.createdAt === cloneDashboardData?.updatedAt && (
              <ActionBar
                handleWebPreviewModeChange={handleWebPreviewModeChange}
                setLayoutUnsavedChnages={setLayoutUnsavedChnages}
              />
            )}
          <WebHomepagePreview
            webPreviewMode={webPreviewMode}
            addCoverPage={addCoverPage}
            unSavedChanges={setLayoutUnsavedChnages}
          />
        </div>
        {visiblePanel === casaWebConstants.RIGHT && (
          <div className={`right-panel ${styles["visible-panel"]}`}>
            {publishState.state ? (
              <PublishWebHomepage
                publishState={publishState}
                setPublishState={setPublishState}
              />
            ) : (
              <WidgetConfigurationRightPanel />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
