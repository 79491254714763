import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import CustomButton from "../CustomButton";
import styles from "./styles.module.css";
import { ReactComponent as PublishIcon } from "../../assets/svgIcons/publish.svg";
import { ReactComponent as SaveIcon } from "../../assets/svgIcons/lsq_save.svg";
import { ReactComponent as SavedIcon } from "../../assets/svgIcons/lsq_ok.svg";
import { ReactComponent as VersionIcon } from "../../assets/svgIcons/lsq_version.svg";
import { ReactComponent as DashboardIcon } from "../../assets/svgIcons/lsq_dashboard.svg";
import { ReactComponent as DropdownIcon } from "../../assets/svgIcons/lsq_chevron_down_filled.svg";
import {
  apiUpdateLayout,
  changeToastMessage,
} from "../../reducers/previewPaneSlice";
import LsqProgressSpinner from "../LsqProgressSpinner";
import {
  addToastMessage,
  changePublishState,
  resetHomeBuilder,
  updateHomePage,
  getHomepageVersions,
} from "../../reducers/homePageSlice";
import { ReactComponent as Back } from "../../assets/svgIcons/back.svg";
import analytics from "../../utils/analytics";
import {
  ROUTES_PATH,
  apiExceptionCode,
  casaConstants,
  globalConstants,
  toastMessageConstants,
} from "../../constants/globalConstant";
import useToast from "../../hooks/useToast";
import { LsqTextEditor } from "../LsqTextEditor";
import { findNumberOfWidgetsInTheLayout } from "../../utils/previewPaneUtils";
import Text from "../Text";
import CustomSplitButton from "../CustomSplitButton";
import useHomepageCard from "../../hooks/useHomepageCard";
import { capitalizeText } from "../../utils/stringUtils";

export default function TopBar({
  dashboardsMappedWithId,
  dashboardsToTeamCountMapping,
  setPublishState,
  setHomepageVersion,
  getPreviewPaneScreenShot,
  setSidePanelState,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const previewPaneToast = useToast(changeToastMessage);
  const { t } = useTranslation();
  const previewPane = useSelector((state) => state.previewPane);
  const widgetListData = useSelector((state) => state.widgetTemplateList);
  const { showCurrentChanges, isPreviewPaneDirty, hasDraftChanges } =
    previewPane;
  const isSaveLoading = useSelector((state) => state.previewPane.isSaveLoading);
  const isPublishLoading = useSelector(
    (state) => state.homepage.publish.isPublishLoading
  );
  const { selectedWidgetListTab, selectedWidget } = widgetListData;
  const mode = selectedWidget.mode;
  const teamDashboards = useSelector((state) => state.homepage.teamDashboards);
  const defaultDashboards = teamDashboards?.defaultDashboards;
  const { selectedHomepageId, homepagesById, isVersionEnabled } = useSelector(
    (state) => state.homepage
  );
  const selectedHomepage = homepagesById[selectedHomepageId];
  const { unpublishHomepageClicked } = useHomepageCard();

  const { homepageName, status } = selectedHomepage || {};
  const isPublished =
    status === casaConstants.PUBLISHED && hasDraftChanges === false;
  const toggleModal = (selectedHomepageId) => {
    setPublishState({
      state: true,
      data: {
        isDefault: defaultDashboards?.homepages?.includes(
          parseInt(selectedHomepageId)
        ),
        teamIds: dashboardsToTeamCountMapping[selectedHomepageId] || [],
        dashboardName:
          dashboardsMappedWithId[selectedHomepageId].homepageName || "",
        selectedHomepageId,
      },
    });
  };

  const onSaveClicked = () => {
    if (isPreviewPaneDirty && !isSaveLoading) {
      dispatch(apiUpdateLayout()).then(({ payload }) => {
        if (payload?.code === apiExceptionCode.HOMEPAGE_NOT_FOUND) {
          navigate(ROUTES_PATH.homepages);
        }
      });
      const analytics_data = {
        category: "Homepage",
        type: "click",
        homepageId: selectedHomepageId,
      };
      analytics.sendEvent("Save_Homepage", analytics_data);
    }
  };

  const onPublishClicked = () => {
    if (isPublishLoading || isPublished) {
      return;
    }
    if (isPreviewPaneDirty) {
      previewPaneToast.warn(
        toastMessageConstants.SAVE_UNSAVED_CHANGES_BEFORE_PUBLISH
      );
      return;
    }
    if (findNumberOfWidgetsInTheLayout(previewPane) <= 0) {
      previewPaneToast.warn(
        toastMessageConstants.NO_ACTIVE_WIDGETS_TO_BE_PUBLISHED
      );
      return;
    }
    getPreviewPaneScreenShot();
    toggleModal(selectedHomepageId);
  };

  const getSaveButtonLabel = () => {
    if (isSaveLoading) return "Saving..";
    if (isPreviewPaneDirty) return "Save";
    return "Saved";
  };

  const getSaveButtonIcon = () => {
    if (isSaveLoading)
      return <LsqProgressSpinner strokeWidth={2} strokeColor="#FFFFFF" />;
    if (isPreviewPaneDirty) return <SaveIcon fill="white" />;
    return <SavedIcon fill="white" />;
  };

  const getPublishButtonIcon = () => {
    if (isPublishLoading)
      return <LsqProgressSpinner strokeWidth={3} strokeColor="white" />;
    if (!isPublished) return <PublishIcon />;
    return <SavedIcon fill="white" />;
  };

  const getPublishButtonLabel = () => {
    if (isPublishLoading || !isPublished) return "Publish";
    return "Published";
  };

  const onTeamCountClicked = (selectedHomepage) => {
    const dashboardId = selectedHomepage.homepageId;
    setSidePanelState({
      state: true,
      data: {
        isDefault: defaultDashboards?.homepages?.includes(
          parseInt(dashboardId)
        ),
        teamIds: dashboardsToTeamCountMapping[dashboardId] || [],
        dashboardName: selectedHomepage.homepageName || "",
        dashboardId,
      },
    });
    analytics.sendEvent("teams_count_clicked_from_homebuilder", {
      dashboardId: dashboardId,
    });
  };

  const getPublishAdditionalOptions = () => {
    const options =
      status === casaConstants.PUBLISHED
        ? [
            {
              label: "Unpublish",
              command: () => {
                unpublishHomepageClicked(selectedHomepage, "home_builder");
              },
            },
            {
              label: "Teams",
              command: () => {
                onTeamCountClicked(selectedHomepage);
              },
            },
          ]
        : [];
    return options;
  };

  const onBackClicked = () => {
    if (isPreviewPaneDirty) {
      previewPaneToast.warn(toastMessageConstants.SAVE_UNSAVED_CHANGES);
      return;
    }
    navigate(ROUTES_PATH.homepages);
    selectedHomepage.status === casaConstants.DRAFT &&
      dispatch(
        addToastMessage({
          type: globalConstants.SUCCESS,
          title: "Saved as Draft",
          message: `${selectedHomepage.homepageName} was saved as a draft!`,
        })
      );
    dispatch(resetHomeBuilder());
  };

  const saveHandler = (text) => {
    const postBody = {
      homepageName: capitalizeText(text?.trim()),
    };
    dispatch(updateHomePage({ homepageId: selectedHomepageId, postBody }));
  };

  useEffect(() => {
    if (!isPreviewPaneDirty) {
      dispatch(changePublishState(false));
    }
  }, [isPreviewPaneDirty]);

  const onVersionClicked = () => {
    setHomepageVersion({ versionPanel: true, versions: null });
    dispatch(getHomepageVersions(selectedHomepageId)).then(
      ({ type, payload }) => {
        if (type.includes(globalConstants.REJECTED)) {
          setHomepageVersion({ versionPanel: false, versions: null });
        } else setHomepageVersion({ versionPanel: true, versions: payload });
      }
    );
  };

  return (
    <div
      className={`flex flex-row w-full h-4rem pl-2 pr-3 justify-content-between align-items-center top-bar coach-top-bar ${styles["border-shadow"]}`}
    >
      <div className={`flex flex-row align-items-center gap-1 ml-7`}>
        {selectedWidgetListTab === globalConstants.EXPIREMENTAL_WIDGETS ? (
          <Text
            color="var(--text-primary)"
            type="T1B"
            className="min-w-max ml-3"
          >
            {globalConstants.EXPERIMENT_WITH_UPCOMING_WIDGET}
          </Text>
        ) : (
          <>
            <CustomButton
              varient="text"
              icon={<Back />}
              label=""
              onClick={onBackClicked}
              data-testid="scaffold-back-btn"
            />
            <LsqTextEditor
              displayText={homepageName}
              onSaveHandler={saveHandler}
            ></LsqTextEditor>
          </>
        )}
      </div>
      <div
        className={`flex flex-row flex-wrap justify-content-between align-items-center w-max h-full  sm:justify-content-end gap-2 ${
          styles["box-animation"]
        } ${
          mode !== null ||
          (hasDraftChanges && !showCurrentChanges) ||
          selectedWidgetListTab === globalConstants.EXPIREMENTAL_WIDGETS
            ? styles["fade-out"]
            : styles["fade-in"]
        }`}
      >
        {isVersionEnabled && (
          <div
            className={`flex px-2 py-1 cursor-pointer ${styles["version-icon"]}`}
            onClick={onVersionClicked}
            data-testid="version-icon"
          >
            <VersionIcon style={{ width: "1.3rem" }} />
          </div>
        )}
        <CustomButton
          varient="outline"
          icon={<DashboardIcon />}
          label={"Templates"}
          onClick={() => navigate(ROUTES_PATH.templates)}
          data-testid="templates-btn"
        />
        <CustomButton
          disabled={!isPreviewPaneDirty}
          varient="outline"
          onClick={onSaveClicked}
          icon={getSaveButtonIcon()}
          label={t(getSaveButtonLabel())}
          data-testid="save-btn"
        />
        <CustomSplitButton
          varient="filled"
          onClick={onPublishClicked}
          icon={getPublishButtonIcon()}
          label={t(getPublishButtonLabel())}
          id="publish-btn"
          additionalOptions={getPublishAdditionalOptions()}
          dropdownIcon={<DropdownIcon></DropdownIcon>}
        />
      </div>
    </div>
  );
}
