export const validateThreshold = (widgetForm, formData) => {
  if (!formData.setThresholds) return true;
  if (formData.min > formData.max) {
    widgetForm.setError("min", {
      type: "manual",
      message: "Min value cannot be greater than Max value.",
    });
    widgetForm.setError("max", {
      type: "manual",
      message: "Max value cannot be less than Min value.",
    });
    return;
  }
  if (formData.min < 0) {
    widgetForm.setError("min", {
      type: "manual",
      message: "Min value must be a positive number.",
    });
    return;
  }
  return true;
};
export const validateCoverPage = (widgetForm, formData) => {
  if (
    formData.name === "Cover Page" &&
    formData.customRadio === "image" &&
    (!formData?.image || !formData?.image?.imageURL)
  ) {
    widgetForm.setError("customRadio", {
      type: "manual",
      message: "This field cannot be left blank",
    });
    return;
  }
  return true;
};

export const formatFormData = (formData) => {
  const { entity } = formData;
  if (entity?.id === "tasks") {
    // Remove the fields smartView and smartViewTab
    delete formData.smartView;
    delete formData.smartViewTab;
    delete formData.teams;
  } else if (entity?.id === "smartviews") {
    // Remove the fields dateFilters, taskStatus, and taskTypes
    delete formData.dateFilters;
    delete formData.taskStatus;
    delete formData.taskTypes;
    delete formData.tasksToDisplay;
  }
  if (!formData?.setThresholds) {
    delete formData.thresholds;
    delete formData.min;
    delete formData.max;
  }
  if (formData?.taskStatus?.id !== "completed" && entity?.id !== "smartviews") {
    delete formData.thresholds;
    delete formData.setThresholds;
    delete formData.min;
    delete formData.max;
  }
  return formData;
};

export const countEntities = (usedWidgetsList, id) => {
  return usedWidgetsList.reduce((count, item) => {
    if (item.config?.entity?.id === id) {
      return count + 1;
    }
    return count;
  }, 0);
};

export const getEntityCountMap = (usedWidgetsList, casaWebLimits) => {
  const entitiesLimit = casaWebLimits?.entitiesLimit;
  const result = {};

  for (const entityType in entitiesLimit) {
    if (Object.prototype.hasOwnProperty.call(entitiesLimit, entityType)) {
      const count = countEntities(usedWidgetsList, entityType);
      result[entityType] = count;
    }
  }

  return result;
};
