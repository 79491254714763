import React from 'react'
import LsqSwitch from '../../../../../components/LsqSwitch';

const renderSwitchController = ({ field, form }) => {
    return (
      <LsqSwitch
        control={form.control}
        label={field.label}
        switchName={field.fieldName || "switch"}
        textColor={"var(--N90)"}
        data-testid={field.fieldName}
        style={{ justifyContent: "space-between", marginRight: "0.3rem" }}
      />
    );
  };

export default renderSwitchController