import { AlertTriangle } from 'lucide-react'
import styles from "./style.module.css"

export default function Warning({ title, message }) {
  return (
    <div className={styles.warningWrapper}>
      <AlertTriangle className={styles.icon} />
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.message}>{message}</div>
      </div>
    </div>
  )
}