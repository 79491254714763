import { useRef } from "react";
import { Menu } from "primereact/menu";
import { useDispatch, useSelector } from "react-redux";
import { changeSelectedWidget } from "../reducers/widgetTemplateListSlice";
import { apiDeleteSection, apiDeleteWidget, apiUpdateLayout, changeToastMessage, setPreviewPaneDirty, updatePreviewPane } from "../reducers/previewPaneSlice";
import { ReactComponent as LSQMore } from "../assets/svgIcons/lsq_more.svg";
import "./style.css";
import { getWidgetTitle, hasConfigurationScreen } from "../utils/widgetUtils";
import cloneDeep from "lodash.clonedeep";
import { topFixedWidgets } from "../constants/globalConstant";
export const WidgetMenu = ({ children, styles, widgetInformation }) => {
  const dispatch = useDispatch();
  const menu = useRef(null);
  const mode = useSelector(
    (state) => state.widgetTemplateList.selectedWidget.mode
  );
  const { sections } = useSelector((state) => state.previewPane);
  const widgetTemplateId = widgetInformation?.widgetTemplateId;
  let widgetTitle = getWidgetTitle(widgetTemplateId);

  const deleteTopFixedWidget = async () => {
    if (sections.length === 1) {
      dispatch(changeToastMessage({
        type: "warning",
        title: "Warning",
        message: "You cannot delete all of the sections from the layout!",
      }));
      return;
    }

    const rowsCountInTopFixedSections = sections[0]?.rows.length;

    // If only one widget present in top-fixed section, then delete section
    if (rowsCountInTopFixedSections === 1) {
      await dispatch(apiDeleteSection(0));
      const _sections = cloneDeep(sections)
      _sections.shift();
      dispatch(updatePreviewPane({ sections: _sections, selectedSection: 0 }))
      dispatch(setPreviewPaneDirty(true))
      dispatch(apiUpdateLayout())
    }
    else {
      await dispatch(apiDeleteWidget(widgetInformation.widgetId));
    }
  }

  const deleteItem = {
    template: () => (
      <div className="py-2 flex justify-content-around align-content-center">
        <img
          title="Delete"
          className="cursor-pointer"
          src="/images/delete.svg"
          alt="delete"
          data-testid="widget-delete"
          onClick={() => {
            if(topFixedWidgets.includes(widgetInformation.widgetTemplateId)){
              deleteTopFixedWidget();
            }
            else{
              dispatch(apiDeleteWidget(widgetInformation.widgetId));
            }
            menu.current.toggle(false);
          }}
        ></img>
      </div>
    ),
  };

  const editItem = {
    template: () => (
      <div className="p-2 flex justify-content-around align-content-center">
        <img
          title="Edit"
          className="cursor-pointer"
          data-testid="widget-edit"
          onClick={() => {
            dispatch(
              changeSelectedWidget({
                mode: "UPDATE",
                widgetTitle: widgetTitle,
                widgetDetails: widgetInformation,
              })
            );
            menu.current.toggle(false);
          }}
          src="/images/edit.svg"
          alt="edit"
        ></img>
      </div>
    ),
  };

  const items =
    hasConfigurationScreen(widgetTemplateId)
      ? [editItem, { separator: true }, deleteItem]
      : [deleteItem];

  return (
    <div className="relative" data-testid="lsq-menu">
      <div
        className={`absolute z-5`}
        style={{
          ...styles,
          cursor: mode !== null ? "not-allowed" : "pointer",
          background: "rgba(255, 255, 255, 0.75)",
          borderRadius: "4px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "4px 2px",
          backdropFilter: "blur(1px)",
        }}
        onClick={(e) => {
          if (mode !== null) return;
          menu.current.toggle(e);
        }}
        data-testid="widget-more-icon"
      >
        <LSQMore />
      </div>
      <Menu
        separator
        model={items}
        popup
        ref={menu}
        data-testid="widget-menu"
      />
      {children}
    </div>
  );
};
