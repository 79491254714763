import { useState, useEffect, useRef } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Add } from "../../../../../assets/svgIcons/lsq_add.svg";
import CustomButton from "../../../../../components/CustomButton";
import CustomInput from "../../../../../components/CustomInput";
import { NavigationMenu } from "../../../../../constants/globalEnums";
import { iconList } from "../../../../../utils/iconList";
import Text from "../../../../../components/Text";
import {
  iconPickerTemplate,
  selectedIconTemplate,
} from "../../../../../utils/dropdownUtils";
import { CustomIconPicker } from "../../../../../components/CustomIconPicker/CustomIconPicker";
import LauncherElementDragHandler from "../../../../../components/LauncherElementDragHandler";
import { v4 as uuidv4 } from "uuid";
import useWidgetEssentials from "../../../../../hooks/useWidgetEssentials";
import LsqMobileNavigator from "../../../../../components/LsqMobileNavigator";
import {
  customFabConstants,
  globalConstantValues,
  iconLauncherItemsConfigurations,
  placeholderConstants,
} from "../../../../../constants/globalConstant";
import { toggleHasCustomFab } from "../../../../../reducers/labsSlice";
import { resetWidgetConfiguration } from "../../../../../reducers/widgetTemplateListSlice";
import { apiGetWebLauncehBarMetaData } from "../../../../../reducers/webLaunchbarSlice";
import { setIconLauncherItems } from "../../../../../reducers/webHomepageSlice";
import styles from "../../styles.module.css"


export default function RearrangeIconLauncherItems() {
  const dispatch = useDispatch();
  const { selectedWidgetLanguage, iconLauncherItems } = useSelector((state) => state.webHomepage);
  const initialConfig = selectedWidgetLanguage?.config?.launcherItems || [];

  useEffect(() => {
    dispatch(setIconLauncherItems(initialConfig));
  }, []);

  const [isDisabled, setIsDisabled] = useState(true);
  const [, setMetaDataForm] = useState([]);
  const iconPickerRef = useRef(null);
  const [iconPanelWidth, setIconPanelWidth] = useState(0);
  const [addItemClicked, setAddItemClicked] = useState(false);
  const [updatedElementIndex, setupdatedElementIndex] = useState();
  const [edit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showSave, setShowSave] = useState(false);
  const [navigationList, setNavigationList] = useState([
    {
      name: "External Link",
      items: [{ id: "ExternalLink", name: "-----" }],
    },
    {
      name: "Internal Navigation",
      items: [],
    },
  ]);
  const { selectedWidget, mode, selectedWidgetConfiguration } =
    useWidgetEssentials();
  const { fabType } = selectedWidgetConfiguration.metaData;
  const defaultItem = {
    displayName: "",
    icon: "",
    navigateTo: "",
    internalLink: {},
    externalLink: "",
    dynamicForm: {
      entity: "",
      workArea: null,
      form: {},
    },
    height: iconLauncherItemsConfigurations.maxHeight,
    width: iconLauncherItemsConfigurations.minWidth,
  };
  const curItem = useForm({
    defaultValues: defaultItem,
  });
  const defaultValues = {
    fabType: fabType?.length > 0 && fabType[0].value,
    items: initialConfig || [],
  };

  const { control, handleSubmit, unregister, watch } = useForm({
    defaultValues,
  });
  const { fields, append, remove, swap, update, move, replace } = useFieldArray({
    control,
    name: "items",
    rules: { maxLength: 9 },
  });

  useEffect(()=> {
    // Incase any difference is spotted in height and width, when items are resized, sync them.
    if (JSON.stringify(iconLauncherItems) !== JSON.stringify(fields) && iconLauncherItems?.length && fields?.length) {
      replace(iconLauncherItems);
    }
  }, [iconLauncherItems])

  const fabTypeWatch = watch("fabType");
  const watchAll = curItem.watch();

  useEffect(() => {
    const fetchMetaData = async () => {
      const formFilterList = [
        "Lead/Form",
        "Activity/Form",
        "Task/Form",
        "Dynamic/Form",
        "Opportunity/Form",
        "Account/Form",
      ];

      const data = await dispatch(apiGetWebLauncehBarMetaData());

      // Filtering data.payload.menu based on formFilterList
      const filteredMenu = data.payload.menu.filter((item) =>
        formFilterList.includes(item.id)
      );

      // Setting the filtered data to your state
      setMetaDataForm(filteredMenu);

      setNavigationList((prevList) => {
        const updatedList = [...prevList];
        updatedList[1].items = filteredMenu;
        return updatedList;
      });
    };

    fetchMetaData();
  }, [dispatch]);

  const addItemMaximumLimit = 10;

  const onElementAddClicked = async (e) => {
    let element;
    let updatedFields;

    if (updatedElementIndex !== undefined) {
      // If editing, replace the item in the fields array at the specific index
      element = {
        ...curItem.getValues(),
        id: fields[updatedElementIndex].id, // Keep the original id
      };
      updatedFields = fields.map((item, index) =>
        index === updatedElementIndex ? element : item
      );
      update(updatedElementIndex, element); // Update in the UI
      // updateItemInLaunchBar(element, updatedElementIndex); // Update the state
    } else {
      // If adding a new element, just append it to the array
      element = {
        ...e,
        displayName: e.displayName.trim(),
        id: uuidv4(),
        uuid: uuidv4(),
        height: iconLauncherItemsConfigurations.maxHeight,
        width: iconLauncherItemsConfigurations.minWidth,
      };
      updatedFields = [...fields, element];
      append(element);
    }

    curItem.reset();
    setupdatedElementIndex();
    setAddItemClicked(false);

    dispatch(setIconLauncherItems(updatedFields))
  };

  const onSubmit = () => {
    if (selectedWidget.isExperimental) {
      dispatch(toggleHasCustomFab(true));
      dispatch(resetWidgetConfiguration());
      return;
    }
  };

  const onElementEdit = (index) => {
    setAddItemClicked(true);
    setupdatedElementIndex(index);
    setEdit(true);
    const { displayName, icon, navigateTo, internalLink, externalLink, height, width, uuid } =
      fields[index];
    let updatedNavigateTo = navigateTo;
    curItem.setValue("uuid", uuid, { shouldDirty: true });
    curItem.setValue("displayName", displayName, { shouldDirty: true });
    curItem.setValue("height", height, { shouldDirty: true });
    curItem.setValue("width", width, { shouldDirty: true });
    curItem.setValue("icon", icon, { shouldDirty: true });
    curItem.setValue("navigateTo", updatedNavigateTo, { shouldDirty: true });
    curItem.setValue("internalLink", internalLink, { shouldDirty: true });
    curItem.setValue("externalLink", externalLink, { shouldDirty: true });
  };

  const onDeleteItem = async (index) => {
    const updatedFields = fields.filter((_, i) => i !== index); // Remove the item from the fields array
    dispatch(setIconLauncherItems(updatedFields));
  };

  useEffect(() => {
    showSave && updateIndex();
  }, [showSave]);

  const updateIndex = async () => {
    dispatch(setIconLauncherItems(fields))
    setShowSave(false);
  };

  useEffect(() => {
    let tempNavigationList = [...navigationList];
    if (selectedWidgetConfiguration.metaData?.menu) {
      tempNavigationList[1].items = selectedWidgetConfiguration.metaData?.menu;
    }
    setNavigationList(tempNavigationList);
  }, []);

  useEffect(() => {
    const dirtyFieldLength = Object.keys(curItem.formState.dirtyFields).length;
    const navigateTo = watchAll["navigateTo"];
    if (isLoading) setIsDisabled(true);
    else if (dirtyFieldLength < 3) {
      if (!isDisabled) setIsDisabled(true);
    } else if (
      navigateTo?._links?.subMenu?.href !== undefined &&
      navigateTo.name !== NavigationMenu.DYNAMIC_FORMS &&
      dirtyFieldLength <= 3
    ) {
      if (!isDisabled) setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [watchAll]);

  return (
    <form
      style={{ height: "90%" }}
      className="flex flex-column w-full scroll-bar-visible"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex-1 overflow-y-auto launch-bar-right-panel">
        <div className={`flex flex-column`}>

          {(fields.length < addItemMaximumLimit ||
            updatedElementIndex !== undefined) &&
            (addItemClicked ? (
              <div
                className={`flex flex-column mb-3 mt-3 add-launch-item-config`}
              >
                <Text type="T4B" color="var(--text-primary)">
                  {customFabConstants.LAUNCHBAR_WIDGET_ADD_ITEMS}
                </Text>
                <div className={`flex w-full gap-1`} ref={iconPickerRef}>
                  <CustomIconPicker
                    id="icon-list"
                    fieldName="icon"
                    control={curItem.control}
                    className="w-full text-base lsq-input lsq-icon-picker"
                    options={iconList}
                    optionLabel="name"
                    filter
                    filterBy="name"
                    resetFilterOnHide={true}
                    filterPlaceholder={customFabConstants.SEARCH_ICONS}
                    placeholder={"lsq_change_image"}
                    valueTemplate={selectedIconTemplate}
                    itemTemplate={iconPickerTemplate}
                    label={customFabConstants.ICON}
                    isMandatory={true}
                    errors={curItem.formState.errors}
                    panelClassName="lsqIconPickerPanel"
                    onShow={() => {
                      setIconPanelWidth(iconPickerRef.current?.offsetWidth);
                    }}
                    panelStyle={{ width: iconPanelWidth }}
                    containerStyle={{ width: "20%" }}
                  />
                  <CustomInput
                    id={"custominput"}
                    errors={curItem.formState.errors}
                    fieldName="displayName"
                    control={curItem.control}
                    isMandatory
                    label={customFabConstants.LABEL}
                    maxLength={
                      globalConstantValues.QUICK_LAUNCHER_LABEL_CHAR_LIMIT
                    }
                    placeholder={placeholderConstants.TYPE_HERE}
                    containerStyle={{ width: "80%" }}
                  />
                </div>
                <LsqMobileNavigator
                  formValues={curItem}
                  mobileNavigationMenu={navigationList}
                  unregister={unregister}
                  edit={edit}
                  setEdit={setEdit}
                  isMandatory={true}
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                  mode={mode}
                  application="WEB"
                  displayExternalLinkOpeningOptions={false}
                />
                <div
                  className={`flex w-12 align-self-center align-items-center justify-content-end mt-auto gap-1`}
                >
                  <CustomButton
                    type="reset"
                    onClick={() => {
                      curItem.reset();
                      setupdatedElementIndex();
                      setAddItemClicked(false);
                    }}
                    varient="text"
                    label={customFabConstants.CANCEL}
                  />
                  <CustomButton
                    data-testid="add-btn"
                    disabled={isDisabled}
                    className="flex justify-content-center w-3"
                    onClick={curItem.handleSubmit((e) =>
                      onElementAddClicked(e)
                    )}
                    label={
                      updatedElementIndex !== undefined
                        ? customFabConstants.SAVE
                        : customFabConstants.ADD
                    }
                    varient="outline"
                  />
                </div>
              </div>
            ) : (
              <div
                className={styles.launcherAddItemButton}
                onClick={() => setAddItemClicked(true)}
                data-testid="add-fab-item"
              >
                <Add fill="var(--icon-primary)" />
                <Text type="T3" color="var(--text-primary)">
                  {customFabConstants.LAUNCHBAR_WIDGET_ADD_ITEM}
                </Text>
              </div>
            ))}
          {fields.length >= 1 && (
            <LauncherElementDragHandler
              onElementEdit={onElementEdit}
              elementList={
                fabTypeWatch === customFabConstants.SINGLE_TAP
                  ? [{ ...fields[0] }]
                  : fields
              }
              remove={remove}
              onDeleteItem={onDeleteItem}
              swap={swap}
              move={move}
              updatedElementIndex={updatedElementIndex}
              showSelected={true}
              updateLauncherInPreviewPane={() => setShowSave(true)}
            />
          )}
          <div />
        </div>
      </div>
    </form>
  );
}
